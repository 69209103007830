declare global {
    interface Date {
        addDays(value: number): Date;
    }
}

Date.prototype.addDays = function (value: number): Date {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + value);
    return date;
};

export { };