import ApiCore , { ApiCoreBase } from '@/base/api-core.typing';
import axios from 'axios';
import Api from '../../base/api.typings';

class UnidadeEstoqueService {

    pesquisarUnidadeEstoque(query?: Api.UnidadeEstoque.PesquisarUnidadeEstoque.Query): Promise<Api.UnidadeEstoque.PesquisarUnidadeEstoque.UnidadeEstoqueDto[]> {
        return axios.get('/api/unidadeestoque/pesquisarunidadeestoque', { params: query }).then(r => r.data);
    }
}

export class UnidadeEstoqueServiceCore extends ApiCoreBase {

    
    UnidadeEstoque1(params?:  ApiCore.UnidadeEstoque.Query): Promise<ApiCore.UnidadeEstoque.UnidadeEstoqueDto1[]> {
        
        return  this.instance.post('/api/Unidade_Estoque/', { tbParametros:[params]  } ).then(r => r.data.unidade_estoque);
    }

    UnidadeEstoque2(params?:  ApiCore.UnidadeEstoque.Query): Promise<ApiCore.UnidadeEstoque.UnidadeEstoqueDto2[]> {
        
        return  this.instance.post('/api/Unidade_Estoque/', { tbParametros:[params]  } ).then(r => r.data.unidade_estoque);
    }
    
    UnidadeEstoque3(params?:  ApiCore.UnidadeEstoque.Query): Promise<ApiCore.UnidadeEstoque.UnidadeEstoqueDto3[]> {
        
        return  this.instance.post('/api/Unidade_Estoque/', { tbParametros:[params]  } ).then(r => r.data.unidade_estoque);
    }

    UnidadeEstoque_Busca(params?:  ApiCore.UnidadeEstoque_Busca.Query): Promise<ApiCore.UnidadeEstoque_Busca.UnidadeEstoqueDto2[]> {
        
        return  this.instance.post('/api/Unidade_Estoque_Busca/', { tbParametros:[params]  } ).then(r => r.data.unidade_estoque);
    }

    UnidadeEstoque_Update_Post(params?:  ApiCore.UnidadeEstoque_Update.Command): Promise<boolean> {
        
        return  this.instance.post('/api/Unidade_Estoque_Update/', { unidade_estoque:[params]  } ).then(r => r.data);
    }
    
    UnidadeEstoque_Update_Put(params?:  ApiCore.UnidadeEstoque_Update.Command): Promise<boolean> {
        
        return  this.instance.put('/api/Unidade_Estoque_Update/', { unidade_estoque:[params]  } ).then(r => r.data);
    } 

    UnidadeEstoque_Update_Delete(params?:  ApiCore.UnidadeEstoque_Update.Command): Promise<boolean> {
        return  this.instance.delete('/api/Unidade_Estoque_Update/', { data:{unidade_estoque:[params]}  } ).then(r => r.data);
    } 
}

export default new UnidadeEstoqueService();