
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop, Watch } from 'vue-property-decorator';
    import moment from 'moment';

    @Component
    export default class DateTimePicker extends Vue {

        @Prop() readonly value: string;

        isInitializing = false;

        activeTab = 0;

        selectedDate = "";
        selectedTime = "";

        created(): void {
            if (this.value != null) {
                this.isInitializing = true;

                let date = moment(this.value);
                this.selectedDate = date.format('YYYY-MM-DD');
                this.selectedTime = date.format('HH:mm');

                this.isInitializing = false;
            }
        }

        @Watch('selectedDate')
        onSelectDate(): void {
            if(!this.isInitializing)
                this.activeTab = 1;
        }

        @Watch('selectedTime')
        onSelectTime(): void {
            if (!this.isInitializing) {

                let date = moment(`${this.selectedDate}T${this.selectedTime}`);

                // Para que o componente possa usar 'v-model' deve-se criar uma propriedade 'value' e emitir seu valor por 'init'.
                // https://vuejs.org/v2/guide/components.html#Using-v-model-on-Components
                this.$emit('input', date.toJSON());
            }
        }
    }

