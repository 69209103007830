import { RouteConfig } from 'vue-router';
import { AuthrorizationPaths, LoginActions, LogoutActions } from '@/features/api-authorization/api-authorization-constants';

const apiAuthorizationRoutes: RouteConfig[] = [
    {
        path: AuthrorizationPaths.Login,
        component: () => import('@/features/api-authorization/login.vue'),
        props: { action: LoginActions.Login },
        meta: { allowAnonymous: true }
    },
    {
        path: AuthrorizationPaths.LoginFailed,
        component: () => import('@/features/api-authorization/login.vue'),
        props: { action: LoginActions.LoginFailed },
        meta: { allowAnonymous: true }
    },
    {
        path: AuthrorizationPaths.LoginCallback,
        component: () => import('@/features/api-authorization/login.vue'),
        props: { action: LoginActions.LoginCallback },
        meta: { allowAnonymous: true }
    },
    {
        path: AuthrorizationPaths.Profile,
        component: () => import('@/features/api-authorization/login.vue'),
        props: { action: LoginActions.Profile }
    },
    {
        path: AuthrorizationPaths.Register,
        component: () => import('@/features/api-authorization/login.vue'),
        props: { action: LoginActions.Register },
        meta: { allowAnonymous: true }
    },
    {
        path: AuthrorizationPaths.LogOut,
        component: () => import('@/features/api-authorization/logout.vue'),
        props: { action: LogoutActions.Logout },
        meta: { allowAnonymous: true }
    },
    {
        path: AuthrorizationPaths.LogOutCallback,
        component: () => import('@/features/api-authorization/logout.vue'),
        props: { action: LogoutActions.LogoutCallback },
        meta: { allowAnonymous: true }
    },
    {
        path: AuthrorizationPaths.LoggedOut,
        component: () => import('@/features/api-authorization/logout.vue'),
        props: { action: LogoutActions.LoggedOut },
        meta: { allowAnonymous: true }
    }
];

export default apiAuthorizationRoutes;