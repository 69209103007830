import Vue from 'vue';
import Router from 'vue-router';
import Component from 'vue-class-component'

import Home from '@/features/home/home.vue';
import Error from '@/features/home/error.vue';
import { authorizeGuard } from '@/features/api-authorization/authorize-route';
import apiAuthorizationRoutes from '@/features/api-authorization/api-authorization-route';
import reportRoutes from '@/features/reports/reports-route';
import phonebookRoutes from '@/features/phonebook/phonebook-router';
import chargesRoutes from '@/features/charges/charges-route';
import invoiceTemplatesRoutes from '@/features/modelos-notas/invoice-templates-route';
import sisgemRoutes from '@/sisgem-route';
import PNRotas from '@/features/pn/pn-route';
import planoContasRotas from '@/features/plano-contas/plano-contas-route';

import VendasRotas from '@/features/vendas/vendas-route';

Vue.use(Router);

// Register the router hooks with their names
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);

/*
 * Representa todas as rotas do projeto.
 * 
 * Os camponentes devem ser configurados utilizando import para que o carregamento ocorra de forma dinâmica conforme a navegação ocorre, ex:
 * component: () => import('./features/usuario/usuario-listar.vue')
 * 
 * Se for necessário autenticação para utilizar a tela, configure:
 * meta: { authorize: true }
 * 
 * Para forçar login direcione o usuário para a tela /login
 * 
 * Para forçar logout direcione o usuário para a tela /logout
 * 
 * Para exibir mensagem de erro direcione o usuário para a tela /erro?messagem=mensagem+de+erro+aqui
 */
const router = new Router({
    mode: 'history',

    routes: [
        ...apiAuthorizationRoutes,
        ...reportRoutes,
        ...phonebookRoutes,
        ...chargesRoutes,
        ...invoiceTemplatesRoutes,
        ...sisgemRoutes,
        ...VendasRotas,
        ...PNRotas,
        ...planoContasRotas,
        {
            path: '/error',
            name: 'error',
            component: Error,
            meta: { allowAnonymous: true },
            props: (route) => route.params,
        },
        {
            path: '/',
            name: 'home',
            component: Home,
        },
        
        {
            path: '/ordemservico/',
            name: 'ordemservico',
            component: () => import('./features/ordem-servico/ordem-servico.vue'),
            props: (route) => route.params,

        },
        {
            path: '/ordemservicolancar/',
            name: 'ordemservicolancar',
            component: () => import('./features/ordem-servico/ordem-servico-lancar.vue'),
            props: (route) => route.params,

        },
        {
            path: "*",
            component: Error,
            props: (route) => ({ ...route.params, message: 'Página não encontrada.' }),

        },
        {
            path: "/user/link",
            component: () => import('@/features/users/link-user.vue'),

        },
        {
            path: "/planopagamento/listar",
            name: 'payment-plan-list',
            component: () => import('@/features/plano-pagamento/payment-plan-list.vue'),

        },
        {
            path: "/planopagamento/inserir",
            name: 'payment-plan-insert',
            component: () => import('@/features/plano-pagamento/payment-plan-insert-edit.vue'),

        },
        {
            path: "/planopagamento/editar/:paymentPlanId",
            name: 'payment-plan-edit',
            component: () => import('@/features/plano-pagamento/payment-plan-insert-edit.vue'),
            props: (route) => route.params,

        },
        {
            path: "/planoconta/cadastrar",
            name: 'account-plan-insert-edit',
            component: () => import('@/features/plano-contas/account-plan-insert-edit.vue'),

        },
        {
            path: "/planoconta/editar/:accountPlanId",
            name: 'account-plan-edit',
            component: () => import('@/features/plano-contas/account-plan-insert-edit.vue'),
            props: (route) => route.params,

        },
        {
            path: "/planoconta/listar",
            name: 'account-plan-list',
            component: () => import('@/features/plano-contas/account-plan-list.vue'),

        },
        {
            path: "/grupocobrancas/inserir",
            name: 'charges-group-insert',
            component: () => import('@/features/grupo-cobrancas/charges-group-insert-edit.vue'),

        },
        {
            path: "/grupocobrancas/editar/:chargesGroupId",
            name: 'charges-group-edit',
            component: () => import('@/features/grupo-cobrancas/charges-group-insert-edit.vue'),
            props: (route) => route.params,

        },
        {
            path: "/grupocobrancas/listar",
            name: 'charges-group-list',
            component: () => import('@/features/grupo-cobrancas/charges-group-list.vue'),

        },
        {
            path: "/cep/list",
            name: 'cep-list',
            component: () => import('@/features/cadastro-cep/cep-list.vue'),

        },
        {
            path: "/cep/insert",
            name: 'cep-insert',
            component: () => import('@/features/cadastro-cep/cep-insert-edit.vue'),

        },
        {
            path: "/cep/edit/:cepId",
            name: 'cep-edit',
            component: () => import('@/features/cadastro-cep/cep-insert-edit.vue'),
            props: (route) => route.params,

        },
        {
            path: "/user/list",
            name: 'user-list',
            component: () => import('@/features/users/user-list.vue'),
            props: (route) => route.params,

        },
        {
            path: "/user/insert",
            name: 'user-insert',
            component: () => import('@/features/users/user-insert-edit.vue'),

        },
        {
            path: "/user/edit/:userId",
            name: 'user-edit',
            component: () => import('@/features/users/user-insert-edit.vue'),
            props: (route) => route.params,

        },
        {
            path: "/user/changebond/:userId",
            name: 'change-user-bond',
            component: () => import('@/features/users/change-user-bond.vue'),
            props: (route) => route.params,

        },
        {
            path: "/user/duplicatesettings/:userId",
            name: 'duplicate-settings',
            component: () => import('@/features/users/duplicate-settings.vue'),
            props: (route) => route.params,

        },
        {
            path: "/user/useraccess/:userId",
            name: 'user-access',
            component: () => import('@/features/users/user-access.vue'),
            props: (route) => route.params,

        },
        {
            path: "/stockunit/list",
            name: 'stock-unit-list',
            component: () => import('@/features/stock-unit/stock-unit-list.vue'),

        },
        {
            path: "/stockunit/insert",
            name: 'stock-unit-insert',
            component: () => import('@/features/stock-unit/stock-unit-insert-edit.vue'),

        },
        {
            path: "/stockunit/edit/:stockUnitId",
            name: 'stock-unit-edit',
            component: () => import('@/features/stock-unit/stock-unit-insert-edit.vue'),
            props: (route) => route.params,

        },
        {
            path: "/productagroup/list",
            name: 'product-agroup-list',
            component: () => import('@/features/agrupadores/product-agroup-list.vue'),

        },
        {
            path: "/productagroup/insert",
            name: 'product-agroup-insert',
            component: () => import('@/features/agrupadores/product-agroup-insert-edit.vue'),

        },
        {
            path: "/productagroup/edit/:agroupId",
            name: 'product-agroup-edit',
            component: () => import('@/features/agrupadores/product-agroup-insert-edit.vue'),
            props: (route) => route.params,

        },
        {
            path: "/productagrouptype/list",
            name: 'product-agroup-type-list',
            component: () => import('@/features/agrupadores/product-agroup-type-list.vue'),

        },
        {
            path: "/productagrouptype/insert",
            name: 'product-agroup-type-insert',
            component: () => import('@/features/agrupadores/product-agroup-type-insert-edit.vue'),

        },
        {
            path: "/productagrouptype/edit/:agroupId",
            name: 'product-agroup-type-edit',
            component: () => import('@/features/agrupadores/product-agroup-type-insert-edit.vue'),
            props: (route) => route.params,

        },
        {
            path: "/relcaixa/:reportGroupId",
            name: 'relcaixa',
            props: (route) => route.params,
            component: () => import('@/features/reports/report_091_010_010/cashier-report-filter.vue'),

        },
        {
            path: "/relcaixasintetico",
            name: 'relcaixa-synthetic-cashier-report',
            props: true,
            component: () => import('@/features/reports/report_091_010_010/cashier-synthetic-report.vue'),

        },
        {
            path: "/relcaixaanalitico",
            name: 'relcaixa-analytical-cashier-report',
            props: true,
            component: () => import('@/features/reports/report_091_010_010/cashier-analytical-report.vue'),

        },
        {
            path: "/exemplo/listar",
            name: 'exemplo-list',
            props: true,
            component: () => import('@/features/exemplos/exemplo-list.vue'),

        },
        {
            path: "/exemplo/inserir",
            name: 'exemplo-insert',
            component: () => import('@/features/exemplos/exemplo-insert-edit.vue'),

        },  
        {
            path: "/exemplo/editar/:parId",
            name: 'exemplo-edit',
            props: (route) => route.params,
            component: () => import('@/features/exemplos/exemplo-insert-edit.vue'),
        },
        {
            path: "/unidadeestoque/listar",
            name: 'unidade-estoque-list',
            component: () => import('@/features/unidade-estoque/unidade-estoque-list.vue'),
            meta: { authorize: false }
        },
        {
            path: "/unidadeestoque/inserir",
            name: 'unidade-estoque-inserir',
            component: () => import('@/features/unidade-estoque/unidade-estoque-insert-edit.vue'),
            meta: { authorize: false }
        },
        {
            path: "/unidadeestoque/editar/:parId",
            name: 'unidade-estoque-editar',
            props: (route) => route.params,
            component: () => import('@/features/unidade-estoque/unidade-estoque-insert-edit.vue'),
            meta: { authorize: false }
        }
    ],
});

authorizeGuard(router);

export default router;