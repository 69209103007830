import axios from 'axios';
import Api from '@/base/api.typings';

class PlanoPagamentoService {

    pesquisarPlanoPagamento(query?: Api.PlanoPagamento.PesquisarPlanoPagamento.Query): Promise<Api.PlanoPagamento.PesquisarPlanoPagamento.PlanoPagamentoDto[]> {
        return axios.get('/api/PaymentPlan/search', { params: query }).then(r => r.data);
    }

    pesquisarPlanoPagamentoGrupo(query?: Api.PlanoPagamento.PesquisarGrupoPlanoPagamento.Query): Promise<Api.PlanoPagamento.PesquisarGrupoPlanoPagamento.PlanoPagamentoGrupoDto[]> {
        return axios.get('/api/planopagamento/pesquisarplanopagamentogrupo', { params: query }).then(r => r.data);
    }

    obterPlanoPagamento(query?: Api.PlanoPagamento.ObterPlanoPagamento.Query): Promise<Api.PlanoPagamento.ObterPlanoPagamento.PlanoPagamentoDto> {
        return axios.get('/api/planopagamento/obterplanopagamento', { params: query }).then(r => r.data);
    }
}
 
export default new PlanoPagamentoService(); 