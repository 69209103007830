/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import Moment from 'moment';
import "@/utils/extensions"

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
 
// axios.create creates a new Axios object that does not copy the global interceptors
// this override fixes that
const orgCreate = axios.create;

axios.create = function (config?: AxiosRequestConfig): AxiosInstance {
  const result = orgCreate.call(this, config);

  for (let index = 0; index < (axios.interceptors.request as any).handlers.length; index++) {
    const handler = (axios.interceptors.request as any).handlers[index] as any;
    result.interceptors.request.use(handler.fulfilled, handler.rejected);
  }

  for (let index = 0; index < (axios.interceptors.response as any).handlers.length; index++) {
    const handler = (axios.interceptors.response as any).handlers[index] as any;
    result.interceptors.response.use(handler.fulfilled, handler.rejected);
  }
 
  return result;
}

Moment.locale('pt-BR');

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')