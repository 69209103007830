import axios from 'axios';
import Api from '@/base/api.typings'
import Client from '../../base/client.typings';

const reportGroupsRepositoryPrefix = '/api/report-groups';
const reportRepositoryPrefix = '/api/report-groups';

export class ReportGroupsRepository {

    async GetAll(): Promise<Api.Reports.GetReportGroups.ReportGroupDto[]> {
        const r = await axios.get(reportGroupsRepositoryPrefix);
        return r.data;
    }

    async GetById(reportGroupId: number): Promise<Api.Reports.GetReportGroup.ReportGroupDto> {
        const r = await axios.get(`${reportGroupsRepositoryPrefix}/${reportGroupId}`);
        return r.data;
    }

}

export class ReportRepository {

    private readonly _groupId: number;

    constructor(groupId: number) {
        this._groupId = groupId;
    }

    get groupId(): number {
        return this._groupId;
    }

    async GetAll(): Promise<Api.Reports.GetReports.ReportDto[]> {
        const r = await axios.get(`${reportRepositoryPrefix}/${this._groupId}/reports`);
        return r.data;
    }

    async GetById(reportId: number): Promise<Api.Reports.GetReport.ReportDto> {
        const r = await axios.get(`${reportRepositoryPrefix}/${this._groupId}/reports/${reportId}`);
        return r.data;
    }

    async Generate(reportId: number, body: Api.Reports.GenerateReport.ReportSettings): Promise<Client.DynamicObject[]> {
        const r = await axios.post(`${reportRepositoryPrefix}/${this._groupId}/reports/${reportId}/generate`, body);
        return r.data;
    }

}

//class ReportsService {

//    Get

//    buscarGrupos(query?: Api.Relatorios.BuscarGrupos.Query): Promise<Api.Relatorios.BuscarGrupos.RelatorioGruposDto[]> {
//        return axios.get('/api/relatorios/buscargrupos', { params: query }).then(r => r.data);
//    }

//    buscarRelatorios(query?: Api.Relatorios.BuscarRelatorios.Query): Promise<Api.Relatorios.BuscarRelatorios.RelatoriosDisponiveisDto[]> {
//        return axios.get('/api/relatorios/buscarelatorios', { params: query }).then(r => r.data);
//    } 

//    buscarColunasFiltros(query: Api.Relatorios.BuscarColunasFiltros.Query): Promise<Api.Relatorios.BuscarColunasFiltros.RelatorioColunasFiltrosDto> {
//        return axios.get('/api/relatorios/buscarcolunasfiltros', { params: query }).then(r => r.data);
//    }

//    obterRelatorios(query?: Api.Relatorios.ObterRelatorios.Query): Promise<Api.Relatorios.ObterRelatorios.RelatorioGrupoDto[]> {
//        return axios.get('/api/relatorios/obterrelatorios', { params: query }).then(r => r.data);
//    }

//    processar(query: Api.Relatorios.Processar.Query): Promise<object[]> {
//        return axios.post('/api/relatorios/processar', query).then(r => r.data);
//    }

//    }