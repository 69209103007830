import ApiCore , { ApiCoreBase } from '@/base/api-core.typing';
import axios from 'axios';
import Api from '../../base/api.typings';

class PlanoContasService {

    pesquisarPlanoContas(query?: Api.PlanoContas.PesquisarPlanoContas.Query): Promise<Api.PlanoContas.PesquisarPlanoContas.PlanoContasDto[]> {
        return axios.get('/api/chartAccount/Search', { params: query }).then(r => r.data);
    }

}

export class PlanoContasServiceCore extends ApiCoreBase {
    planoContas1(params?:  ApiCore.Plano_Contas.Query): Promise<ApiCore.Plano_Contas.PlanoContasDto1[]> {
        return  this.instance.post('/api/Plano_Contas/', { tbParametros:[params]  } ).then(r => r.data.plano_contas);
    }

    planoContas2(params?:  ApiCore.Plano_Contas.Query): Promise<ApiCore.Plano_Contas.PlanoContasDto2[]> {
        return  this.instance.post('/api/Plano_Contas/', { tbParametros:[params]  } ).then(r => r.data.plano_contas);
    }

    planoContas3(params?:  ApiCore.Plano_Contas.Query): Promise<ApiCore.Plano_Contas.PlanoContasDto3[]> {
        return  this.instance.post('/api/Plano_Contas/', { tbParametros:[params]  } ).then(r => r.data.plano_contas);
    }

    insert(params?:  ApiCore.Plano_Contas.Estrutura): Promise<boolean> {
        params = this.objectToUppercase(params);
        return  this.instance.post('/api/Plano_Contas_Update/', { plano_contas:[params]  } ).then(r => r.data);
    }

    update(params?:  ApiCore.Plano_Contas.Estrutura): Promise<boolean> {
        params = this.objectToUppercase(params);
        return  this.instance.put('/api/Plano_Contas_Update/', { plano_contas:[params]  } ).then(r => r.data);
    }

    delete(params?:  ApiCore.Plano_Contas.Estrutura): Promise<boolean> {
        return  this.instance.delete('/api/Plano_Contas_Update/', { data:{plano_contas:[params]}  } ).then(r => r.data);
    }
}

export default new PlanoContasService();