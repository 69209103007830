/* eslint-disable */
import axios, { AxiosInstance } from 'axios';

export const TamanhoPagina = 10;

export class ApiCoreBase {

    protected readonly instance: AxiosInstance;

    constructor() {
        this.instance = axios.create({ baseURL: process.env.VUE_APP_API_DESK_URL });
    }

    protected objectToUppercase<T>(value: T) : T {
        if (typeof value === 'string') {
            value = value.toUpperCase() as any;
        } else if(typeof value === 'object') {
            for (const key in value) {
                if (Object.prototype.hasOwnProperty.call(value, key)) {
                    value[key] = this.objectToUppercase(value[key]);
                }
            }
        } else if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i++) {
                value[i] = this.objectToUppercase(value[i]);
            }
        }

        return value;
    }
}

export module TipagemParametros {

    export enum TipoColuna {
        Varchar = "varchar",
        Int = "int",
        Short = "smallint",
        Long = "bigint",
    }

    export enum Ordem {
        ASC = "ASC",
        DESC = "DESC"
    }
}
  
export interface IParametros {
    parTipoRetorno?: number;
    parNomeColuna?: string;
    parTipoColuna?: TipagemParametros.TipoColuna;
    parOrdem?: TipagemParametros.Ordem;
    parTop?: number;
    parConteudo?: string;
    Pagina?: number,
    TamanhoPagina?: number,
}

module ApiCore.Bancos {

    export interface Estrutura {        
        pk_sis_bancos: number;
        cod_compensacao?: string;
        nome_banco: string;
        qtd_digitos_ccorrente_no_cmc7?: number;
        digito_id_envelope_dinheiro?: number;
        digito_id_envelope_cheque?: number;
        digito_id_envelope_cheque_pre?: number;
        pagina_internet?: string;
        ativo?: number;
    }

    export interface Query extends IParametros {
        parAtivo?: string;
        parCod_Compensacao?:string;
    }

    export interface BancosDto extends Estrutura {
       
    }

    export interface BancosDto2 {
        pk_sis_bancos: number;
        cod_copensacao: number;
        nome_banco: string;
        qtd_digitos_ccorrente_no_cmc7: number;
        digito_id_envelope_dinheiro: number;
        digito_id_envelope_cheque: number;
        digito_id_envelope_cheque_pre: number;
        pagina_internet: number;
        ativo: boolean;
    } 

    export interface BancosDto3{
        pk_sis_bancos: number;
        nome_banco: string;
    }
}

module ApiCore.Empresas {

    export interface Estrutura {        
        cod_empresa?:number;
        razao_social?:string;
        fk_sis_cep?:number;
        endereco?:string;
        numero?:string;
        complemento?: string;
        bairro?:string;
        cidade?:string;
        uf?:string;
        cgc?:string;
        inscricao_estadual?:string;
        telefone?:string;
        tipo_juros?:number;
        perc_juros?:number;
        valor_multa?:number;
        mensagem_carne?:string;
        mensagem_contrato?:string;
        dias_tolerancia?:number;
        cabecalho_tela?:string;
        cabecalho_relatorio?:string;
        presta_servicos?:number;
        pergunta_cheque?:number;
        entrega_futura_merc?:number;
        num_iten_nota_fiscal?:number;
        perg_aliquotas_venda?:number;
        faturamento_fk_empresas?:number;
        livre1?:number;
        livre2?:number;
        livre3?:number;
        livre4?:number;
        livre5?:number;
        meta2?:number;
        meta3?:number;
        optante_simples_estadual?:number;
        forma_tributacao?:number;
        rateia_despesas?:number;
        cod_cst_pis?:number;
        aliquota_pis?:number;
        cod_cst_cofins?:number;
        aliquota_cofins?:number;
        inscricao_municipal?:string;
        fax?:string;
        nome_contato?:string;
        email?:string;
        csosn?:number;
        fk_sis_cnae?:number;
        regime_trib?:number;
        perc_credito_sn?:number;
        tipo_aprop_credito?:number;
        tipo_contribuicao?:number;
        texto_livre_contrato?:string;
        codigo_cmc?:number;
        hashmd5?:string;
        regime_simples?:number;
        habilita_vendas?:number;
        habilita_vendas_fk_usuarios?:number;
        habilita_vendas_data?:number;
        inativo?:number;
        fk_tabela_preco?:number;
        fk_unidade_estoque?:number;
    }

    export interface Query extends IParametros {
        parInativo?: string;
        parFK_Usuarios?:string;
    }

    export interface EmpresasDto1 extends Estrutura {
       
    }

    export interface EmpresasDto8 {
        cod_empresa: number;
        razao_social: string;
    } 
}
 
module ApiCore.NaturezaOperacao {

    export interface Estrutura {
        pk_sis_cfop?: number;
        cfop?: number;
        descricao?: string;
        aliquota_icms_contribuinte?: number;
        aliquota_icms_nao_contribuinte?: number;
        aliquota_iss?: number;
        inativo?: number;
        texto_fiscal?: string;
        ind_entrada_saida?: number;
        tipo_operacao_especie?: number;
        tipo_operacao_pis_cofins?: number;
        cod_acumulador?: number;
    }

    export interface Query extends IParametros {
        parInativo?: number;
    }

    export interface NaturezaOperacaoDto1 extends Estrutura {

    }
    export interface NaturezaOperacaoDto2 {
        pk_sis_cfop:number;
        cfop:number;
        descricao:string;
        aliquota_icms_contribuinte:number;
        aliquota_icms_nao_contribuinte:number;
        aliquota_iss:number;
        inativo:string;
        texto_fiscal:string;
        ind_entrada_saida:number;
        tipo_operacao_especie:number;
        tipo_operacao_pis_cofins:number;
        cod_acumulador:number;
    }
}

module ApiCore.ModeloNotas {
    export interface Estrutura {
        cod_modelo_nota: number,
        descricao: string,
        formulario_cupom: number,
        especie_documento: string | null,
        serie: number,
        codigo_modelo_exportacao: string | null,
        inativo: number,
        atualizacao_fk_usuarios: number,
        atualizacao_data: number,
        atualizacao_hora: number,
    } 

    export interface Query extends IParametros {
        parInativo?: number;
    }

    export interface ModeloNotaDto extends Estrutura {

    }

    export interface ModeloNotasDto2  {
        cod_modelo_nota: number;
        descricao: string;
        formulario_cupom: number;
        especie_documento: string;
        serie: number;
        codigo_modelo_exportacao: string;
        inativo: number;
        atualizacao_fk_usuarios: number;
        atualizacao_data: number;
        atualizacao_hora: number;
    }

    export interface ModeloNotasDto5 {
        serie?: number;
        inativo?: number;
        descricao: string;
        cod_modelo_nota: number;
        formulario_cupom?: number;
        especie_documento?: string;
        codigo_modelo_exportacao?: string;
    }
}


module ApiCore.Modelos_Notas {

    export interface Estrutura {
        cod_modelo_nota:number;
        descricao:string;
        formulario_cupom:number;
        especie_documento:string;
        serie:number;
        codigo_modelo_exportacao:string;
        inativo: number;
        atualizacao_fk_usuarios:number;
        atualizacao_data:number;
        atualizacao_hora:number;
    }
    
    export interface Modelos_NotasDto1 extends Estrutura{
        
    }

    export interface Query extends IParametros {
        parInativo?: number;  
    }
}

module ApiCore.Busca_Produto_Lancamento{
    export interface Query { 
        parBA_TipoRetorno?: string;
        parBA_NomeColuna?: string;
        parBA_TipoColuna?: string;
        parBA_Ordem?: string;
        parBA_Top?: string;
        parBA_Conteudo?: string;
        parBA_TipoBusca:string,
        parBA_PK_Unidade_Estoque:number,
        parBA_PK_Tabela_Preco:number,
        parBA_OrdemConfiguracao:string,
        parBA_TemCaracterEspecial:number,
        parBA_ProdutoServico:number,
        parBA_BuscaEquivalencia:number,
        parBA_Inativo:string,
        parBA_ForaLinha:string,
        parBA_Status:string,
        parBA_UsarTabelaPadrao:number,
        parBA_CarregaImagem:number,
        parRF_Ind_Busca_Regra_Fiscal:number,
        parRF_Ind_Consumidor_Final:number,
        parRF_Ind_Contribuinte_ICMS:number,
        parRF_Ind_Entrada_Saida:number,
        parRF_Modulo_Lancto:number,
        parRF_UF_Empresa:string,
        parRF_UF_Origem_Destino:string,
        parRF_Cod_Empresa:number,
        parRF_Base_Calculo_PIS_COFINS:string,
        parRF_Ind_Regime_Tributario:number,
        parRF_Ind_ISS_Retido:number,
        parRF_Ind_REIDI:number,
        parBE_Tipo:number,
        parBE_PK:number,
    }

    export interface Busca_Produto_LancamentoDto{ 
        a700_descricao: string;
        a700_pk_produto:number;
        a700_unidade: string;
        a894tam_descricao: string;
        a894cor_descricao: string;
        a699_valor_unitario:number;
        a700_referencia: string;
        a700_cest: string;
        a700_ncm: string;
        a700_gtin: string;
        a700_cod_fabricante: string;
        a710_fk_unidade_estoque:number
        a852_codigo_barras: string;
        a863_preco_custo:number;
        a863_cmv:number;
        a863_ind_permite_saldo_negativo:number;
        a863_estoque_minimo:number;
        a863_estoque_maximo:number;
        a700_lucro_minimo:number;
        a700_status:number;
        a700_inativo:number;
        a700_lote_numero: string;
        a700_validade_data:Date;
        a700_comissao:number;
        a700_volume:number;
        a700_desconto_montagem:number;
        a700_tempo_entrega:number;
        a700_st_retido_base:number;
        a700_st_retido_valor:number;
        a700_ind_precisa_montagem:number;
        a700_ind_producao:number;
        a700_ind_arredondado_truncado:number;
        a700_ind_quantidade_decimal:number;
        a700_ind_fora_linha:number;
        a700_ind_nao_vender_separado:number;
        a700_ind_obrigar_obs_venda:number;
        a700_fk_sis_tipo_servico:number;
        a694_codigo:number;
        a694_descricao: string;
        a694_nfse_codigo:number;
        a694_cnae:number;
        a710_pk_produto_estoque:number;
        a699_pk_tabela_preco_produto:number;
        a695_pk_tabela_preco:number;
        a699_perc_maximo_desconto:number;
        a700_fk_produto_derivado:number;
        a713_imagem:number;
        a768_ind_baixo_estoque:number;
        a768_ind_baixo_estoque_quantidade:number;
        a000_ind_gtin_valido:number;
        a000_ind_ncm_valido:number;
        a000_ind_ncm_pertence_cest:number;
        a700_fracionar_venda_permite:number;
        a708_fator_conversao:number;
        a700_ind_tipo_regra_fiscal:number;
        a894_peso_valor:number;
        a894cor_codigo:number; 
        encontrou_por:number;//em algum ponto remove essa e adiciona essa XX_Quantidade_Lancto e seta pra = quantidade
    }
}

module ApiCore.Produto_Estoque{
    export interface Query extends IParametros {
        parFK_Unidade_Estoque:number, 
        parFK_Produto:number
    }

    export interface Produto_Estoque3{
        estoque:number,
		estoque_reserva:number,
		estoque_sat_loja:number,
		estoque_empresa:number
    }
}

module ApiCore.Produto_Estoque_Busca{
    export interface Query extends IParametros {
        parFK_Unidade_Estoque:string, 
        parFK_Produto:number,
        parEmp_Unidade_Estoque:string,
        parProdutoAtivoInativo:string,
        parFK_Usuarios:string,
        parInd_Acesso:string 
    }
  
    export interface Produto_Estoque_Busca5{
        a768_fk_empresas?:number,
        a768_pk_unidade_estoque?:number,
        a768_descricao?:number,
        a700_pk_produto?:number,
        a700_descricao?:number,
        a700_tamanho?:number,
        a13_descricao?:number,
        axx_saldo_bruto?:number,
        axx_saldo_reserva?:number,
        axx_saldo_requisicao?:number,
        axx_saldo_sat?:number,
        axx_saldo_entregar?:number,
        axx_saldo_estoque?:number,
        a768_estoque_minimo?:number,
        a700_ind_fora_linha?:number,
        a700_status?:number,
        a768_ind_permite_saldo_negativo?:number,
        a768_ind_baixo_estoque?:number,
        a768_ind_baixo_estoque_quantidade?:number,
        a768_inativo?:number
    }
}

module ApiCore.PN_Busca {

    export interface Query extends IParametros {
        parInativo?: number;
        parInd_Cliente?: number;
        parInd_Fornecedor?: number;
        parInd_Vendedor?: number;
        parInd_Usuario?: number;
        parInd_Assistencia?: number;
        parInd_Representante?: number;
        parBuscaEspecial?: string;
        parEmp_Vendedor?: number;
    }


    export interface Estrutura {
        a579_pk_pn?: number;
        a579_tipo_fisica_juridica?: number;
        a579_nome_empresarial?: string;
        a579_nome_fantasia?: string;
        a579_nascimento_data?: number;
        a579_cadastro_data?: number;
        a579_cadastro_hora?: number;
        a579_cadastro_fk_usuarios?: number;
        a579_atualizacao_data?: number;
        a579_atualizacao_hora?: number;
        a579_atualizacao_fk_usuarios?: number;
        a579_inativo?: number;
        a579_ind_contribuinte_icms?: number;
        a579_ind_consumidor_final?: number;
        a579_ind_informar_ie?: number;
        a579_ind_substituto_icms?: number;
        a579_ind_cliente?: number;
        a579_ind_fornecedor?: number;
        a579_ind_vendedor?: number;
        a579_ind_usuario?: number;
        a579_ind_assistencia?: number;
        a579_ind_representante?: number;
        a579_spc_codigo_cadastro?: number;
        a579_atualizacao_cadastro_data?: number;
        a579_iss_retido?: number;
        a579_referencias?: string;
        a579_ind_reidi?: number;
        a579_fk_pn_endereco?: number;
    }

    export interface PNDto2{
        a579_nome_fantasia?: string; 
        a579_pk_pn: number;
        A584_Documento?: string; 
        A579_Nome_Empresarial?: string; 
        A580_Municipio?: string; 
        A580_Endereco?: string; 
        A580_Bairro?: string; 
        A580_UF?: string; 
        A580_FK_Sis_Cep?: number;
        A580_Complemento?: string; 
        A583_Telefone?: string; 
        A584_FK_Sis_Tipo_Documento?: number; 
    }
    
    export interface PNDto3{
        a579_pk_pn: number;
        a579_nome_empresarial?: string; 
        a579_nome_fantasia?: string; 
        a584_documento:string;
        nome_exibicao: string;
    }

    export interface PNDto4 {
        a579_nome_empresarial?: string; 
        a579_pk_pn?: number; 
        a579_nascimento_data?: number;
        a588_naturalidade_municipio?: string;
        a584_documento?: string;
        a579_nome_fantasia?: string;
        a586_email?: string;
        a579_ind_cliente?: number; 
        a579_ind_fornecedor?: number; 
        a579_ind_vendedor?: number; 
        a579_ind_usuario?: number; 
        a579_ind_assistencia?: number; 
        a579_ind_representante?: number; 
    }

    export interface PNDto5 extends PNDto4 {

    }

    export interface PNDto6  {
        a579_pk_pn: number; 
        a680_pk_pn_vendedor: number;  
    }

    export interface PNDto7  {
        
        a579_pk_pn: number; 
        a579_nome_empresarial?: string; 
        a579_nome_fantasia?: string;
        a579_cpf_cnpj:string;
        a580_municipio:string;
    }

    export interface PNDto8  {
        a579_pk_pn: number; 
        a579_nome_empresarial?: string; 
        a579_nome_fantasia?: string;
        a579_cpf_cnpj:string;
        a580_municipio:string; 
    }

    export interface PNDto9  {
        a579_nome_fantasia?: string;
        a579_pk_pn: number; 
        a579_nascimento_data?: number;
        a584_documento?: string;
        a579_nome_empresarial?: string; 
        a583_telefone?: string; 
        a757_renda ?: string; 
        a757_descricao_Cargo?: string; 
        a757_escricao_local_trabalho?: string; 
    }

    export interface PNDto10 {
         pk_pn: number; 
         pk_pn_vendedor: number;  
    }

    export interface PNDto11{
        pk_pn: number; 
        nome_fantasia?: string;
        ie?: string;
        inativo:number;
        versao_sistema?:string;
    }

    export interface PNDto12{
        a579_pk_pn: number; 
        a579_nome_empresarial?: string; 
        a579_nome_fantasia?: string;
        a579_cpf_cnpj:string;
        a580_municipio:string; 
        a586_email:string; 
        b586_email:string; 
        c586_email:string; 
    }

    export interface PNDto13{
        a579_pk_pn: number; 
        a679_pk_pn_agrupadores_movto: number; 
    }

    export interface PNDto14{
        a579_nome_empresarial?: string; 
        v579_a579_cpf_cnpj?: string; 
    }

}

module ApiCore.PN_Cliente {
    export interface Query extends IParametros { 
    }

    export interface Estrutura{
        pk_pn_cliente:number;
        fk_pn:number;
        status:number;
        emitir_carta_fk_pn_endereco:number;
        aviso_dias_tolerancia:number;
    }
}

module ApiCore.PN_Documento_Busca{

    export interface Query extends IParametros {
        parFK_PN?: number;
        parFK_Sis_Tipo_Documento?: number;
        parPK_PN_Documento?: number;
    }

    export interface PN_Documento_Busca_Dto3 {
        a584_pk_pn_documento:number;
        a584_fk_sis_tipo_documento:number;
        a587_descricao:string;
        a584_documento:string;
    }
}

module ApiCore.PN_Telefone_Busca {
       
    export interface Query extends IParametros {
        parFK_PN?: number;
        parPK_PN_Telefone?: number;
        parInativo?: number;
        parConsideraGrupo?: number;
    }

    export interface Estrutura {
        a583_pk_pn_telefone?: number;
        a583_fk_pn?: number;
        a583_fk_sis_tipo_telefone?: number;
        a583_ddi?: string;
        a583_ddd?: string;
        a583_telefone?: string;
        a583_ramal?: string;
        a583_nome_contato?: string;
        a591_descricao?: string; 
        a583_observacao?: string;
        a583_inativo?: number;
        a583_historico_data?: number;
        a583_historico_hora?: number;
        a583_historico_fk_usuarios?: number;
        a583_telefone_formatado?: string;
    }

    export interface PN_Telefone_BuscaDto3 {
        a583_pk_pn_telefone:number;
        a591_descricao:string;
        a583_ddd:string;
        a583_telefone:string;
        a583_ramal:string;
    }
}

module ApiCore.PV_Busca_Dados_PV{
    export interface Query {
        parPK_PV: number; 
        parTipoOperacao:string;
    }

    export interface Estrutura{  
        pv:pv[];
        fatura:fatura[];
        fatura_pv:fatura_pv[];
        fatura_plano_contas:fatura_plano_contas[];
        fatura_titulo:fatura_titulo[];
        plano_contas:plano_contas[];
        plano_pagamento:plano_pagamento[];
        pv_produto:pv_produto[];
        produto_busca_avancada:produto_busca_avancada[];
        tmp_vendedores:tmp_vendedores[];
    }

    export interface fatura_plano_contas{
        pk_fatura_plano_contas: number,
        fk_fatura: number,
        fk_plano_contas: number,
        valor: number
    }

    export interface pv{
        pk_pv:number;
        fk_pn:number;
        fk_empresas:number;
        status:number;
        lancamento_data:number;
        lancamento_hora:number;
        lancamento_fk_usuarios:number;
        atualizacao_data:number;
        atualizacao_hora:number;
        atualizacao_fk_usuarios:number;
        cancelado_data?:number;
        cancelado_hora?:number;
        cancelado_fk_usuarios?:number;
        autorizacao_plano_pagamento_status:number;
        autorizacao_plano_pagamento_fk_usuarios?:number;
        autorizacao_limite_desconto_fk_usuarios?:number;
        comprador_nome:string;
        comprador_cpf_cnpj:string;
        fk_sis_cep?:string;
        localidade?:string;
        bairro?:string;
        logradouro?:string;
        numero_endereco?:string;
        uf?:string;
        observacoes?:string;
        total_bruto:number;
        desconto_percentual_1:number;
        desconto_percentual_2:number;
        desconto_percentual_plano_pagamento:number;
        desconto_valor:number;
        desconto_total:number;
        acrescimo_percentual:number;
        acrescimo_percentual_plano_pagamento:number;
        acrescimo_valor:number;
        acrescimo_total:number;
        frete_valor:number;
        total_liquido:number;
        campo_livre:number;
        ind_faturar:number;
        seguro_valor:number;
    }
    
    export interface fatura{
        pk_fatura:number;
        fk_empresas:number;
        fk_pn:number;
        fk_sis_tipo_fatura:number;
        tipo_movimento:number;
        fk_plano_pagamento:number;
        numero_fatura:string;
        numero_titulos:number;
        emissao_data:number;
        emissao_hora:number;
        fatura_total:number;
        historico:number;
        dias_tolerancia:number;
        lancamento_data:number;
        lancamento_hora:number;
        lancamento_fk_usuarios:number;
        competencia_data:number;
        status:number;
        atualizacao_data:number;
        atualizacao_hora:number;
        atualizacao_fk_usuarios:number;
        total_acrescimo:number;
        total_desconto:number;
        deflacao_perc:number;
    }

    export interface fatura_pv{
        pk_fatura_pv:number;
        fk_fatura:number;
        fk_pv:number;
        total_pv:number;
        total_fatura:number;
    }

    export interface fatura_plano_contas{
        pk_fatura_plano_contas:number;
        fk_fatura:number;
        fk_plano_contas:number;
        valor:number;
    }
  
    export interface fatura_titulo{
        pk_fatura_titulo:number;
        fk_fatura:number;
        status:number;
        titulo_numero:number;
        titulo_valor:number;
        juros_valor:number;
        desconto_valor:number;
        multa_valor:number;
        vencimento_data:number;
        pagamento_data:number;
        lancamento_fk_usuarios:number;
        pagamento_fk_usuarios:number;
        numero_documento:string;
        credito_devolucao:number;
        outras_despesas:number;
        fk_portador?:number;
        aceite_data:number;
        observacao:string;
        atualizacao_data:number;
        atualizacao_hora:number;
        atualizacao_fk_usuarios:number;
        fk_cx_cab:number;
    }
  
    export interface plano_contas{
        pk_plano_contas:number;
        cod_estruturado:string;
        descricao:string;
        tipo_de_conta:number;
        status:number;
        rateia_custos:number;
        origem:number;
        conta:number;
        tipo_plano:number;
        cod_plano_conta_ref_rfb:number;
        inativo:number;
    }

    export interface plano_pagamento{
        pk_plano_pagamento:number;
        fk_plano_pagamento_grupo:number;
        descricao:string;
        ind_usa_venda:number;
        ind_usa_compra:number;
        ind_dia_fixo:number;
        ind_precisa_autorizacao:number;
        entrada:number;
        entrada_dinheiro:number;
        entrada_cheque_terceiros:number;
        entrada_cheque_terceiros_dias_limite:number;
        entrada_cheque_proprio:number;
        entrada_conta_corrente:number;
        entrada_credito:number;
        entrada_cartao_debito:number;
        entrada_cartao_credito:number;
        entrada_cartao_credito_parcelas:number;
        parcelas_numero:number;
        parcelas_crediario:number;
        parcelas_cheque:number;
        parcelas_cartao_credito:number;
        juros_tipo:number;
        juros_mes:number;
        juros_ano:number;
        juros_descontar_entrada?:number;
        acrescimo_perc:number;
        desconto_perc:number;
        desconto_perc_max:number;
        deflacao_perc:number;
        comissao_perc:number;
        venda_valor_minimo:number;
        tac_valor:number;
        tef_trans_vl_parc:number;
        tef_tipo_transacao:number;
        tef_tipo_parcelamento:number;
        tef_trans_dados:number;
        tef_trans_qtd_parc:number;
        tef_trans_dt_parc:number;
        inativo:number;
        lancamento_data?:number;
        lancamento_hora?:number;
        lancamento_fk_usuarios?:number;
        atualizacao_data?:number;
        atualizacao_hora?:number;
        atualizacao_fk_usuarios?:number;
        ind_destino:number;
    }

    export interface pv_produto{
        pk_pv_produto:number;
        fk_pv:number;
        sequencia:number;
        fk_unidade_estoque:number;
        fk_produto:number;
        status:number;
        fk_tabela_preco:number;
        fk_produto_kit_produto?:number;
        fk_pn_vendedor:number;
        observacao:string;
        valor_unitario:number;
        quantidade:number;
        total_bruto:number;
        acrescimo_percentual:number;
        acrescimo_valor:number;
        acrescimo_total:number;
        desconto_percentual:number;
        desconto_valor:number;
        desconto_total:number;
        total_liquido:number;
        frete_valor:number;
        seguro_valor:number;
    }
     
    export interface produto_busca_avancada{
        a700_descricao:string;
        a700_pk_produto:number;
        a700_unidade:string;
        a894tam_descricao:string;
        a894cor_descricao:string;
        a699_valor_unitario:number;
        a700_referencia:string;
        a700_cest:string;
        a700_ncm:string;
        a700_gtin:string;
        a700_cod_fabricante:string;
        a710_fk_unidade_estoque:number;
        a852_codigo_barras:number;
        a863_preco_custo:number;
        a863_cmv:number;
        a863_ind_permite_saldo_negativo:number;
        a863_estoque_minimo:number;
        a863_estoque_maximo:number;
        a700_lucro_minimo:number;
        a700_status:number;
        a700_inativo:number;
        a700_lote_numero:string;
        a700_validade_data:Date;
        a700_comissao:number;
        a700_volume:number;
        a700_desconto_montagem:number;
        a700_tempo_entrega:number;
        a700_st_retido_base:number;
        a700_st_retido_valor:number;
        a700_ind_precisa_montagem:number;
        a700_ind_producao:number;
        a700_ind_arredondado_truncado:number;
        a700_ind_quantidade_decimal:number;
        a700_ind_fora_linha:number;
        a700_ind_nao_vender_separado:number;
        a700_ind_obrigar_obs_venda:number;
        a700_fk_sis_tipo_servico:number;
        a694_codigo:number;
        a694_descricao:number;
        a694_nfse_codigo:number;
        a694_cnae:number;
        a710_pk_produto_estoque:number;
        a699_pk_tabela_preco_produto:number;
        a695_pk_tabela_preco:number;
        a699_perc_maximo_desconto:number;
        a700_fk_produto_derivado:number;
        a713_imagem?:string;
        a768_ind_baixo_estoque:number;
        a768_ind_baixo_estoque_quantidade:number;
        a000_ind_gtin_valido:number;
        a000_ind_ncm_valido:number;
        a000_ind_ncm_pertence_cest:number;
        a700_fracionar_venda_permite:number;
        a708_fator_conversao:number;
        a700_ind_tipo_regra_fiscal:number;
        a894_peso_valor:number;
        a894cor_codigo:number;
        encontrou_por:number;
    }

    export interface tmp_vendedores{
        pk_pn:number;
        pk_pn_vendedor:number;
    }


}

module ApiCore.UnidadeEstoque_Busca {
 
}

module ApiCore.Empresas_Update {

    export interface Command extends ApiCore.Empresas.Estrutura { 

    }
}

module ApiCore.UnidadeEstoque {

    export interface Estrutura {
        pk_unidade_estoque?: number;
        fk_empresas?: number;
        descricao?: string;
        inativo?: number;
        ind_baixo_estoque?: number;
        ind_baixo_estoque_quantidade?: number;
    }

    export interface Query extends IParametros {
        parInativo?: string;
        parFK_Empresas?: string;
        parFK_Usuario?: string;
        parInd_Acesso?: string;
    }

    export interface UnidadeEstoqueDto1 extends Estrutura {
       
    }

    export interface UnidadeEstoqueDto2 {
        pk_unidade_estoque: number;
        fk_empresas: number;
        descricao: string;
        inativo: number;
        ind_baixo_estoque: number;
        ind_baixo_estoque_quantidade: number;
    }

    export interface UnidadeEstoqueDto3 {
        pk_unidade_estoque: number;
        descricao: string;
    }

    export interface UnidadeEstoqueDto4 {
        pk_unidade_estoque: number;
        descricao: string;
    }
}

module ApiCore.UnidadeEstoque_Busca {

    export interface Query extends IParametros {
        parFK_Empresas?: string;
        parFK_Usuario?: string;
        parInd_Acesso?: string;
    }

    export interface UnidadeEstoqueDto2 {
        A710_pk_produto_estoque: number;
        A768_fk_empresas: number;
        A710_fk_unidade_estoque: number;
        A768_descricao: string;
        A710_estoque_sat_loja: number;
        A710_estoque_reserva: number;
        A710_estoque: number;
        A710_localizacao: string;
        A768_Inativo: number;
        A710_estoque_copia: number;
        A710_hash_md5: string;
    }

    export interface UnidadeEstoqueDto2 {
        pk_unidade_estoque: number;
        fk_empresas: number;
        descricao: string;
        inativo: number;
        ind_baixo_estoque: number;
        ind_baixo_estoque_quantidade: number;
    }

    export interface UnidadeEstoqueDto3 {
        pk_unidade_estoque: number;
        descricao: string;
    }

    export interface UnidadeEstoqueDto4 {
        pk_unidade_estoque: number;
        descricao: string;
    }
}
 
module ApiCore.UnidadeEstoque_Update {

    export interface Command extends ApiCore.UnidadeEstoque.Estrutura {

    }

}

module ApiCore.Tabela_Preco_Busca {
 
    export interface Query extends IParametros {
        parInativo?: string; 
        parWhere?:string;
        parPK_Produto:string;
        parPn:number;
    }
    export interface Tabela_PrecoDto16 { 
        pk_tabela_preco: number;
        descricao_longa:string;
        descricao_curta: string;
        incluir_produto_automatico: number;
        tipo_tabela: number;
        desc_acresc_perc: number;
        usar_tabela_padrao: number;
        perc_maximo_desconto: number;
        vigencia_inicial: number;
        vigencia_final: number;
        arredondamento_regra: number;
        arredondamento_limite: number;
        ind_tipo_venda: number;
        ind_tabela_padrao: number;
        ordem: number;
        inativo: number;
        lancamento_data: number;
        lancamento_hora: number;
        lancamento_fk_usuarios: number;
        atualizacao_data: number;
        atualizacao_hora: number;
        atualizacao_fk_usuarios: number;
        ind_recalculo_valor_unitario: number; 
        ind_destino:number;
    } 
}
module ApiCore.PN_Agrupadores {

    export interface Estrutura {
        pk_pn_agrupadores?: number;
        descricao?: string;
        inativo?: number;
        niveis?: number;
        mascara?: string;
        ind_unico?: number;
        ind_obrigatorio?: number;
        ind_cliente?: number;
        ind_fornecedor?: number;
        ind_vendedor?: number;
        ind_usuario?: number;
        ind_assistencia?: number;
        ind_representante?: number;
    }
    export interface IPN_Agrupadores extends Estrutura{
        pk_pn_agrupadores_movto_pn?: number;
    }
    export interface Query extends IParametros {
        parInativo?: number; 
        parInd_Unico?: number; 
        parInd_Fornecedor?: number; 
    }
}

module ApiCore.PN_Agrupadores_Busca {

    export interface Estrutura {
        a677_pk_pn_agrupadores?: number;
        a677_descricao?: string;
        a677_inativo?: number;
        a677_niveis?: number;
        a677_mascara?: string;
        a677_ind_unico?: number;
        a677_ind_obrigatorio?: number;
        a677_ind_cliente?: number;
        a677_ind_fornecedor?: number;
        a677_ind_vendedor?: number;
        a677_ind_usuario?: number;
        a677_ind_assistencia?: number;
        a677_ind_representante?: number;
        a678_pk_pn_agrupadores_movto?: number;
        a678_descricao?: string;
        a679_pk_pn_agrupadores_movto_pn?: number;
    }
    export interface Query extends IParametros {
        parInativo?: number; 
        parInd_Unico?: number; 
        parInd_Fornecedor?: number; 
        parFK_PN?: number;
    }
}

module ApiCore.PN_Agrupadores_Movto {

    export interface Estrutura {
        pk_pn_agrupadores_movto?: number;
        fk_pn_agrupadores?: number;
        codigo_estruturado?: string;
        descricao?: string;
        inativo?: number;
        tipo?: number;
        nivel?: number;

    }
    export interface Query extends IParametros {
        parFK_PN_Agrupadores?: number;
        parInativo?: number; 
        parWhere?: string;
    }
}

module ApiCore.PN_Agrupadores_Movto_Busca {

    export interface Estrutura {
        a678_pk_pn_agrupadores_movto?: number;
        a678_descricao?: string;
        a678_fk_pn_agrupadores?: number;
        a677_descricao?: string;
        a678_inativo?: number;
        a678_niveis?: number;
        a678_mascara?: string;
        a678_ind_unico?: number;
        a678_ind_obrigatorio?: number;
        a678_ind_cliente?: number;
        a678_ind_fornecedor?: number;
        a678_ind_vendedor?: number;
        a678_ind_usuario?: number;
        a678_ind_assistencia?: number;
        a678_ind_representante?: number;
    }
    export interface Query extends IParametros {
    }
}

module ApiCore.PN_Agrupadores_Movto_PN {

    export interface Estrutura {
        pk_pn_agrupadores_movto_pn?: number;
        fk_pn?: number;
        fk_pn_agrupadores_movto?: number;
    }
    export interface Query extends IParametros {
    }
}


module ApiCore.PN {

    export interface Estrutura {
        pk_pn?: number;
        tipo_fisica_juridica?: number;
        nome_empresarial?: string;
        nome_fantasia?: string;
        fk_usuarios?: number;
        nascimento_data?: number;
        cadastro_fk_usuarios?:number;
        cadastro_data?: number;
        cadastro_hora?: number;
        atualizacao_data?: number;
        atualizacao_hora?: number;
        atualizacao_fk_usuarios?: number;
        inativo?: number;
        ind_contribuinte_icms?: number;
        ind_consumidor_final?: number;
        ind_informar_ie?: number;
        ind_substituto_icms?: number;
        ind_cliente?: number;
        ind_fornecedor?: number;
        ind_vendedor?: number;
        ind_usuario?: number;
        ind_assistencia?: number;
        ind_representante?: number;
        spc_codigo_cadastro?: number;
        atualizacao_cadastro_data?: number;
        iss_retido?: number;
        referencias?: string;
        ind_reidi?: number;
        fk_pn_endereco?: number;
    }

    export interface Query extends IParametros {
        parInativo?: number;
        parPK_PN?: number;
    }

    export interface IPnUpdate{
        pn: ApiCore.PN.Estrutura[];
        pn_endereco_insert?: ApiCore.PN_Endereco.Estrutura[];
        pn_endereco_update?: ApiCore.PN_Endereco.Estrutura[];
        pn_endereco_delete?: ApiCore.PN_Endereco.Estrutura[];
    }

    export interface DadosCadastrais{

        cnpj: string;
        ie: string;
        razaoSocial: string;
        nomeFantasia: string;
        endereco: string;
        numero: string;
        complemento: string;
        bairro: string;
        uf: string;
        cidade: string;
        cep: string;
        email: string;
        telefone: string;
        cnaePrincipal: string;
        dataRegistro: string;
    }
}

module ApiCore.PN_Busca {

    export interface Estrutura {
        a579_pk_pn?: number;
        a579_tipo_fisica_juridica?: number;
        a579_nome_empresarial?: string;
        a579_nome_fantasia?: string;
        a579_nascimento_data?: number;
        a579_cadastro_data?: number;
        a579_cadastro_hora?: number;
        a579_cadastro_fk_usuarios?: number;
        a579_atualizacao_data?: number;
        a579_atualizacao_hora?: number;
        a579_atualizacao_fk_usuarios?: number;
        a579_inativo?: number;
        a579_ind_contribuinte_icms?: number;
        a579_ind_consumidor_final?: number;
        a579_ind_informar_ie?: number;
        a579_ind_substituto_icms?: number;
        a579_ind_cliente?: number;
        a579_ind_fornecedor?: number;
        a579_ind_vendedor?: number;
        a579_ind_usuario?: number;
        a579_ind_assistencia?: number;
        a579_ind_representante?: number;
        a579_spc_codigo_cadastro?: number;
        a579_atualizacao_cadastro_data?: number;
        a579_iss_retido?: number;
        a579_referencias?: string;
        a579_ind_reidi?: number;
        a579_fk_pn_endereco?: number;
    }

    export interface IPN_Busca_Representante_PN extends Estrutura{
        a594_pk_pn_representante_pn?: number;
    }

    export interface Query extends IParametros {
        parInativo?: number;
        parInd_Cliente?: number;
        parInd_Fornecedor?: number;
        parInd_Vendedor?: number;
        parInd_Usuario?: number;
        parInd_Assistencia?: number;
        parInd_Representante?: number;
        parBuscaEspecial?: string;
        parEmp_Vendedor?: number;
    }

    export interface PNDto4 extends Estrutura {
        a579_nome_empresarial?: string; 
        a579_pk_pn?: number; 
        a579_nascimento_data?: number;
        a588_naturalidade_municipio?: string;
        a584_documento?: string;
        a579_nome_fantasia?: string;
        a586_email?: string;
        a579_ind_cliente?: number; 
        a579_ind_fornecedor?: number; 
        a579_ind_vendedor?: number; 
        a579_ind_usuario?: number; 
        a579_ind_assistencia?: number; 
        a579_ind_representante?: number; 
    }
}

module ApiCore.Plano_Contas {

    export interface Estrutura {
        pk_plano_contas?: number; 
        cod_estruturado?: string; 
        descricao?: string; 
        tipo_de_conta?: number; 
        status?: number; 
        rateia_custos?: number; 
        origem?: number; 
        conta?: number; 
        tipo_plano?: number; 
        cod_plano_conta_ref_rfb?: string; 
        inativo?: number; 
    }
    export interface Query extends IParametros {
        parInativo?: string;
        parConta?:string;
        parStatus?:string;
        parPK_Plano_Contas?:string;
    }

    export interface PlanoContasDto1 extends Estrutura {
       
    }

    export interface PlanoContasDto2 {
        pk_plano_contas: number;
        descricao: string;
    } 

    export interface PlanoContasDto3 {
        pk_plano_contas: number;
        descricao: string;
    } 
}

module ApiCore.PN_Endereco {

    export interface Estrutura {
        pk_pn_endereco?: number; 
        fk_pn?: number; 
        fk_sis_tipo_endereco?: number; 
        fk_sis_cep?: number; 
        fk_sis_paises?: number; 
        uf?: string; 
        municipio?: string; 
        bairro?: string; 
        endereco?: string; 
        complemento?: string; 
        numero?: string; 
        caixa_postal: string; 
        inativo?: number; 
        historico_data?: number; 
        historico_hora?: number; 
        historico_fk_usuarios?: number; 
        referencia?: string; 
    }

    export interface Query extends IParametros {
        parInativo?: number;
        parFK_PN?: number;
        parFK_Sis_Tipo_Endereco?: number;
    }

    export interface IPnEnderecoUpdate{
        pn_endereco_insert: ApiCore.PN_Endereco.Estrutura[];
        pn_endereco_update: ApiCore.PN_Endereco.Estrutura[];
        pn_endereco_delete: ApiCore.PN_Endereco.Estrutura[];
    }
}


module ApiCore.PN_Fisica {

    export interface Estrutura {
        pk_pn_fisica?: number; 
        fk_pn?: number; 
        sexo?: number; 
        naturalidade_municipio?: string; 
        naturalidade_uf?: string; 
        falecido?: number; 
        estado_civil?: number; 
        ind_escolaridade?: number; 
    }

    export interface Query extends IParametros {
        parFK_PN?: number;
        parPK_PN_Fisica?: number;
    }
}

module ApiCore.PN_Vinculo {

    export interface Estrutura {
        pk_pn_vinculo?: number;
        fk_pn?: number;
        fk_pn_vinculado?: number;
        fk_sis_pn_tipo_vinculo?: number;
        nome?: string;
        nascimento_data?: number;
        ind_dependente?: number;
        ind_autoriza_venda?: number;
        ind_contribuicao_renda?: number;
        renda?: number;
        documento?: string;
        cargo?: string;
        descricao_local_trabalho?: string;
        filiacao?: string;
        telefone?: string;
        inativo?: number;
    }

    export interface Query extends IParametros {
    }
}

module ApiCore.Sis_Status_Cliente {

    export interface Estrutura {
        pk_sis_status_cliente?: number;
        descricao?: string;
        inativo?: number;
    }

    export interface Query extends IParametros {
    }
}

module ApiCore.Sis_Cbo {

    export interface Estrutura {
        pk_sis_cbo?: number;
        codigo?: number;
        descricao?: string;
        inativo?: number;
    }

    export interface Query extends IParametros {
    }
}

module ApiCore.PN_Vinculo_Busca {

    export interface Estrutura {
        a767_pk_pn_vinculo?: number;
        a767_fk_pn?: number;
        a767_fk_pn_vinculado?: number;
        a767_fk_sis_pn_tipo_vinculo?: number;
        a767_nome?: string;
        a767_nascimento_data?: number;
        a767_ind_dependente?: number;
        a767_ind_autoriza_venda?: number;
        a767_ind_contribuicao_renda?: number;
        a767_renda?: number;
        a767_documento?: string;
        a767_cargo?: string;
        a767_descricao_local_trabalho?: string;
        a767_filiacao?: string;
        a767_telefone?: string;
        a767_inativo?: number;
        a691_descricao?: string;
    }

    export interface Query extends IParametros {
        parFK_PN?: number;
        parPK_PN_Vinculo?: number;
        parInativo?: number;
    }
}

module ApiCore.PN_Dados_Trabalho {

    export interface Estrutura {
        pk_pn_dados_trabalho?: number; 
        fk_pn?: number; 
        fk_sis_cbo?: number; 
        renda?: number; 
        renda_complementar?: number; 
        telefone?: string; 
        pessoa_contato?: string; 
        confirma_vinculo_empresa?: number; 
        descricao_local_trabalho?: string; 
        admissao_data?: number; 
        descricao_cargo?: string; 
        inativo?: number; 
        historico_data?: number; 
        historico_hora?: number; 
        historico_fk_usuarios?: number; 
    }

    export interface Query extends IParametros {
        parInativo: string
    }
}

module ApiCore.PN_Limite_Credito {

    export interface Estrutura {
        pk_pn_limite_credito?: number; 
        fk_pn?: number; 
        limite_credito_anterior?: number; 
        limite_credito_atual?: number; 
        limite_credito_usuario?: number; 
        inativo?: number; 
    }

    export interface Query extends IParametros {
        parInativo: number;
    }
}

module ApiCore.PN_Cnae {

    export interface Estrutura {
        pk_pn_cnae?: number; 
        fk_pn?: number; 
        fk_sis_cnae?: number; 
        cnae_principal?: number; 
    }

    export interface Query extends IParametros {
    }
}


module ApiCore.PN_Fornecedor {

    export interface Estrutura {
        pk_pn_fornecedor?: number; 
        fk_pn?: number; 
        prazo_medio_entrega?: number; 
        margem_lucro_padrao?: number; 
        fk_plano_contas?: number; 
        ind_parcelamento?: number; 
    }

    export interface Query extends IParametros {
    }
}

module ApiCore.PN_Vendedor {

    export interface Estrutura {
        pk_pn_vendedor?: number;
        fk_pn?: number;
        fk_empresas?: number;
        meta_perc_padrao?: number;
        senha?: string;
        inativo?: number;
        fk_plano_contas?: number;
        tipo_vendedor?: number;
        perc_desc_max_venda?: number;

    }

    export interface Query extends IParametros {
    }
}

module ApiCore.PN_Vendedor_Remuneracao {

    export interface Estrutura {
        pk_pn_vendedor_remuneracao?: number;
        fk_pn?: number;
        valor?: number;
        mes_inicial?: number;
        mes_final?: number;
    }

    export interface Query extends IParametros {
    }
}

module ApiCore.PN_Representante {

    export interface Estrutura {
        pk_pn_representante?: number;
        fk_pn?: number;
        fk_sis_tipo_representante?: number;

    }

    export interface Query extends IParametros {
    }
}

module ApiCore.PN_Representante_PN_Busca {

    export interface Dto2 {
        a594_pk_pn_representante_pn?: number;
        a594_fk_pn_representante?: number;
        a594_fk_pn_fornecedor?: number;
        a579_nome_empresarial?: string;
        a579_nome_fantasia?: string;
        a583_telefone?: string;
    }

    export interface Dto3 {
        a594_pk_pn_representante_pn?: number;
        a594_fk_pn_representante?: number;
        a594_fk_pn_fornecedor?: number;
        a579_nome_empresarial?: string;
        a579_nome_fantasia?: string;
        a583_telefone?: string;
    }

    export interface Query extends IParametros {
    }
}

module ApiCore.PN_Representante_Produto_Agrupadores_Movto {
    export interface Estrutura {
        pk_pn_representante_produto_agrupadores_movto?: number;
        fk_pn?: number;
        fk_produto_agrupadores_movto?: number;
    }

    export interface Query extends IParametros {
    }
}

module ApiCore.PN_Representante_Produto_Agrupadores_Movto_Busca {
    export interface Estrutura {
        a765_pk_pn_representante_produto_agrupadores_movto?: number;
        a765_fk_pn?: number;
        a765_fk_produto_agrupadores_movto?: number;
        a702_descricao?: string;
        a704_descricao?: string;
    }

    export interface Query extends IParametros {
    }
}

module ApiCore.Produto_Agrupadores_Movto_Busca {
    export interface Estrutura {
        a704_pk_produto_agrupadores_movto?: number;
        a704_descricao?: string;
        a704_fk_produto_agrupadores?: number;
    }

    export interface Query extends IParametros {
        parInativo?: number;
        parFk_produto_agrupadores_movto?: number;
    }
}

module ApiCore.PN_Endereco_Busca {
    export interface Estrutura {
        a580_pk_pn_endereco?: number;
        a580_fk_pn?: number;
        a580_fk_sis_tipo_endereco?: number;
        a581_descricao?: string;
        a580_fk_sis_cep?: number;
        a580_endereco?: string;
        a580_numero?: string;
        a580_municipio?: string;
        a580_bairro?: string;
        a580_uf?: string;
        a580_complemento?: string;
        a580_caixa_postal?: string;
        a580_fk_sis_paises?: number;
        a580_inativo?: number;
        a580_historico_data?: number;
        a580_historico_hora?: number;
        a580_historico_fk_usuarios?: number;
    }
    export interface IPN_Endereco_Busca extends Estrutura{
        parRowPainted?: boolean;
    }

    export interface Query extends IParametros {
        parInativo?: number;
    }
}

module ApiCore.PN_Telefone {

    export interface Estrutura {
        pk_pn_telefone?: number;
        fk_pn?: number;
        fk_sis_tipo_telefone?: number;
        ddi?: string;
        ddd?: string;
        telefone?: string;
        ramal?: string;
        nome_contato?: string;
        observacao?: string;
        inativo?: number;
        historico_data?: number;
        historico_hora?: number;
        historico_fk_usuarios?: number;
    }

    export interface Query extends IParametros {
        parFK_PN?: number;
        parPK_PN_Telefone?: number;
        parInativo?: number;
    }

    export interface IPnTelefoneUpdate{
        pn_telefone_insert: ApiCore.PN_Telefone.Estrutura[];
        pn_telefone_update: ApiCore.PN_Telefone.Estrutura[];
        pn_telefone_delete: ApiCore.PN_Telefone.Estrutura[];
    }
}

module ApiCore.PN_Telefone_Busca {
    export interface Estrutura {
        a583_pk_pn_telefone?: number;
        a583_fk_pn?: number;
        a583_fk_sis_tipo_telefone?: number;
        a583_ddi?: string;
        a583_ddd?: string;
        a583_telefone?: string;
        a583_ramal?: string;
        a583_nome_contato?: string;
        a591_descricao?: string;
        a583_observacao?: string;
        a583_inativo?: number;
        a583_historico_data?: number;
        a583_historico_hora?: number;
        a583_historico_fk_usuarios?: number;
        a583_telefone_formatado?: string;
    }

    export interface Query extends IParametros {
        parFK_PN?: number;
        parPK_PN_Telefone?: number;
        parInativo?: number;
        parConsideraGrupo?: number;
    }
}

module ApiCore.PN_Documento{

    export interface Estrutura {
        pk_pn_documento?: number;
        fk_pn?: number;
        fk_sis_tipo_documento?: number;
        documento?: string;
        orgao_emissor?: string;
        uf?: string;
        historico_data?: number;
        historico_hora?: number;
        historico_fk_usuarios?: number;
    }
    export interface Query extends IParametros {
        parFK_PN?: number;
        parFK_Sis_Tipo_Documento?: number;
        parPK_PN_Documento?: number;
    }

    export interface IPnDocumentoUpdate{
        pn_documento_insert: ApiCore.PN_Documento.Estrutura[];
        pn_documento_update: ApiCore.PN_Documento.Estrutura[];
        pn_documento_delete: ApiCore.PN_Documento.Estrutura[];
    }
}

module ApiCore.PN_Documento_Busca{

    export interface Estrutura {
        a584_pk_pn_documento?: number;
        a584_fk_pn?: number;
        a584_fk_sis_tipo_documento?: number;
        a587_descricao?: string;
        a584_documento?: string;
        a584_orgao_emissor?: string;
        a584_uf?: string;
        a584_historico_data?: number;
        a584_historico_hora?: number;
        a584_historico_fk_usuarios?: number;
    }
    export interface Query extends IParametros {
        parFK_PN?: number;
        parFK_Sis_Tipo_Documento?: number;
        parPK_PN_Documento?: number;
    }
}

module ApiCore.PN_Email{

    export interface Estrutura {
        pk_pn_email?: number;
        fk_pn?: number;
        fk_sis_tipo_email?: number;
        email?: string;
        inativo?: number;
        historico_data?: number;
        historico_hora?: number;
        historico_fk_usuarios?: number;
    }

    export interface Query extends IParametros {
        parFK_PN?: number;
        parFK_Sis_Tipo_Email?: number;
        parInativo?: number;
        parPK_PN_Email?: number;
    }

    export interface IPnEmailUpdate{
        pn_email_insert: ApiCore.PN_Email.Estrutura[];
        pn_email_update: ApiCore.PN_Email.Estrutura[];
        pn_email_delete: ApiCore.PN_Email.Estrutura[];
    }
}

module ApiCore.PN_Email_Busca{

    export interface Estrutura {
        a586_pk_pn_email?: number;
        a586_fk_pn?: number;
        a586_fk_sis_tipo_email?: number;
        a590_descricao?: string;
        a586_email?: string;
        a586_inativo?: number;
        a586_historico_data?: number;
        a586_historico_hora?: number;
        a586_historico_fk_usuarios?: number;
    }

    export interface Query extends IParametros {
        parFK_PN?: number;
        parInativo?: number;
        parPK_PN_Email?: number;
    }
}

module ApiCore.PN_Observacao {

    export interface Estrutura {
        pk_pn_observacao?: number;
        fk_pn?: number;
        fk_pn_tipo_observacao?: number;
        observacao?: string;
        prioridade?: number;
        inclusao_data?: number;
        inclusao_hora?: number;
        inclusao_fk_usuarios?: number;
        inativo?: number;
        historico_data?: number;
        historico_hora?: number;
        historico_fk_usuarios?: number;
    }

    export interface Query extends IParametros {
        parInativo?: number;
        parFK_PN?: number;
        parFK_PN_Tipo_Observacao?: number;
        parPK_PN_Observacao?: number;
    }

    export interface IPnObservacaoUpdate{
        pn_observacao_insert: ApiCore.PN_Observacao.Estrutura[];
        pn_observacao_update: ApiCore.PN_Observacao.Estrutura[];
        pn_observacao_delete: ApiCore.PN_Observacao.Estrutura[];
    }
}

module ApiCore.PN_Observacao_Busca{

    export interface Estrutura {
        a593_pk_pn_observacao?: number;  
        a593_fk_pn?: number;  
        a593_fk_pn_tipo_observacao?: number;  
        a595_descricao?: string;  
        a593_inclusao_data?: string;   
        a593_inclusao_hora?: string;   
        a593_observacao?: string;     
        a593_inclusao_fk_usuarios?: number;   
        a593_prioridade?: number;   
        a593_inativo?: number;   
        a593_historico_data?: number;   
        a593_historico_hora?: number;   
        a593_historico_fk_usuarios?: number;   
    }

    export interface IPNObservacao_Busca extends Estrutura {
        parRowPainted?: boolean;
    }
    
    export interface Query extends IParametros {
        parInativo?: number;
        parFK_PN?: number;
        parFK_PN_Tipo_Observacao?: number;
        parPK_PN_Observacao?: number;
    }

    export interface PN_Observacao_BuscaDto2{
        a593_pk_pn_observacao:number;
        a593_fk_pn:number;
        a593_fk_pn_tipo_observacao:number;
        a595_descricao:string;
        a593_inclusao_data:Date;
        a593_inclusao_hora:string;
        a593_observacao:string;
        a593_inclusao_fk_usuarios:number;
        a593_prioridade:number;
        a593_inativo:number
        a593_historico_data:Date;
        a593_historico_hora:string;
        a593_historico_fk_usuarios:number;
    }
}

module ApiCore.Sis_PN_Tipo_Vinculo {

    export interface Estrutura {
        pk_sis_pn_tipo_vinculo?: number; 
        descricao?: string; 
        inativo?: number;
    }

    export interface Query extends IParametros {
        parInativo?: number;
    }
}

module ApiCore.PN_Tipo_Observacao {

    export interface Estrutura {
        pk_pn_tipo_observacao?: number; 
        descricao?: string; 
        inativo?: number;
    }

    export interface Query extends IParametros {
        parInativo?: number;
    }
}


module ApiCore.Generica {
    export interface Query {
        parTipoRetorno?: number;
        par1?:number;
        par2?:number;
        par3?:number;
        par4?:number;
        par5?:number;
        par6?:string;
        par7?:string;
        par8?:string;
        par9?:string;
        par10?:string;
        par11?:string;
        par12?:string;
        par13?:string;
        par14?:string;
    }

    export interface GenericaDto51  {
        total: number;
    }
    export interface GenericaDto68  {
        fatura: number;
        pedido_compra: number;
        portador: number;
        tabela_preco: number;
    }
}


module ApiCore.Sis_Cep {

    export interface Estrutura {
        logradouro?: string;
        localidade?: string;
        bairro?: string;
        cep?: number;
        uf?: string;
        tipo?: string;
        cod_fiscal_municipio?: number;
        cod_ibge_municipio?: number;
    
    }
    export interface Query extends IParametros {
        parInativo?: number;
    }
}

module ApiCore.Sis_Cnae {

    export interface Estrutura {
        pk_sis_cnae?: number;
        cod_cnae?: string;
        descricao?: string;
    
    }
    export interface Query extends IParametros {
    }
}

module ApiCore.Sis_Tipo_Documento {

    export interface Estrutura {
        pk_sis_tipo_documento?: number; 
        descricao?: string; 
        inativo?: number; 
    }

    export interface Query extends IParametros {
        parInativo?: number;
        parTipoPN?: number;
    }
}

module ApiCore.Sis_Tipo_Endereco {

    export interface Estrutura {
        pk_sis_tipo_endereco?: number; 
        descricao?: string; 
    }

    export interface Query extends IParametros {
        parInativo?: number;
    }
}

module ApiCore.Sis_Tipo_Email {

    export interface Estrutura {
        pk_sis_tipo_email?: number; 
        descricao?: string; 
        inativo?: number;
    }

    export interface Query extends IParametros {
        parInativo?: number;
    }
}

module ApiCore.Sis_Tipo_Telefone {

    export interface Estrutura {
        pk_sis_tipo_telefone?: number; 
        descricao?: string; 
        inativo?: number; 
    }

    export interface Query extends IParametros {
        parInativo?: number;
    }
}

module ApiCore.Sis_Tipo_Representante {

    export interface Estrutura {
        pk_sis_tipo_representante?: number; 
        descricao?: string; 
        inativo?: number; 
    }

    export interface Query extends IParametros {
        parInativo?: number;
    }
}

export default ApiCore; 