import { AuthrorizationPaths } from '@/features/api-authorization/api-authorization-constants';
import api from "@/base/api.typings";

export const ApplicationPaths = {

    Authrorization: AuthrorizationPaths,

    Users: {
        LinkUser: "/user/link",
    },

    Home: '/',
}

export class UF {
    CarregaUF(acrescentarVazio: boolean, acrescentarEx: boolean) :api.UF.UfDto[] {

        const retUF: api.UF.UfDto[] = [];

        if(acrescentarVazio == true) {
            retUF.push({
                codigo: 0,
                sigla: '',
                descricao: 'Não Informado',
                aliquota_icms: 0,
                codigo_uf: 0,
                url_consulta_nfce: '',
                url_consulta_nfce_hom: '',
                url_webservice_nfce: '',
                url_webservice_nfce_hom: '',
                url_webservice_nfe: '',
                url_webservice_nfe_hom: '',
            });
        }

        for (let i = 1; i <=28; i++) {
            if ((i != 9) || (i == 9 && acrescentarEx == true)) {
                switch (i) {
                    case 1:
                        retUF.push({
                            codigo: i,
                            sigla: 'AC',
                            descricao: 'Acre',
                            aliquota_icms: 17,
                            codigo_uf: 12,
                            url_consulta_nfce: 'www.sefaznet.ac.gov.br/nfce/consulta',
                            url_consulta_nfce_hom: 'www.sefaznet.ac.gov.br/nfce/consulta',
                            url_webservice_nfce: '',
                            url_webservice_nfce_hom: '',
                            url_webservice_nfe: '',
                            url_webservice_nfe_hom: '',
                        });
                        break;
                    case 2:
                        retUF.push({
                            codigo: i,
                            sigla: 'AL',
                            descricao: 'Alagoas',
                            aliquota_icms: 17,
                            codigo_uf: 27,
                            url_consulta_nfce: 'www.sefaz.al.gov.br/nfce/consulta',
                            url_consulta_nfce_hom: 'www.sefaz.al.gov.br/nfce/consulta',
                            url_webservice_nfce: '',
                            url_webservice_nfce_hom: '',
                            url_webservice_nfe: '',
                            url_webservice_nfe_hom: '',
                        });
                        break;
                    case 3:
                        retUF.push({
                            codigo: i,
                            sigla: 'AP',
                            descricao: 'Amapá',
                            aliquota_icms: 17,
                            codigo_uf: 16,
                            url_consulta_nfce: 'www.sefaz.ap.gov.br/nfce/consulta',
                            url_consulta_nfce_hom: 'www.sefaz.ap.gov.br/nfce/consulta',
                            url_webservice_nfce: '',
                            url_webservice_nfce_hom: '',
                            url_webservice_nfe: '',
                            url_webservice_nfe_hom: '',
                        });
                        break;
                    case 4:
                            retUF.push({
                                codigo: i,
                                sigla: 'AM',
                                descricao: 'Amazonas',
                                aliquota_icms: 17,
                                codigo_uf: 13,
                                url_consulta_nfce: 'www.sefaz.am.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'www.sefaz.am.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 5:
                            retUF.push({
                                codigo: i,
                                sigla: 'BA',
                                descricao: 'Bahia',
                                aliquota_icms: 17,
                                codigo_uf: 29,
                                url_consulta_nfce: 'www.sefaz.ba.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'http://hinternet.sefaz.ba.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 6:
                            retUF.push({
                                codigo: i,
                                sigla: 'CE',
                                descricao: 'Ceará',
                                aliquota_icms: 17,
                                codigo_uf: 23,
                                url_consulta_nfce: 'www.sefaz.ce.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'www.sefaz.ce.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 7:
                            retUF.push({
                                codigo: i,
                                sigla: 'DF',
                                descricao: 'Distrito Federal',
                                aliquota_icms: 17,
                                codigo_uf: 53,
                                url_consulta_nfce: 'www.fazenda.df.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'www.fazenda.df.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 8:
                            retUF.push({
                                codigo: i,
                                sigla: 'ES',
                                descricao: 'Espirito Santo',
                                aliquota_icms: 17,
                                codigo_uf: 32,
                                url_consulta_nfce: 'www.sefaz.es.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'www.sefaz.es.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 9:
                            retUF.push({
                                codigo: i,
                                sigla: 'EX',
                                descricao: 'Exterior',
                                aliquota_icms: 0,
                                codigo_uf: 0,
                                url_consulta_nfce: '',
                                url_consulta_nfce_hom: '',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 10:
                            retUF.push({
                                codigo: i,
                                sigla: 'GO',
                                descricao: 'Goiás',
                                aliquota_icms: 17,
                                codigo_uf: 52,
                                url_consulta_nfce: 'www.sefaz.go.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'www.sefaz.go.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 11:
                            retUF.push({
                                codigo: i,
                                sigla: 'MA',
                                descricao: 'Maranhão',
                                aliquota_icms: 17,
                                codigo_uf: 21,
                                url_consulta_nfce: 'www.sefaz.ma.gov.br/nfce/consulta"',
                                url_consulta_nfce_hom: 'www.sefaz.ma.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 12:
                            retUF.push({
                                codigo: i,
                                sigla: 'MT',
                                descricao: 'Mato Grosso',
                                aliquota_icms: 17,
                                codigo_uf: 51,
                                url_consulta_nfce: 'http://www.sefaz.mt.gov.br/nfce/consultanfce',
                                url_consulta_nfce_hom: 'http://homologacao.sefaz.mt.gov.br/nfce/consultanfce',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 13:
                            retUF.push({
                                codigo: i,
                                sigla: 'MS',
                                descricao: 'Mato Grosso do Sul',
                                aliquota_icms: 18,
                                codigo_uf: 31,
                                url_consulta_nfce: 'www.dfe.ms.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'www.dfe.ms.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 14:
                            retUF.push({
                                codigo: i,
                                sigla: 'MG',
                                descricao: 'Minas Gerais',
                                aliquota_icms: 18,
                                codigo_uf: 31,
                                url_consulta_nfce: 'www.dfe.ms.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'www.dfe.ms.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 15:
                            retUF.push({
                                codigo: i,
                                sigla: 'PA',
                                descricao: 'Pará',
                                aliquota_icms: 17,
                                codigo_uf: 15,
                                url_consulta_nfce: 'www.sefa.pa.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'www.sefa.pa.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 16:
                            retUF.push({
                                codigo: i,
                                sigla: 'PB',
                                descricao: 'Paraíba',
                                aliquota_icms: 17,
                                codigo_uf: 41,
                                url_consulta_nfce: 'www.receita.pb.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'www.receita.pb.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 17:
                            retUF.push({
                                codigo: i,
                                sigla: 'PR',
                                descricao: 'Paraná',
                                aliquota_icms: 17,
                                codigo_uf: 41,
                                url_consulta_nfce: 'http://www.fazenda.pr.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'http://www.fazenda.pr.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 18:
                            retUF.push({
                                codigo: i,
                                sigla: 'PE',
                                descricao: 'Pernambuco',
                                aliquota_icms: 17,
                                codigo_uf: 26,
                                url_consulta_nfce: 'nfce.sefaz.pe.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'nfce.sefaz.pe.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 19:
                            retUF.push({
                                codigo: i,
                                sigla: 'PI',
                                descricao: 'Piauí',
                                aliquota_icms: 17,
                                codigo_uf: 22,
                                url_consulta_nfce: 'www.sefaz.pi.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'www.sefaz.pi.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 20:
                            retUF.push({
                                codigo: i,
                                sigla: 'RJ',
                                descricao: 'Rio de Janeiro',
                                aliquota_icms: 19,
                                codigo_uf: 33,
                                url_consulta_nfce: 'www.fazenda.rj.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'www.fazenda.rj.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 21:
                            retUF.push({
                                codigo: i,
                                sigla: 'RN',
                                descricao: 'Rio Grande do Norte',
                                aliquota_icms: 17,
                                codigo_uf: 24,
                                url_consulta_nfce: 'www.set.rn.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'www.set.rn.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 22:
                            retUF.push({
                                codigo: i,
                                sigla: 'RS',
                                descricao: 'Rio Grande do Sul',
                                aliquota_icms: 18,
                                codigo_uf: 43,
                                url_consulta_nfce: 'www.sefaz.rs.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'www.sefaz.rs.gov.br/nfce/consulta',
                                url_webservice_nfce: 'nfce.svrs.rs.gov.br',
                                url_webservice_nfce_hom: 'nfce-homologacao.svrs.rs.gov.br',
                                url_webservice_nfe: 'nfe.svrs.rs.gov.br',
                                url_webservice_nfe_hom: 'nfe-homologacao.svrs.rs.gov.br',
                            });
                            break;
                    case 23:
                            retUF.push({
                                codigo: i,
                                sigla: 'RO',
                                descricao: 'Rondônia',
                                aliquota_icms: 17,
                                codigo_uf: 11,
                                url_consulta_nfce: 'www.sefin.ro.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'www.sefin.ro.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 24:
                            retUF.push({
                                codigo: i,
                                sigla: 'RR',
                                descricao: 'Roraima',
                                aliquota_icms: 17,
                                codigo_uf: 14,
                                url_consulta_nfce: 'www.sefaz.rr.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'www.sefaz.rr.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 25:
                            retUF.push({
                                codigo: i,
                                sigla: 'SC',
                                descricao: 'Santa Catarina',
                                aliquota_icms: 17,
                                codigo_uf: 42,
                                url_consulta_nfce: 'https://sat.sef.sc.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'https://hom.sat.sef.sc.gov.br/nfce/consulta',
                                url_webservice_nfce: 'nfce.svrs.rs.gov.br',
                                url_webservice_nfce_hom: 'nfce-homologacao.svrs.rs.gov.br',
                                url_webservice_nfe: 'nfe.svrs.rs.gov.br',
                                url_webservice_nfe_hom: 'nfe-homologacao.svrs.rs.gov.br',
                            });
                            break;
                    case 26:
                            retUF.push({
                                codigo: i,
                                sigla: 'SP',
                                descricao: 'São Paulo',
                                aliquota_icms: 18,
                                codigo_uf: 35,
                                url_consulta_nfce: 'https://www.nfce.fazenda.sp.gov.br/consulta',
                                url_consulta_nfce_hom: 'https://www.homologacao.nfce.fazenda.sp.gov.br/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 27:
                            retUF.push({
                                codigo: i,
                                sigla: 'SE',
                                descricao: 'Sergipe',
                                aliquota_icms: 17,
                                codigo_uf: 28,
                                url_consulta_nfce: 'http://www.nfce.se.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'http://www.hom.nfe.se.gov.br/nfce/consulta',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
                    case 28:
                            retUF.push({
                                codigo: i,
                                sigla: 'TO',
                                descricao: 'Tocantins',
                                aliquota_icms: 17,
                                codigo_uf: 17,
                                url_consulta_nfce: 'www.sefaz.to.gov.br/nfce/consulta',
                                url_consulta_nfce_hom: 'http://homologacao.sefaz.to.gov.br/nfce/consulta.jsf',
                                url_webservice_nfce: '',
                                url_webservice_nfce_hom: '',
                                url_webservice_nfe: '',
                                url_webservice_nfe_hom: '',
                            });
                            break;
            
                    default:
                        break;
                }
            }
        }

        return retUF;
    }
}
export default new UF();