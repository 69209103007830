import axios from 'axios';
import Api from '../../base/api.typings';

class AgrupadoresService {

    buscarTiposAgrupadores(query: Api.Agrupadores.BuscarTiposAgrupadores.Query): Promise<Api.Agrupadores.BuscarTiposAgrupadores.TipoAgrupadorDto[]> {
        return axios.get('/api/agrupadores/buscartiposagrupadores', { params: query }).then(r => r.data);
    }

    buscarAgrupadores(query: Api.Agrupadores.BuscarAgrupadores.Query): Promise<Api.Agrupadores.BuscarAgrupadores.AgrupadorDto[]> {
        return axios.get('/api/agrupadores/buscaragrupadores', { params: query }).then(r => r.data);
    }

    pesquisarPorAgrupadores(query: Api.Agrupadores.PesquisarPorAgrupadores.Query): Promise<Api.Agrupadores.PesquisarPorAgrupadores.AgrupadorDto[]> {
        return axios.get('/api/agrupadores/pesquisarporagrupadores', { params: query }).then(r => r.data);
    }
}

export default new AgrupadoresService();