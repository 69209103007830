import ApiCore , { ApiCoreBase } from '@/base/api-core.typing';
import axios from 'axios';
import Api from '../../base/api.typings';

class TabelaPrecoService {

    pesquisarTabelaPreco(query?: Api.TabelaPreco.PesquisarTabelaPreco.Query): Promise<Api.TabelaPreco.PesquisarTabelaPreco.TabelaPrecoDto[]> {
        return axios.get('/api/tabelapreco/pesquisartabelapreco', { params: query }).then(r => r.data);
    }
}

export class TabelaPrecoBuscaServiceCore extends ApiCoreBase {

    Tabela_Preco16(params?:  ApiCore.Tabela_Preco_Busca.Query): Promise<ApiCore.Tabela_Preco_Busca.Tabela_PrecoDto16[]> {
        
        return  this.instance.post('/api/Tabela_Preco_Busca/', { tbParametros:[params]  } ).then(r => r.data.tabela_preco_busca);
    }
}

export default new TabelaPrecoService();