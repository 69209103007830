import { RouteConfig } from 'vue-router';

export const PNListar = "/pn";
export const PNInserir = "/pn/inserir";
export const PNEditar = (id: number | string): string => `/pn/editar/${id}`;

export const PNListarNome = "pn-listar";
export const PNInserirNome = "pn-inserir";
export const PNEditarNome = "pn-editar";

const PNRotas: RouteConfig[] = [
    {
        path: PNListar,
        name: PNListarNome,
        component: () => import('@/features/pn/pn-list.vue'),
        meta: { authorize: false }
    },
    {
        path: PNInserir,
        name: PNInserirNome,
        component: () => import('@/features/pn/pn-insert-edit.vue'),
        meta: { authorize: false }
    },
    {
        path: PNEditar(":id"),
        name: PNEditarNome,
        component: () => import('@/features/pn/pn-insert-edit.vue'),
        props: (route) => route.params,
        meta: { authorize: false }
    }
];

export default PNRotas;