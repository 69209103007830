import { RawLocation } from "vue-router/types/router"

export interface IErrorParameters {
    type?: 'success' | 'info' | 'warning' | 'error'
    message: string | string[]
    go: string | RawLocation
    icon?: string,
}

export class ErrorParameters implements IErrorParameters {
    type: 'success' | 'info' | 'warning' | 'error'
    message: string | string[]
    go: RawLocation
    icon?: string

    constructor(data?: IErrorParameters) {
        this.type = data?.type ?? 'error';
        this.icon = data?.icon;
        this.message = data?.message ?? ["Ops! Parece que houve um erro.", "Por favor tente novamente."];
        
        if (Array.isArray(this.message)) {
            for (let i = 0; i < this.message.length; i++) {
                this.message[i] = this.message[i].trim();   
            }
        }

        this.go = data?.go ?? { path: "/" };
    }
}