import axios from 'axios';
import router from '@/router';
import Api from '@/base/api.typings';
import Client from '@/base/client.typings';
import { ApplicationPaths } from '../../base/app-constants';

class UsersService {
    getUserAccesses(query?: Api.Users.GetUserAccesses.Query) : Promise<Api.Users.GetUserAccesses.UserAccessInfoDto> {
        return axios.get('/api/users/accesses', { params: query })
            .then(r => r.data)
            .catch(e => {

                const response = e.response;

                if (response && response.status !== 400) {
                    throw e;
                }
                else if (!response.data || !response.data.errors) {
                    throw e;
                } else {

                    const errors: Client.Requests.Errors = response.data.errors;

                    if (!errors.servidor || errors.servidor.length != 1) {
                        throw e;
                    } else {
                        const error: Client.Requests.ErrorInfo = errors.servidor[0];

                        if (error.text !== 'User not found') {
                            throw e;
                        } else {
                            router.replace(ApplicationPaths.Users.LinkUser);
                        }
                    }
                }
            });
    }

    linkUser(command: Api.Users.LinkUser.Command): Promise<boolean> {
        return axios.post('/api/users/linkuser', command).then(r => r.data);
    }
}

export default new UsersService();