import { RouteConfig } from "vue-router";

const phonebookRoutes: RouteConfig[] = [
    {
        path: "/phonebook",
        name: 'phonebook',
        component: () => import('@/features/phonebook/phonebook-list.vue'),

    },
    {
        path: "/phonebook/contact/insert",
        name: 'phonebook-insert',
        component: () => import('@/features/phonebook/phonebook-contact-insert-edit.vue'),

    },
    {
        path: "/phonebook/contact/:phonebookId/edit",
        name: 'phonebook-edit',
        component: () => import('@/features/phonebook/phonebook-contact-insert-edit.vue'),
        props: (route) => route.params,

    },
    {
        path: "/phonebook/contact/:phonebookId/phones",
        name: 'phonebook-contact',
        component: () => import('@/features/phonebook/phonebook-contact-phone-list.vue'),
        props: (route) => route.params,

    },
    {
        path: "/phonebook/contact/:phonebookId/phones/insert",
        name: 'phonebook-contact-insert',
        component: () => import('@/features/phonebook/phonebook-contact-phone-insert-edit.vue'),
        props: (route) => route.params,

    },
    {
        path: "/phonebook/contact/:phonebookId/phones/edit/:phonebookContactId",
        name: 'phonebook-contact-edit',
        component: () => import('@/features/phonebook/phonebook-contact-phone-insert-edit.vue'),
        props: (route) => route.params,

    }
]

export default phonebookRoutes;