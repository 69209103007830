import ApiCore , { ApiCoreBase } from '@/base/api-core.typing';
import axios from 'axios';
import Api from '../../base/api.typings'; 

class ModelosNotasService {
    pesquisar(query?: Api.ModelosNotas.PesquisarModelosNotas.Query): Promise<Api.ModelosNotas.PesquisarModelosNotas.ModelosNotasDto[]> {
        return axios.get('/api/modelosnotasfiscais/pesquisar', { params: query }).then(r => r.data);
    }
}

export class ModeloNotasFiscaisServiceCore extends ApiCoreBase {
    modeloNotas(params:  ApiCore.ModeloNotas.Query): Promise<ApiCore.ModeloNotas.ModeloNotaDto[]> {
        params.parTipoRetorno = 1;
        return this.instance.post('/api/Modelos_Notas/', { tbParametros:[params]  } ).then(r => r.data.modelos_notas );
    }

    modeloNotas2(params:  ApiCore.ModeloNotas.Query): Promise<ApiCore.ModeloNotas.ModeloNotasDto2[]> {
        params.parTipoRetorno = 2;
        return this.instance.post('/api/Modelos_Notas/', { tbParametros:[params]  } ).then(r => r.data.modelos_notas );
    }

    modeloNotas5(params:  ApiCore.ModeloNotas.Query): Promise<ApiCore.ModeloNotas.ModeloNotasDto5[]> {
        params.parTipoRetorno = 5;
        return this.instance.post('/api/Modelos_Notas/', { tbParametros:[params]  } ).then(r => r.data.modelos_notas );
    }

    insert(params:  ApiCore.ModeloNotas.Estrutura): Promise<boolean> {
        params = this.objectToUppercase(params);
        return this.instance.post('/api/Modelos_Notas_Update/', { modelos_notas:[params]  } ).then(r => Boolean(r.data));
    }

    update(params:  ApiCore.ModeloNotas.Estrutura): Promise<boolean> {
        params = this.objectToUppercase(params);
        return this.instance.put('/api/Modelos_Notas_Update/', { modelos_notas:[params]  } ).then(r =>  Boolean(r.data) );
    }

    delete(params:  ApiCore.ModeloNotas.Estrutura): Promise<boolean> {
        return this.instance.delete('/api/Modelos_Notas_Update/', { data:{modelos_notas:[params]}  } ).then(r => Boolean(r.data));
    }
}

export class ModelosNotasServiceCore extends ApiCoreBase {

    modelos_notas(params?:  ApiCore.Modelos_Notas.Query): Promise<ApiCore.Modelos_Notas.Modelos_NotasDto1[]> {
        
        return this.instance.post('/api/Modelos_Notas/', { tbParametros:[params]  } ).then(r => r.data.modelos_notas);
    }
    
}

export default new ModelosNotasService();