declare global {
    interface StringConstructor {
        isNullOrWhiteSpace(value: string): boolean;
    }
}

String.isNullOrWhiteSpace = (value: string): boolean => {
    return value == null || value.trim() == '';
};

export { };