  import Convert from "@/utils/convert-to";
  
  declare global {
    interface NumberConstructor {
        isEmpty(value: number): boolean;
        isNotEmpty(value: number): boolean;
        tryParse(value: string, out: (result: number) => void): boolean;
        round(value:number, fractionDigits:number ):number;
    }
}

Number.isEmpty = function(value: number): boolean {
    const _number = Number(value);
    return (_number|0) == 0;
};

Number.isNotEmpty = function(value: number): boolean {
    return !this.isEmpty(value);
};

Number.round =  function(valor: number, fractionDigits): number {
    return parseFloat(Convert.Decimal(valor).toFixed(fractionDigits));
  }

Number.tryParse = function(value: string, out: (result: number) => void): boolean {
    let returnValue = 0;
    let _return = false;

    try {
        if (String.isNullOrWhiteSpace(value)) {
            return _return = false;
        }
        
        const num = Number(value);

        if (!this.isFinite(num)) {
            return _return = false;
        }

        returnValue = num;
        return _return = true;
    } catch {
        return _return = false;
    } finally {
        out(_return ? returnValue : 0)
    }
};

export { };