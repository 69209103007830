
    /* eslint-disable @typescript-eslint/no-explicit-any */

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import { Prop, PropSync, Watch } from 'vue-property-decorator'
    
    // Components
    import DateTimePicker from '@/components/date-time-picker.vue';
    import Search from '@/components/search.vue';

    // Frameworks
    import moment from 'moment';
    import * as validations from '@/base/validations';
    import ConvertTo from '@/utils/convert-to';
    import Enum from '@/utils/enum';

    // Typings
    import Api from '@/base/api.typings';
    import Client from '@/base/client.typings';
    import { FieldRules } from './action-controller.interfaces';

    // Extensions
    import '@/utils/extensions/string';
    import '@/utils/extensions/date';

    interface IFilterTypes {
        Combobox: Api.Domain.Enums.TipoCampo,
        Date: Api.Domain.Enums.TipoCampo,
        Datetime: Api.Domain.Enums.TipoCampo,
        Number: Api.Domain.Enums.TipoCampo,
        Text: Api.Domain.Enums.TipoCampo,
    }

    @Component({
        components: {
            DateTimePicker,
            Search,
        }
    })
    export default class ReportFilter extends Vue {

        // #region Data ---------------------------------------------------------------------------------------

        // Component Properties -----------------------------

        @Prop({ required: true }) filter: Api.Reports.GetReport.FilterDto;
        @Prop({ required: true }) value: Api.Reports.GenerateReport.Filter;
        @PropSync('name', { type: String, required: false }) syncedName: string;        
        @PropSync('rules', { required: false }) syncedRules: FieldRules;     


        // Consts Properties --------------------------------

        FilterTypes: IFilterTypes = {
            Combobox: Api.Domain.Enums.TipoCampo.ComboBox,
            Date: Api.Domain.Enums.TipoCampo.Data,
            Datetime: Api.Domain.Enums.TipoCampo.DataHora,
            Number: Api.Domain.Enums.TipoCampo.Numerico,
            Text: Api.Domain.Enums.TipoCampo.Texto,
        }


        // Computed Properties ------------------------------

        get filterType(): Api.Domain.Enums.TipoCampo {
            return this.filter.filterType;
        }

        get counter(): string | undefined {
            let ruleMaxValue = this.filter.validationRules.find(s => s.ruleType == Api.Domain.Enums.RuleType.MaxLength);
            if (ruleMaxValue != null) {
                return ruleMaxValue.max;
            }

            return undefined;
        }

        get icon(): string {
            switch (this.filterType) {
                case Api.Domain.Enums.TipoCampo.Data:
                case Api.Domain.Enums.TipoCampo.DataHora:
                    return this.isBetweenDates ? 'mdi-calendar-range' : 'mdi-calendar';
                default:
                    return '';
            }
        }

        get isBetweenDates(): boolean {
            return Enum.In(this.filterType, Api.Domain.Enums.TipoCampo.Data, Api.Domain.Enums.TipoCampo.DataHora) && this.isMultipleChoose;
        }

        get isDenseData(): boolean {
            return (this.filter && this.filter.data && this.filter.data.length > 10) || false;
        }

        get isMultipleChoose(): boolean {
            return this.filter.multipleChoice || false;
        }

        get isNullFilterDados(): boolean {
            return (this.filter.data && this.filter.data.length > 1) || false;
        }

        // Local variables ----------------------------------

        isMenuOpen = false;
        isSearching = false;

        dateFormatted = '';                
        localRules: FieldRules = [];
        search = '';
        selectedData: Client.ConjuntoValorTexto[] | Client.ConjuntoValorTexto | Date[] | Date | string[] | string | number[] | number = [];
        timerToSearch: number = null;

        maxDate = '';
        minDate = '';


        // #endregion




        // #region Watchers -----------------------------------------------------------------------------------

        @Watch('selectedData')
        aoSelecionarValor(data: any) {

            let value = data;

            if (value == null) {
                this.emitValues(value);
                return;
            }

            if (Array.isArray(value)) {

                value = value.filter(f => f != null);

                if (value.length <= 0) {
                    this.emitValues(value);
                    return;
                }
            }

            switch (this.filterType) {

                case Api.Domain.Enums.TipoCampo.Texto:
                case Api.Domain.Enums.TipoCampo.Numerico:
                    this.emitValues([value]);
                    break;

                case Api.Domain.Enums.TipoCampo.Data:
                    {
                        let dates: Date[];

                        if (this.isBetweenDates) {
                            dates = value;
                            dates = dates.sort((a, b) => (new Date(a)).valueOf() - (new Date(b)).valueOf());
                        } else {
                            dates = [value];
                        }

                        this.dateFormatted = dates.map(m => moment(m).format('L')).join(' - ');

                        if (this.filter.returnType == Api.Domain.Enums.TipoRetorno.Int || false) {
                            this.emitValues(dates.map(m => ConvertTo.DataClarion(m)));
                        } else {
                            this.emitValues(dates);
                        }

                        if (!this.isBetweenDates) {
                            this.isMenuOpen = false;
                        }
                    }
                    break;

                case Api.Domain.Enums.TipoCampo.DataHora:
                    {
                        let date = moment(value);

                        this.dateFormatted = `${date.format('L')} ${date.format('LTS')}`;

                        this.emitValues([date.toJSON()]);

                        this.isMenuOpen = false;
                    }
                    break;

                case Api.Domain.Enums.TipoCampo.ComboBox:
                    {
                        if (this.isMultipleChoose && Array.isArray(value)) {
                            if (value != null) {
                                this.emitValues((value as Client.ConjuntoValorTexto[]).map(m => m.valor));
                            } else {
                                this.emitValues(null);
                            }
                        } else {
                            this.emitValues([(value as Client.ConjuntoValorTexto).valor]);
                        }
                    }
                    break;

                default:
                    throw new Error('Tipo de campo de filtro não definido.');
            }
        }

        // #endregion




        // #region Methods ------------------------------------------------------------------------------------

        // Life Cycles --------------------------------------

        async created(): Promise<void> {
            await this.initiateFilterValue();
        }

        mounted(): void {
            this.selectedData = this.getInitSelectedValue();
            this.setFilterRules();
        }

        // Components Events --------------------------------
        // @Change
        // ...
        // @Click
        // ...

        // Local methods ------------------------------------

        clearData(): void {
            this.selectedData = this.getInitSelectedValue();
        }

        getFilterName(): string {

            if (!this.selectedData || (Array.isArray(this.selectedData) && this.selectedData.length <= 0))
                return null;

            switch (this.filterType) {
                case Api.Domain.Enums.TipoCampo.Data:
                case Api.Domain.Enums.TipoCampo.DataHora:
                    return `${this.filter.label}: ${this.dateFormatted}`;

                case Api.Domain.Enums.TipoCampo.ComboBox: {

                    //if (Array.isArray(this.selectedData)) {
                    //    let x: any;
                    //    if (this.selectedData.length <= 1) {
                    //        let y = this.selectedData[0];
                    //        x = this.filter.dados.find(f => f.valor == y).texto;
                    //    } else {
                    //        x = `${this.selectedData.length} itens`;
                    //    }

                    //    return `${this.filter.nome}: ${x}`;
                    //} else {
                    //    return `${this.filter.nome}: ${this.filter.dados.find(f => f.valor == this.selectedData).texto}`;
                    //}
                    return '';

                }
                default:
                    return `${this.filter.label}: ${this.selectedData}`;
            }
        }

        getInitSelectedValue(): any {
            let defaultValue: any = null;

            if (this.filter.filterType == Api.Domain.Enums.TipoCampo.Data) {
                if (this.filter.validationRules.some(s => s.ruleType == Api.Domain.Enums.RuleType.MaxValue)) {
                    defaultValue = new Date()
                        .addDays(ConvertTo.Int(this.filter.validationRules
                            .find(f => f.ruleType == Api.Domain.Enums.RuleType.MaxValue).value))
                        .toISOString();
                }
                else if (this.filter.validationRules.some(s => s.ruleType == Api.Domain.Enums.RuleType.MinValue)) {
                    defaultValue = new Date()
                        .addDays(ConvertTo.Int(this.filter.validationRules
                            .find(f => f.ruleType == Api.Domain.Enums.RuleType.MinValue).value))
                        .toISOString();
                }
            }

            return this.isMultipleChoose ? [] : defaultValue;
        }

        async initiateFilterValue(): Promise<void> {

            if (this.filter && this.filter.validationRules && this.filter.validationRules.some(s => s.ruleType == Api.Domain.Enums.RuleType.Required)) {

                switch (this.filterType) {
                    case Api.Domain.Enums.TipoCampo.Data: {
                        let currentDate = moment().toISOString().substring(0, 10);
                        this.selectedData = this.isBetweenDates ? [currentDate] : currentDate;
                    }
                        break;
                    case Api.Domain.Enums.TipoCampo.DataHora:
                        this.selectedData = moment().toISOString();
                        break;
                    case Api.Domain.Enums.TipoCampo.ComboBox:

                        if (this.filter.data && this.filter.data.length > 0) {
                            this.selectedData = this.isMultipleChoose ? this.filter.data : this.filter.data[0];
                        }

                        //this.selectedData = this.isMultipleChoose ? [{ valor: Number.MIN_VALUE, texto: 'Todos' } as Client.ConjuntoValorTexto] : this.filter.dados[0];
                        break;
                    default:
                        this.selectedData = this.getInitSelectedValue();
                        break;
                }

                this.emitValues(this.selectedData as any);
            } else {
                this.selectedData = this.getInitSelectedValue();
            }
        }

       


        // #endregion

  

        // #region Events - Emit

        setFilterRules(): void {

            if (!this.filter.validationRules || this.filter.validationRules.length <= 0)
                return

            for (const validationRule of this.filter.validationRules) {
                switch (validationRule.ruleType) {
                    case Api.Domain.Enums.RuleType.Required:
                        this.localRules.push(validations.required);
                        break;
                    case Api.Domain.Enums.RuleType.MaxLength:
                        if (ConvertTo.Int(validationRule.max) > 0)
                            this.localRules.push(validations.maxLength(ConvertTo.Int(validationRule.max)));
                        break;
                    case Api.Domain.Enums.RuleType.MaxValue:
                        {
                            switch (this.filter.filterType) {
                                case Api.Domain.Enums.TipoCampo.Data:
                                    {
                                        const maxDate = new Date().addDays(ConvertTo.Int(validationRule.value));
                                        this.maxDate = maxDate.toISOString().substring(0, 10)
                                        this.localRules.push(validations.maxValue(maxDate));
                                    }
                                    break;
                                default:
                                    this.localRules.push(validations.maxValue(ConvertTo.Int(validationRule.value)));
                                    break;
                            }
                        }
                        break;
                    case Api.Domain.Enums.RuleType.MinValue:
                        {
                            switch (this.filter.filterType) {
                                case Api.Domain.Enums.TipoCampo.Data:
                                    {
                                        const minDate = new Date().addDays(ConvertTo.Int(validationRule.value));
                                        this.minDate = minDate.toISOString().substring(0, 10)
                                        this.localRules.push(validations.minValue(minDate));
                                    }
                                    break;
                                default:
                                    this.localRules.push(validations.minValue(ConvertTo.Int(validationRule.value)));
                                    break;
                            }
                        }
                        break;
                    case Api.Domain.Enums.RuleType.Period:
                        if (this.filter.filterType == Api.Domain.Enums.TipoCampo.Data ||
                            this.filter.filterType == Api.Domain.Enums.TipoCampo.DataHora)
                            {
                                //
                                this.localRules.push(value => {
                                    
                                    if (String.isNullOrWhiteSpace(value)) {
                                        return "Campo Obrigatório";
                                    }

                                    const values = value.split("-");

                                    if (values.length != 2) {
                                        return 'Campo deve possuir 2 valores';
                                    }

                                    const period: Date[] = [];

                                    for (const value of values) {
                                        const v = value.trim().split('/');
                                        period.push(new Date(Number(v[2]), Number(v[1]), Number(v[0])))
                                    }

                                    if (period[0].getTime() === period[1].getTime()) {
                                        return 'Não pode ser datas iguais'
                                    }
                                    else {
                                        return true;
                                    }

                                });
                            }
                        break;
                }
            }

            if (this.localRules.length > 0)
                this.syncedRules = this.localRules;
        }

        emitValues(values: any[]) {

            let name = this.getFilterName();
            if (!String.isNullOrWhiteSpace(name))
                this.syncedName = this.getFilterName();

            this.$emit('input', {
                id: this.filter.id,
                values: values,
            } as Api.Reports.GenerateReport.Filter);
        }

        // #endregion

        
    }

