
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ErrorParameters } from './error.interfaces';

@Component
export default class Error extends Vue {
    @Prop({ 
        required: false, 
        type: String, 
    }) readonly message: string;

    @Prop({
        required: false,
        type: Object,
        default: () => new ErrorParameters(),
    }) readonly parameters: ErrorParameters;

    localParameters : ErrorParameters;

    created(): void {
        let error = this.$route.query['error'];

        if (Array.isArray(error)) {
            error = error[0];
        }

        if (error != undefined) {
            try {
                this.localParameters = JSON.parse(this.b64_to_utf8(error))
            } catch {
                this.localParameters = this.parameters;
            }
        }
        else
        {            
            this.localParameters = this.parameters;
            const route = this.$route;
            const error = this.utf8_to_b64(JSON.stringify(this.localParameters));
            this.$router.replace({
                name: route.name,
                path: route.path,
                hash: route.hash,
                query: {...route.query, error },
                params: route.params,
            })
            return;
        }
    }

    get messages(): string[] {
        if (!String.isNullOrWhiteSpace(this.message)) {
            return [this.message];
        }

        if (!Array.isArray(this.localParameters.message)) {
            return [this.localParameters.message];
        }
        
        return this.localParameters.message;
    }

    goBack(): void {
        this.$router.replace(this.localParameters.go);
    }

    utf8_to_b64(value: string) : string {
        return window.btoa(unescape(encodeURIComponent(value))).replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '');
    }

    b64_to_utf8(value: string) : string {
        return decodeURIComponent(escape(window.atob(value.replaceAll('-', '+').replaceAll('_', '/'))));
    }
}
