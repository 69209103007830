
    import Vue from 'vue'
    import Component from 'vue-class-component'
    import { Prop, VModel } from 'vue-property-decorator'
    import Client from '@/base/client.typings'

    @Component({
        name: 'menu-list'
    })
    export default class MenuList extends Vue {
        @VModel({ required: true }) readonly menus: Client.Home.Menu[];
        @Prop({ default: false }) readonly subGroup: boolean;
    }
