import VueRouter from 'vue-router';
import authService from '@/features/api-authorization/authorize-service';
import { AuthrorizationPaths, QueryParameterNames } from '@/features/api-authorization/api-authorization-constants';
import { Dictionary } from 'vue-router/types/router';
import Api from '@/base/api.typings';
import UserAccess = Api.Domain.Enums.UserAccess;
import { IErrorParameters } from '../home/error.interfaces';

export function authorizeGuard(router: VueRouter): void {

    // Whenever a route changes,
    // this function is called before rendering the target path
    router.beforeEach(async (to, from, next) => {
        if (to.matched.some(record => record.meta.allowAnonymous) === true) {
            // Route does not require authorization
            next();
        } else if (authService.isAuthenticated()) {
            // User is authenticated

            const access = to.matched.map(m => m.meta.userAccesses).filter(f => !!f) as UserAccess[];

            if (access.length <= 0)
            {
                // No access required
                next();
            }
            else if (await (authService.validateUserAccess(...access))) {
                // User has permission
                next();
            }
            else {
                // User does not have permission
                next({
                    name: 'error',
                    params: {
                        error: (({
                            type: 'error',
                            icon: '',
                            message: 'Usuário não tem permissão para acessar',
                            go: from
                        } as IErrorParameters) as unknown) as string 
                    }
                });
            }
        } else {
            // Route requires authorization and
            // User is not authenticated then
            // redirect to login page
            const query: Dictionary<string> = {};

            query[QueryParameterNames.ReturnUrl] = to.fullPath;

            next({
                path: AuthrorizationPaths.Login,
                query
            });
        }

    });
}