import { RouteConfig } from 'vue-router';

export const ChargesListPath = "/charges";
export const ChargesInsertPath = "/charges/insert";
export const ChargesEditPath = (id: number | string): string => `/charges/edit/${id}`;

const ChargesRoutes: RouteConfig[] = [
    {
        path: ChargesListPath,
        name: "charges-list",
        component: () => import('@/features/charges/charges-list.vue'),

    },
    {
        path: ChargesInsertPath,
        name: "charges-insert",
        component: () => import('@/features/charges/charges-insert-edit.vue'),

    },
    {
        path: ChargesEditPath(':chargeId'),
        name: "charges-edit",
        component: () => import('@/features/charges/charges-insert-edit.vue'),
        props: (route) => route.params,
    },
];

export default ChargesRoutes;