/* eslint-disable @typescript-eslint/no-explicit-any */

function numberWithDots(value: string): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function Currency(value: number, fractionDigits = 0): string {
    return `R$ ${Decimal(value, fractionDigits)}`;
}

export function Decimal(value: number, fractionDigits = 0): string {
    const [integer, decimals] = value.toFixed(fractionDigits).split('.');

    if (decimals == null)
        return numberWithDots(integer);

    return [numberWithDots(integer), decimals].join(',');
}

export function FormatDate(value: Date) {
    if (value == null) {
        return '';
    }

    const date = value.toISOString().substring(0, 10);

    if (!date) {
        return '';
    }

    const [year, month, day] = date.split('-')
    return `${day}/${month}/${year}`
}

export function FormatDatePicker(value: Date) {
    if (value == null) {
        return '';
    }

    const date = value.toISOString().substring(0, 10);

    if (!date) {
        return '';
    }
    
    const [year, month, day] = date.split('-')
    return `${year}-${month}-${day}`
}

export function justNumbers(value: string){
    const numbers = value.replace(/[^0-9]/g,'');
    return numbers;
}

export function formatCpf(cpf:string ):string{
    if(cpf==null)
    {
        return cpf;
    } 
    return cpf.replace(/[^\d]/g, "").replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}
 

export function  formatCnpjCpf(value:string ):string
{
  const cnpjCpf = value.replace(/\D/g, '');
  
  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  } 
  
  return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
}

export default {
    Currency,
    Decimal,
    FormatDate,
    FormatDatePicker,
    justNumbers,
    formatCpf,
    formatCnpjCpf
};