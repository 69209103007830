import { RouteConfig } from 'vue-router';
import Relatorio from '@/features/reports/reports.vue';

const ReportPathPrefix = '/relatorio';

export const ReportRouteName = 'Report';
export const ReportRouteParamReportGroupId = 'reportGroupId';
export const ReportRouteParamReportGroupObject = 'reportGroupObject';
export const ReportQueryParamReportGroupId = `:${ReportRouteParamReportGroupId}`;
export const ReportPath = `${ReportPathPrefix}/${ReportQueryParamReportGroupId}`;

const ReportRoutes: RouteConfig[] = [
    {
        name: ReportRouteName,
        path: ReportPath,
        component: Relatorio,
        props: (router) => router.params,
    },
    {
        name: 'ReportExpensesByChartOfAccounts',
        path: '/reports/expenses-by-chart-of-accounts',
        component: () => import('@/features/reports/expenses-by-chart-of-accounts/report.vue'),
        props: (router) => router.params,
    }
];

export default ReportRoutes;