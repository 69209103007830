import Api from '@/base/api.typings';
import { RouteConfig } from 'vue-router';
import UserAccess = Api.Domain.Enums.UserAccess;

const invoiceTemplatePath = "/modelo-de-notas-fiscais";
export const InvoiceTemplateListPath = invoiceTemplatePath.concat("/listar");
export const InvoiceTemplateInsertPath = invoiceTemplatePath.concat("/cadastrar");
export const InvoiceTemplateEditPath = (id: number | string): string => invoiceTemplatePath.concat(`/editar/${id}`);

export const InvoiceTemplateListName = 'invoice-template-list';
export const InvoiceTemplateInsertName = 'invoice-template-insert';
export const InvoiceTemplateEditName = 'invoice-template-edit';

const InvoiceTemplateRoutes: RouteConfig[] = [
    {
        meta: { userAccesses: [UserAccess.Cadastro_ModelosDeNota] },
        path: InvoiceTemplateListPath,
        name: InvoiceTemplateListName,
        props: (route) => route.params,
        component: () => import('@/features/modelos-notas/modelos-notas-listar.vue')
    },
    {
        meta: { userAccesses: [UserAccess.Cadastro_ModelosDeNota_Incluir] },
        path: InvoiceTemplateInsertPath,
        name: InvoiceTemplateInsertName,
        component: () => import('@/features/modelos-notas/invoice-templates-insert-edit.vue')
    },
    {
        meta: { userAccesses: [UserAccess.Cadastro_ModelosDeNota_Alterar] },
        path: InvoiceTemplateEditPath(':invoiceTemplateId'),
        name: InvoiceTemplateEditName,
        props: (route) => route.params,
        component: () => import('@/features/modelos-notas/invoice-templates-insert-edit.vue')
    },    
];

export default InvoiceTemplateRoutes;