import { RouteConfig } from 'vue-router';

export const PlanoContasListar = "/plano-contas";
export const PlanoContasInserir = "/plano-contas/inserir";
export const PlanoContasEditar = (id: number | string): string => `/plano-contas/editar/${id}`;

export const PlanoContasListarNome = "plano-contas-listar";
export const PlanoContasInserirNome = "plano-contas-inserir";
export const PlanoContasEditarNome = "plano-contas-editar";

const PlanoContasRotas: RouteConfig[] = [
    {
        path: PlanoContasListar,
        name: PlanoContasListarNome,
        component: () => import('@/features/plano-contas/plano-contas-list.vue'),
        meta: { authorize: false }
    },
    {
        path: PlanoContasInserir,
        name: PlanoContasInserirNome,
        component: () => import('@/features/plano-contas/plano-contas-insert-edit.vue'),
        meta: { authorize: false }
    },
    {
        path: PlanoContasEditar(":id"),
        name: PlanoContasEditarNome,
        component: () => import('@/features/plano-contas/plano-contas-insert-edit.vue'),
        props: (route) => route.params,
        meta: { authorize: false }
    }
];

export default PlanoContasRotas;