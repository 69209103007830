import { RouteConfig } from "vue-router"

export const PedidoLancar = "/pedido/lancar"
export const PedidoEditar = (pkPv:number | string):string =>  `/pedido/editar/${pkPv}`

export const PedidoLancarNome = "pedido-lancar"
export const PedidoEditarNome = "pedido-editar"

const VendasRotas: RouteConfig[]=[ 
    {
        path: PedidoLancar,
        name: PedidoLancarNome,
        component: () => import('@/features/vendas/lancar-venda.vue'),
        props: (route) => route.params,

    },
    {
        path: PedidoEditar(":pkPv"),
        name: PedidoEditarNome,
        component: () => import('@/features/vendas/lancar-venda.vue'),
        props: (route) => route.params,
    },
]

export default VendasRotas;