declare global {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface Array<T> {
        sum(): number;
        isEmpty(): boolean;
        isNotEmpty(): boolean;
    }
}

Array.prototype.sum = function (): number {
    return this.filter(f => f != null && f != undefined)
        .reduce((x, y) => Number(x) + Number(y), 0);
};

Array.prototype.isEmpty = function(): boolean {
    return !this || this.length <= 0;
}

Array.prototype.isNotEmpty = function(): boolean {
    return !this.isEmpty();
}

export {};