import axios from 'axios';
import Api from '@/base/api.typings';

const bankAccountsRepositoryPrefix = '/api/bank-accounts';

export class BankAccountsRepository {

    GetAll(query?: Api.BankAccount.GetBankAccounts.Query): Promise<Api.BankAccount.GetBankAccounts.BankAccountDto[]> {
        return axios.get(bankAccountsRepositoryPrefix, { params: query }).then(r => r.data);
    }

}