
import Vue from "vue";
import Component from "vue-class-component";
import authService from "@/features/api-authorization/authorize-service";

@Component
export default class Home extends Vue {

    userIdBase64 = "";

    readonly buttons = [
        {
            link: "newssoft-sisgem:",
            text: "Sisgem",
            color: "#044259",
            icon: "mdi-application-outline"
        }, {
            link: "newssoft-sisgem://sisgem/vendas",
            text: "Vendas",
            color: "#3a865c",
            icon: "mdi-cart-outline"
        }, {
            link: "newssoft-sisgem://sisgem/compras",
            text: "Compras",
            color: "#d34927",
            icon: "mdi-cube-outline"
        }, {
            link: "newssoft-sisgem://sisgem/produtos",
            text: "Produtos",
            color: "#2671eb",
            icon: "mdi-gift-outline"
        }, {
            link: "newssoft-sisgem://sisgem/caixa",
            text: "Caixa",
            color: "#008a9f",
            icon: "mdi-currency-usd"
        }, {
            link: "newssoft-sisgem://sisgem/bancos",
            text: "Bancos",
            color: "#ac193d",
            icon: "mdi-bank-outline"
        },
    ]

    mounted(): void {
        this.userIdBase64 = this.toBase64FromString(authService.getUserProfile().sub)
    }

    addUserIdQueryParam(link: string): string {
        const url = new URL(link);

        url.searchParams.append("UserId", this.userIdBase64)

        return url.toString();
    }

    toBase64FromString(str: string) {
        const arrayBuffer = this.parse(str);

        let base64 = ''
        const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

        const bytes = new Uint8Array(arrayBuffer)
        const byteLength = bytes.byteLength
        const byteRemainder = byteLength % 3
        const mainLength = byteLength - byteRemainder

        let a, b, c, d
        let chunk

        // Main loop deals with bytes in chunks of 3
        for (let i = 0; i < mainLength; i = i + 3) {
            // Combine the three bytes into a single integer
            chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]

            // Use bitmasks to extract 6-bit segments from the triplet
            a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
            b = (chunk & 258048) >> 12 // 258048   = (2^6 - 1) << 12
            c = (chunk & 4032) >> 6 // 4032     = (2^6 - 1) << 6
            d = chunk & 63               // 63       = 2^6 - 1

            // Convert the raw binary segments to the appropriate ASCII encoding
            base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
        }

        // Deal with the remaining bytes and padding
        if (byteRemainder == 1) {
            chunk = bytes[mainLength]

            a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2

            // Set the 4 least significant bits to zero
            b = (chunk & 3) << 4 // 3   = 2^2 - 1

            base64 += encodings[a] + encodings[b] + '=='
        } else if (byteRemainder == 2) {
            chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]

            a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
            b = (chunk & 1008) >> 4 // 1008  = (2^6 - 1) << 4

            // Set the 2 least significant bits to zero
            c = (chunk & 15) << 2 // 15    = 2^4 - 1

            base64 += encodings[a] + encodings[b] + encodings[c] + '='
        }

        return base64.replaceAll('+', '_').replaceAll('/', '-').replaceAll("=", "");
    }

    parse(uuid: string): Uint8Array {
        let v;
        const arr = new Uint8Array(16);
        let aux;

        // Parse ########-....-....-....-............
        aux = uuid.slice(0, 8);
        v = parseInt(aux, 16)
        arr[0] = v >>> 24;
        arr[1] = (v >>> 16) & 0xff;
        arr[2] = (v >>> 8) & 0xff;
        arr[3] = v & 0xff;

        // Parse ........-####-....-....-............
        aux = uuid.slice(9, 13);
        v = parseInt(aux, 16)
        arr[4] = v >>> 8;
        arr[5] = v & 0xff;

        // Parse ........-....-####-....-............
        arr[6] = (v = parseInt(uuid.slice(14, 18), 16)) >>> 8;
        arr[7] = v & 0xff;

        // Parse ........-....-....-####-............
        arr[8] = (v = parseInt(uuid.slice(19, 23), 16)) >>> 8;
        arr[9] = v & 0xff;

        // Parse ........-....-....-....-############
        // (Use "/" to avoid 32-bit truncation when bit-shifting high-order bytes)
        arr[10] = ((v = parseInt(uuid.slice(24, 36), 16)) / 0x10000000000) & 0xff;
        arr[11] = (v / 0x100000000) & 0xff;
        arr[12] = (v >>> 24) & 0xff;
        arr[13] = (v >>> 16) & 0xff;
        arr[14] = (v >>> 8) & 0xff;
        arr[15] = v & 0xff;

        return arr;
    }
}
