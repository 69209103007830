/* eslint-disable @typescript-eslint/no-explicit-any */

class ConvertTo {
    Int(value: any, defaultValue = 0) {
        return parseInt(value) || defaultValue;
    }

    Decimal(value: any, defaultValue = 0.00) {
        return parseFloat(value) || defaultValue;
    }

    DataClarion(date: Date | string): number {      
        return (Math.round((new Date(date).valueOf() - new Date("1801-01-01").valueOf()) / (1000 * 3600 * 24) + 4)) || 0;
    }

    ClarionData(value: number): Date{
        if (value < 36163 && value > 100000) return new Date(new Date("1801-01-01").valueOf());
        return new Date("1801-01-01").addDays(value - 3);
    }
}

export default new ConvertTo(); 