import axios from 'axios';
import Api from '../../base/api.typings';
import ApiCore , { ApiCoreBase } from '@/base/api-core.typing';
  
class EmpresasService {

    pesquisarEmpresas(query?: Api.Empresas.PesquisarEmpresas.Query): Promise<Api.Empresas.PesquisarEmpresas.EmpresasDto[]> {
        return axios.get('/api/Company/search', { params: query }).then(r => r.data);
    }

}

export class EmpresasServiceCore extends ApiCoreBase {

    Empresas8(params?:  ApiCore.Empresas.Query): Promise<ApiCore.Empresas.EmpresasDto8[]> {
        
        return  this.instance.post('/api/Empresas/', { tbParametros:[params]  } ).then(r => r.data.empresas);
    }
 
}

export default new EmpresasService();