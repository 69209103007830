import { RouteConfig } from 'vue-router';

const SisgemRoutes: RouteConfig[] = [
    {
        name: 'Sisgem-Report-Cash-Flow',
        path: '/reports/cash/flow',
        component: () => import('@/features/reports/reports.vue'),
        props: { reportGroupId: 10000000004 } 
    },
    {
        name: 'Sisgem-Report-Cash',
        path: '/reports/cash',
        component: () => import('@/features/reports/report_091_010_010/cashier-report-filter.vue'),
        props: { reportGroupId: 10000000002 } 
    },
    {
        name: 'Sisgem-Report-Cash-Analytical',
        path: '/reports/cash/analytical',
        component: () => import('@/features/reports/report_091_010_010/cashier-report-filter.vue'),
        props: { reportGroupId: 10000000002, reportIndex: 0 } 
    },
    {
        name: 'Sisgem-Report-Cash-Synthetic',
        path: '/reports/cash/synthetic',
        component: () => import('@/features/reports/report_091_010_010/cashier-report-filter.vue'),
        props: { reportGroupId: 10000000002, reportIndex: 1 } 
    },
    {
        name: 'Sisgem-Report-Cash-Accounts-Receivable',
        path: '/reports/cash/accounts-receivable',
        component: () => import('@/features/reports/reports.vue'),
        props: { reportGroupId: 10000000003 } 
    },
    {
        name: 'Sisgem-Report-Payable-Accounts-Payable',
        path: '/reports/payable/accounts-payable',
        redirect: {
            name: 'ReportExpensesByChartOfAccounts'
        }
    },
];

export default SisgemRoutes;