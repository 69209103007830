
    /* eslint-disable @typescript-eslint/no-explicit-any */

    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { VForm } from "vue/types/vue";
    import { IActionController, FormRules, ProcessSubmitOptions, ProcessActionOptions, ServerRuleValidation, FieldRules, ResponseMessages } from './action-controller.interfaces';

    const defaultMessages = {
        networkFailureMessage: 'Não foi possível se comunicar com o servidor, verifique sua conexão com a internet.',
        successMessage: 'Enviado com sucesso!',
        badRequestMessage: 'Preenchimento inválido.',
        unauthorizedMessage: 'Usuário sem permissão de acesso.',
        notFoundMessage: 'O recurso que você está procurando não foi encontado.',
        internalErrorMessage: 'Ops! Erro interno de servidor.',
        loadingMessage: 'Enviando...',
        validationMessage: 'Corrija os campos em destaque.'
    }

    const saveMessages = {
        loadingMessage: 'Salvando...',
        successMessage: 'Salvo com sucesso!'
    }

    const removeMessages = {
        loadingMessage: 'Removendo...',
        successMessage: 'Removido com sucesso!'
    }

    const reportMessages = {
        loadingMessage: 'Processando relatório...',
        successMessage: 'Relatório pronto para visualização!'
    }

    @Component
    export default class ActionController extends Vue implements IActionController {
        /** Mensagens prontas para processos de salvar */
        saveMessages = saveMessages;

        /** Mensagens prontas para processos de remover */
        removeMessages = removeMessages;

        /** Mensagens prontas para processamento de relatórios */
        reportMessages = reportMessages;

        loadingVisible = false;

        snackbarVisible = false;

        loadingOverlayVisible = false;

        loadingMessage = '';

        snackbarMessage = '';

        snackbarColor = '';


        readonly timeout = 3000;
        readonly interval = this.timeout * 0.1;

        closeTimeout = this.timeout;
        funcTimeout = 0;
        percCloseTimeout = 100;

        /**
         * Remove as validações de servidor de um formulário
         * @param rules Rules do formulário
         */
        public resetServerValidation(rules: FormRules) {
            for (let fieldName in rules) {
                let server = this.getServerValidation(rules[fieldName]);

                if (server)
                    server.validation = '';
            }
        }

        /**
         * Preenche um formulário com as mensagens de validação retornadas pelo servidor
         * @param formRules Rules do formulário
         * @param formErrors Mensagens de validação retornadas pelo servidor
         */
        public fillServerValidation(formRules: FormRules, formErrors: any) {
            let summaryErrors = [];

            for (let fieldName in formErrors) {
                let fieldRuleName = fieldName[0].toLowerCase() + fieldName.substr(1);
                let fieldErrors = formErrors[fieldName];
                let validationMessage = this.getValidationText(fieldErrors);

                if (fieldRuleName in formRules) {
                    if (formRules[fieldRuleName] === null) {
                        formRules[fieldRuleName] = [];
                    }

                    let fieldRules = formRules[fieldRuleName];

                    let serverValidation = this.getOrCreateServerValidation(fieldRules);
                    if (serverValidation)
                        serverValidation.validation = validationMessage;
                    else
                        summaryErrors.push(validationMessage);
                }
                else
                    summaryErrors.push(validationMessage);
            }

            if (summaryErrors.length) {
                if (formRules['$summary'] === null) {
                    formRules['$summary'] = [];
                }

                let summary = formRules['$summary'];
                
                if (summary) {
                    let serverValidation = this.getOrCreateServerValidation(summary);
                    serverValidation.validation = summaryErrors.join('\n');
                }
                else {
                    console.error('Defina um $summary nas regras de validação do formulário para exibir erros de campos não visíveis na tela.');
                }
            }
        }

        /**
         * Processa o submit de um formulário.
         * Primeiro faz a validação do lado do cliente e somente se o formulário for válido ele é enviado ao servidor.
         * Um feedback de loading será exibido enquanto aguarda a resposta do servidor.
         * Caso o servidor retorne erros de validação elas serão exibidos no formulário automaticamente.
         * @param options
         */
        public async processSubmit(options: ProcessSubmitOptions) {
            if (!options)
                throw 'Options deve ser informada';

            if (!options.form)
                throw 'Options.form deve ser informado';

            if (!options.rules)
                throw 'Options.rules deve ser informado';

            this.hideSnackbar();
            this.hideLoading();

            if (options.showLoadingOverlay === undefined || options.showLoadingOverlay === null)
                options.showLoadingOverlay = true;

            let formRules = options.rules;
            let form = options.form;

            if (formRules)
                this.resetServerValidation(formRules);

            const valid = (form as VForm).validate();
            if (valid !== true) {
                this.showFormValidationSnackbar(options);
                this.focusInvalidInput();
                return;
            }

            try {
                this.showLoading(options);

                await options.action();

                this.showSuccessSnackbar(options);
            }
            catch (ex) {
                if (ex.response && ex.response.status == 400 && ex.response.data && (ex.response.data.errors || ex.response.data.mensagem)) {
                    this.showFormValidationSnackbar(options);

                    if (ex.response.data.mensagem)
                    {
                        if (!ex.response.data.errors) {
                            ex.response.data.errors = {}
                        }

                        ex.response.data.errors["$summary"] =  ex.response.data.mensagem;
                    }

                    if (formRules) {
                        this.fillServerValidation(formRules, ex.response.data.errors);
                        (form as any).validate();
                        this.focusInvalidInput();
                    }
                }
                else {
                    this.showErrorSnackbar(ex, options);

                    throw ex;
                }
            }
            finally {
                this.hideLoading();
            }
        }
        
        /**
         * Processa de ações que são enviadas ao servidor.
         * Um feedback de loading será exibido enquanto aguarda a resposta do servidor.
         * Caso o servidor retorne erros de validação elas serão exibidas em um snackbar.
         * @param options
         */
        public async processAction(options: ProcessActionOptions) {
            if (!options)
                throw 'Options deve ser informada';

            if (!options.action)
                throw 'Options.action deve ser informada';

            this.hideSnackbar();
            this.hideLoading();

            try {
                this.showLoading(options);

                let result = typeof options.action === 'function'
                    ? options.action()
                    : options.action;

                if (result && 'then' in (result as any))
                    await result;

                this.showSuccessSnackbar(options);
            }
            catch (ex) {
                this.showErrorSnackbar(ex, options);

                throw ex;
            }
            finally {
                this.hideLoading();
            }
        }

        private showLoading(options: ProcessActionOptions) {
            let showLoading = options.showLoading !== false;
            let showLoadingOverlay = options.showLoadingOverlay === true;
            let loadingMessage = options.loadingMessage || defaultMessages.loadingMessage;

            this.loadingVisible = showLoading;
            this.loadingOverlayVisible = showLoading && showLoadingOverlay;
            this.loadingMessage = loadingMessage;
        }

        private hideLoading() {
            this.loadingVisible = false;
            this.loadingOverlayVisible = false;
        }

        hideSnackbar() {
            this.snackbarVisible = false;
            this.closeTimeout = this.timeout;
            clearInterval(this.funcTimeout);
        }

        private setFuncTimeout(): void {
            if (!this.snackbarVisible) {
                return
            }

            this.funcTimeout = setInterval((() => {
                this.closeTimeout -= this.interval;
                this.percCloseTimeout = Math.round(this.closeTimeout / this.timeout * 100);
            }).bind(this), this.interval);
        }

        private showSuccessSnackbar(options: ProcessActionOptions) {
            let showSnackbar = typeof options.showSnackbar === 'boolean' 
                ? options.showSnackbar !== false 
                : options.showSnackbar?.onSuccess !== false;

            let message = options.successMessage || defaultMessages.successMessage;

            this.snackbarMessage = message;
            this.snackbarColor = 'success';
            this.snackbarVisible = showSnackbar;
            this.setFuncTimeout();
        }

        private showErrorSnackbar(ex: any, options: ProcessActionOptions) {
            let showSnackbar = typeof options.showSnackbar === 'boolean' 
                ? options.showSnackbar !== false 
                : options.showSnackbar?.onError !== false;
            let message = this.getErrorMessage(ex, options);

            this.snackbarMessage = message;
            this.snackbarColor = 'error';
            this.snackbarVisible = showSnackbar;
            this.setFuncTimeout();
        }

        private showFormValidationSnackbar(options: ProcessActionOptions) {
            let showSnackbar = typeof options.showSnackbar === 'boolean' 
                ? options.showSnackbar !== false 
                : options.showSnackbar?.onFormValidation !== false;

            let message = options.validationMessage || defaultMessages.validationMessage;

            this.snackbarMessage = message;
            this.snackbarColor = 'dark';
            this.snackbarVisible = showSnackbar;
            this.setFuncTimeout();
        }

        private createServerValidation(): ServerRuleValidation {
            function serverValidation() {
                if (serverValidation.validation)
                    return serverValidation.validation;

                return true;
            }

            serverValidation.isServer = true;
            serverValidation.validation = '';

            return serverValidation;
        }

        private getServerValidation(rules: FieldRules): ServerRuleValidation {
            return rules.find((r: any) => r.isServer) as any;
        }

        private getOrCreateServerValidation(rules: FieldRules): ServerRuleValidation {
            let server = this.getServerValidation(rules);

            if (!server) {
                server = this.createServerValidation();
                rules.push(server);
            }

            return server;
        }

        private getValidationText(fieldErrors: any): string | null {
            if (typeof fieldErrors === 'string')
                return fieldErrors;

            if (Array.isArray(fieldErrors) && fieldErrors.length) {
                let error = fieldErrors[0];

                if (typeof error === 'string')
                    return error;

                if ('text' in error)
                    return error.text || null;
            }

            return null;
        }

        private getErrorMessage(ex: any, messages: ResponseMessages) {
            if (!ex.response || ex.response.status == 501 || ex.response.status == 502)
                return messages && messages.networkFailureMessage || defaultMessages.networkFailureMessage;

            if (!ex.response || ex.response.status >= 200 && ex.response.status <= 299)
                return messages && messages.successMessage || defaultMessages.successMessage;

            if (ex.response && ex.response.status == 400) {
                if (ex.response.data && ex.response.data.errors)
                    for (let fieldName in ex.response.data.errors) {
                        let fieldErrors = ex.response.data.errors[fieldName];

                        return this.getValidationText(fieldErrors);
                    }

                return messages && messages.badRequestMessage || defaultMessages.badRequestMessage;
            }

            if (ex.response && (ex.response.status == 401 || ex.response.status == 403))
                return messages && messages.unauthorizedMessage || defaultMessages.unauthorizedMessage;

            if (ex.response && ex.response.status == 404)
                return messages && messages.notFoundMessage || defaultMessages.notFoundMessage;

            return messages && messages.internalErrorMessage || defaultMessages.internalErrorMessage;
        }

        private focusInvalidInput() {
            window.requestAnimationFrame(() => {
                const error = document.querySelector('.error--text') as HTMLElement;
                if (error)
                    this.$vuetify.goTo(error);
            });
        }
    }
