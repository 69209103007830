import ApiCore , { ApiCoreBase } from '@/base/api-core.typing';
import axios from "axios";
import Api from "@/base/api.typings";

class PNService extends ApiCoreBase {
    async pesquisarPN(query?: Api.Pn.PesquisarPn.Query): Promise<Api.Pn.PesquisarPn.PnDto[]> {
        const r = await axios.get("/api/pn/pesquisarpn", { params: query });
        return r.data;
    }

    // insert(params?: Api.BusinessPartner.RegisterBusinessPartner.Command): Promise<boolean> {
        
    //     return  this.instance.post('/api/businesspartner', params );
    // }

    // update(params?: Api.BusinessPartner.EditBusinessPartner.Command): Promise<boolean> {

    //     return  this.instance.put('/api/businesspartner',  params );
    // } 

    // inactivate(params?: Api.BusinessPartner.InactivateBusinessPartner.Command): Promise<boolean> {

    //     return  this.instance.put('/api/businesspartner',  params );
    // }


    async getSisCep(query?: Api.Sis_Ceps.GetSis_Cep.Query): Promise<ApiCore.Sis_Cep.Estrutura[]> {
        const response = await axios.get('/api/sisceps', { params: query }).then(r => r.data.sis_cep);
        return response;
    }

    async insert(command?: Api.BusinessPartner.RegisterBusinessPartner.Command): Promise<number> {
        const response = await axios.post('/api/businesspartner', this.objectToUppercase(command));
        return response.data;
    }

    async update(command?: Api.BusinessPartner.EditBusinessPartner.Command): Promise<void> {
        const response = await axios.put('/api/businesspartner', this.objectToUppercase(command));
        return response.data;
    }

    async inactivate(command?: Api.BusinessPartner.InactivateBusinessPartner.Command): Promise<void> {
        const response = await axios.put('/api/businesspartner/Inactive', this.objectToUppercase(command));
        return response.data;
    }
}

export class PNServiceCore extends ApiCoreBase {
    async plano_Contas(params?: ApiCore.Plano_Contas.Query): Promise<ApiCore.Plano_Contas.Estrutura[]> {
        const r = await this.instance.post("/api/Plano_Contas/", { tbParametros: [params] });
        return r.data.plano_contas;
    }

    async pn1(params?: ApiCore.PN.Query): Promise<ApiCore.PN.Estrutura[]> {
        const r = await this.instance.post("/api/PN/", { tbParametros: [params] });
        return r.data.pn;
    }

    async pn_Busca(params?: ApiCore.PN_Busca.Query): Promise<ApiCore.PN_Busca.PNDto4[]> {
        const r = await this.instance.post("/api/PN_Busca/", { tbParametros: [params] });
        return r.data.pn_busca;
    }

    async pn_Endereco_Busca(params?: ApiCore.PN_Endereco_Busca.Query): Promise<ApiCore.PN_Endereco_Busca.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Endereco_Busca/", { tbParametros: [params] });
        return r.data.pn_endereco_busca;
    }

    async pn_Telefone(params?: ApiCore.PN_Telefone.Query): Promise<ApiCore.PN_Telefone.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Telefone/", { tbParametros: [params] });
        return r.data.pn_telefone;
    }
    async pn_Telefone_Busca(params?: ApiCore.PN_Telefone_Busca.Query): Promise<ApiCore.PN_Telefone_Busca.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Telefone_Busca/", { tbParametros: [params] });
        return r.data.pn_telefone_busca;
    }

    async pn_Documento(params?: ApiCore.PN_Documento.Query): Promise<ApiCore.PN_Documento.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Documento/", { tbParametros: [params] });
        return r.data.pn_documento;
    }

    async pn_Documento_Busca(params?: ApiCore.PN_Documento_Busca.Query): Promise<ApiCore.PN_Documento_Busca.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Documento_Busca/", { tbParametros: [params] });
        return r.data.pn_documento_busca;
    }

    async pn_Email(params?: ApiCore.PN_Email.Query): Promise<ApiCore.PN_Email.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Email/", { tbParametros: [params] });
        return r.data.pn_email;
    }

    async pn_Email_Busca(params?: ApiCore.PN_Email_Busca.Query): Promise<ApiCore.PN_Email_Busca.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Email_Busca/", { tbParametros: [params] });
        return r.data.pn_email_busca;
    }

    async pn_Agrupadores_Busca(params?: ApiCore.PN_Agrupadores_Busca.Query): Promise<ApiCore.PN_Agrupadores_Busca.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Agrupadores_Busca/", { tbParametros: [params] });
        return r.data.pn_agrupadores_busca;
    }

    async pn_Fisica(params?: ApiCore.PN_Fisica.Query): Promise<ApiCore.PN_Fisica.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Fisica/", { tbParametros: [params] });
        return r.data.pn_fisica;
    }

    async pn_Dados_Trabalho(params?: ApiCore.PN_Dados_Trabalho.Query): Promise<ApiCore.PN_Dados_Trabalho.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Dados_Trabalho/", { tbParametros: [params] });
        return r.data.pn_dados_trabalho;
    }

    async pn_Cliente(params?: ApiCore.PN_Cliente.Query): Promise<ApiCore.PN_Cliente.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Cliente/", { tbParametros: [params] });
        return r.data.pn_cliente;
    }

    async pn_Agrupadores(params?:  ApiCore.PN_Agrupadores.Query): Promise<ApiCore.PN_Agrupadores.Estrutura[]> {
        return this.instance.post('/api/PN_Agrupadores/', { tbParametros:[params]  } ).then(r => r.data.pn_agrupadores);
    }

    async pn_Fornecedor(params?: ApiCore.PN_Fornecedor.Query): Promise<ApiCore.PN_Fornecedor.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Fornecedor/", { tbParametros: [params] });
        return r.data.pn_fornecedor;
    }

    async pn_Agrupadores_Movto(params?:  ApiCore.PN_Agrupadores_Movto.Query): Promise<ApiCore.PN_Agrupadores_Movto.Estrutura[]> {
        
        return this.instance.post('/api/PN_Agrupadores_Movto/', { tbParametros:[params]  } ).then(r => r.data.pn_agrupadores_movto);
    }

    async pn_Agrupadores_Movto_PN(params?:  ApiCore.PN_Agrupadores_Movto_PN.Query): Promise<ApiCore.PN_Agrupadores_Movto_PN.Estrutura[]> {
        return this.instance.post('/api/PN_Agrupadores_Movto_PN/', { tbParametros:[params]  } ).then(r => r.data.pn_agrupadores_movto_pn);
    }

    async pn_Cnae(params?: ApiCore.PN_Cnae.Query): Promise<ApiCore.PN_Cnae.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Cnae/", { tbParametros: [params] });
        return r.data.pn_cnae;
    }

    async pn_Vendedor(params?: ApiCore.PN_Vendedor.Query): Promise<ApiCore.PN_Vendedor.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Vendedor/", { tbParametros: [params] });
        return r.data.pn_vendedor;
    }

    async pn_Vendedor_Remuneracao(params?: ApiCore.PN_Vendedor_Remuneracao.Query): Promise<ApiCore.PN_Vendedor_Remuneracao.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Vendedor_Remuneracao/", { tbParametros: [params] });
        return r.data.pn_vendedor_remuneracao;
    }

    async pn_Limite_Credito(params?: ApiCore.PN_Limite_Credito.Query): Promise<ApiCore.PN_Limite_Credito.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Limite_Credito/", { tbParametros: [params] });
        return r.data.pn_limite_credito;
    }

    async pn_Representante(params?: ApiCore.PN_Representante.Query): Promise<ApiCore.PN_Representante.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Representante/", { tbParametros: [params] });
        return r.data.pn_representante;
    }

    async pn_Representante_PN_Busca_Dto2(params?: ApiCore.PN_Representante_PN_Busca.Query): Promise<ApiCore.PN_Representante_PN_Busca.Dto2[]> {
        const r = await this.instance.post("/api/PN_Representante_PN_Busca/", { tbParametros: [params] });
        return r.data.pn_representante_pn_busca;
    }

    async pn_Representante_PN_Busca_Dto3(params?: ApiCore.PN_Representante_PN_Busca.Query): Promise<ApiCore.PN_Representante_PN_Busca.Dto3[]> {
        const r = await this.instance.post("/api/PN_Representante_PN_Busca/", { tbParametros: [params] });
        return r.data.pn_representante_pn_busca;
    }

    async pn_Representante_Produto_Agrupadores_Movto_Busca(params?: ApiCore.PN_Representante_Produto_Agrupadores_Movto_Busca.Query): Promise<ApiCore.PN_Representante_Produto_Agrupadores_Movto_Busca.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Representante_Produto_Agrupadores_Movto_Busca/", { tbParametros: [params] });
        return r.data.pn_representante_produto_agrupadores_movto_busca;
    }

    async pn_Agrupadores_Movto_Busca(params?: ApiCore.PN_Agrupadores_Movto_Busca.Query): Promise<ApiCore.PN_Agrupadores_Movto_Busca.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Agrupadores_Movto_Busca/", { tbParametros: [params] });
        return r.data.pn_agrupadores_movto_busca;
    }

    async produto_Agrupadores_Movto_Busca(params?: ApiCore.Produto_Agrupadores_Movto_Busca.Query): Promise<ApiCore.Produto_Agrupadores_Movto_Busca.Estrutura[]> {
        const r = await this.instance.post("/api/Produto_Agrupadores_Movto_Busca/", { tbParametros: [params] });
        return r.data.produto_agrupadores_movto_busca;
    }

    async pn_Representante_Agrupadores_Movto_Busca(params?: ApiCore.PN_Representante_Produto_Agrupadores_Movto_Busca.Query): Promise<ApiCore.PN_Representante_Produto_Agrupadores_Movto_Busca.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Representante_Produto_Agrupadores_Movto_Busca/", { tbParametros: [params] });
        return r.data.pn_representante_produto_agrupadores_movto_busca;
    }

    async pn_Observacao(params?: ApiCore.PN_Observacao.Query): Promise<ApiCore.PN_Observacao.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Observacao/", { tbParametros: [params] });
        return r.data.pn_observacao;
    }
    async pn_Observacao_Busca(params?: ApiCore.PN_Observacao_Busca.Query): Promise<ApiCore.PN_Observacao_Busca.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Observacao_Busca/", { tbParametros: [params] });
        return r.data.pn_observacao_busca;
    }

    async pn_Vinculo(params?: ApiCore.PN_Vinculo.Query): Promise<ApiCore.PN_Vinculo.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Vinculo/", { tbParametros: [params] });
        return r.data.pn_vinculo;
    }

    async pn_Vinculo_Busca(params?: ApiCore.PN_Vinculo_Busca.Query): Promise<ApiCore.PN_Vinculo_Busca.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Vinculo_Busca/", { tbParametros: [params] });
        return r.data.pn_vinculo_busca;
    }

    async pn_Tipo_Observacao(params?: ApiCore.PN_Tipo_Observacao.Query): Promise<ApiCore.PN_Tipo_Observacao.Estrutura[]> {
        const r = await this.instance.post("/api/PN_Tipo_Observacao/", { tbParametros: [params] });
        return r.data.pn_tipo_observacao;
    }

    async sis_Cep(params?: ApiCore.Sis_Cep.Query): Promise<ApiCore.Sis_Cep.Estrutura[]> {
        const r = await this.instance.post("/api/Sis_Cep/", { tbParametros: [params] });
        return r.data.sis_cep;
    }
    
    async sis_Cnae(params?: ApiCore.Sis_Cnae.Query): Promise<ApiCore.Sis_Cnae.Estrutura[]> {
        const r = await this.instance.post("/api/Sis_Cnae/", { tbParametros: [params] });
        return r.data.sis_cnae;
    }

    async sis_Pn_Tipo_Vinculo(params?: ApiCore.Sis_PN_Tipo_Vinculo.Query): Promise<ApiCore.Sis_PN_Tipo_Vinculo.Estrutura[]> {
        const r = await this.instance.post("/api/Sis_PN_Tipo_Vinculo/", { tbParametros: [params] });
        return r.data.sis_pn_tipo_vinculo;
    }

    async sis_Status_Cliente(params?: ApiCore.Sis_Status_Cliente.Query): Promise<ApiCore.Sis_Status_Cliente.Estrutura[]> {
        const r = await this.instance.post("/api/Sis_Status_Cliente/", { tbParametros: [params] });
        return r.data.sis_status_cliente;
    }

    async sis_Tipo_Endereco(params?: ApiCore.Sis_Tipo_Endereco.Query): Promise<ApiCore.Sis_Tipo_Endereco.Estrutura[]> {
        const r = await this.instance
            .post("/api/Sis_Tipo_Endereco/", { tbParametros: [params] });
        return r.data.sis_tipo_endereco;
    }

    async sis_Tipo_Telefone(params?: ApiCore.Sis_Tipo_Telefone.Query): Promise<ApiCore.Sis_Tipo_Telefone.Estrutura[]> {
        const r = await this.instance.post("/api/Sis_Tipo_Telefone/", { tbParametros: [params] });
        return r.data.sis_tipo_telefone;
    }

    async sis_Tipo_Representante(params?: ApiCore.Sis_Tipo_Representante.Query): Promise<ApiCore.Sis_Tipo_Representante.Estrutura[]> {
        const r = await this.instance.post("/api/Sis_Tipo_Representante/", { tbParametros: [params] });
        return r.data.sis_tipo_representante;
    }

    async sis_Cbo(params?:  ApiCore.Sis_Cbo.Query): Promise<ApiCore.Sis_Cbo.Estrutura[]> {
        return this.instance.post('/api/Sis_Cbo/', { tbParametros:[params]  } ).then(r => r.data.sis_cbo);
    }

    async pn_Busca3(params?: ApiCore.PN_Busca.Query): Promise<ApiCore.PN_Busca.PNDto3[]> {
        const r = await this.instance.post("/api/PN_Busca/", { tbParametros: [params] });
        return r.data.pn_busca;
    }

    async pn_Documento_Busca3(params?: ApiCore.PN_Documento_Busca.Query): Promise<ApiCore.PN_Documento_Busca.PN_Documento_Busca_Dto3[]> {
        const r = await this.instance.post("/api/PN_Documento_Busca/", { tbParametros: [params] });
        return r.data.pn_documento_busca;
    }

    async pn_Observacao_Busca2(params?: ApiCore.PN_Observacao_Busca.Query): Promise<ApiCore.PN_Observacao_Busca.PN_Observacao_BuscaDto2[]> {
        const r = await this.instance.post("/api/PN_Observacao_Busca/", { tbParametros: [params] });
        return r.data.pn_observacao_busca;
    }

    async sis_Tipo_Documento(params?: ApiCore.Sis_Tipo_Documento.Query): Promise<ApiCore.Sis_Tipo_Documento.Estrutura[]> {
        const r = await this.instance.post("/api/Sis_Tipo_Documento/", { tbParametros: [params] });
        return r.data.sis_tipo_documento;
    }

    async pn_Telefone_Busca3(params?: ApiCore.PN_Telefone_Busca.Query): Promise<ApiCore.PN_Telefone_Busca.PN_Telefone_BuscaDto3[]> {
        const r = await this.instance.post("/api/PN_Telefone_Busca/", { tbParametros: [params] });
        return r.data.pn_telefone_busca;
    }

    async sis_Tipo_Email(params?: ApiCore.Sis_Tipo_Email.Query): Promise<ApiCore.Sis_Tipo_Email.Estrutura[]> {
        const r = await this.instance.post("/api/Sis_Tipo_Email/", { tbParametros: [params] });
        return r.data.sis_tipo_email;
    }
}

export default new PNService();
