import convertTo from "@/utils/convert-to";
import format from "@/utils/format";

/* eslint-disable @typescript-eslint/no-explicit-any */
type DynamicParam<T> = () => T;

type Param<T> = T | DynamicParam<T>;

/**
 * Retorna o valor de um parâmetro. Se o parâmetro for uma função, então ela é executada e seu resultado é retornado.
 * @param params
 */
function getParam<T>(params: Param<T>): T {
    if (typeof params === 'function')
        return (params as any)();

    return params;
}

/**
 * Retorna true se o valor de um campo for considerado como preenchido.
 * @param value
 */
const req = (value: any) => {
    if (Array.isArray(value)) {
        return !!value.length
    }

    if (value === undefined || value === null) {
        return false
    }

    if (value === false) {
        return false
    }

    if (value instanceof Date) {
        // invalid date won't pass
        return !isNaN(value.getTime())
    }

    if (typeof value === 'object') {
        for (const _ in value) return true
        return false
    }

    return !!String(value).length
}

/**
 * Obriga o preenchimento do campo.
 * @param value
 */
export function required(value: any) {
    if (!req(value))
        return 'Preencha este campo.';

    return true;
}

export function requiredWithMessage(message: Param<string> = 'Preencha este campo.') {
    return (value: any) => {
        if (!req(value))
            return getParam(message);

        return true;
    }
}

/**
 * Obriga o preenchimento do campo somente se condition for válida.
 * @param condition Condição em que o campo deve ser preenchido.
 */
export function requiredIf(condition: Param<boolean>) {
    return (value: any) => {
        if (getParam(condition))
            return required(value);

        return true;
    }
}
 

export function requiredIfValidatedByFunction(condition: Param<boolean>, message: Param<string> = 'Preencha este campo.') {
    return () => {
        if (getParam(condition))
            return getParam(message);

        return true;
    }
}

export function requiredIfWithMessage(condition: Param<boolean>, message: Param<string> = 'Preencha este campo.') {
    return (value: any) => {
        if (getParam(condition))
            if (!req(value))
                return getParam(message);

        return true;
    }
}

/**
 * Obriga o preenchimento do campo a não ser que a condição seja válida.
 * @param condition
 */
export function requiredUnless(condition: Param<boolean>) {
    return (value: any) => {
        if (!getParam(condition))
            return required(value);

        return true;
    }
}

function countDescription(count: number) {
    return count == 1 ? '1 caracter' : `${count} caracteres`;
}

/**
 * Obriga um tamanho máximo de texto.
 * @param max Quantidade máxima de caracteres. Pode ser passada uma função para que a quantidade seja dinâmica.
 */
export function maxLength(max: Param<number>) {
    return (value: string) => {
        if (typeof value !== 'string') {
            return true;
        }

        const maxValue = getParam(max);
        const length = value?.length ?? 0;
        
        if (length > maxValue)
            return `Tamanho máximo de ${countDescription(maxValue)}, você preencheu ${countDescription(length)}`;

        return true;
    };
}

/**
 * Obriga um tamanho específico de texto.
 * @param size Quantidade de caracteres. Pode ser passada uma função para que a quantidade seja dinâmica.
 */
export function length(size: Param<number>) {
    return (value: string) => {
        const sizeValue = getParam(size);
        
        if (typeof value === 'string' && value.length !== sizeValue)
            return `Tamanho deve ser ${countDescription(sizeValue)}.`;

        return true;
    };
}

/**
 * Obriga um tamanho mínimo de texto.
 * @param min Quantidade mínima de caracteres. Pode ser passada uma função para que a configuração seja dinâmica.
 */
export function minLength(min: Param<number>) {
    return (value: string) => {
        if (typeof value !== 'string') {
            return true;
        }

        const minValue = getParam(min);

        const length = value?.length ?? 0;

        if (length < minValue)
            return `Tamanho mínimo de ${countDescription(minValue)}, você preencheu ${countDescription(length)}.`;

        return true;
    };
}

const alphaRegex = /^[a-zA-Z]*$/;

/**
 * Obriga somente letras (sem espaço e acentuação).
 * @param value
 */
export function alpha(value: string) {
    if (req(value) && !alphaRegex.test(value))
        return 'Informe somente letras.';

    return true;
}

const alphaNumRegex = /^[a-zA-Z0-9]*$/;

/**
 * Obriga somente letras e números (sem espaço e acentuação).
 * @param value
 */
export function alphaNum(value: string) {
    if (req(value) && !alphaNumRegex.test(value))
        return 'Informe somente letras ou números.';

    return true;
}

const emailRegex  = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Obriga que o texto esteja em formato de email.
 * @param value
 */
export function email(value: string) {
    if (req(value) && !emailRegex.test(value))
        return 'E-mail inválido.';

    return true;
}

const cpfRegex = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}?[0-9]{2}$/;
const cnpjRegex = /^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}?[0-9]{2}$/;
export function documento(isCPF: Param<boolean>) {
    return (value: string) => {
        const isCPFValue = getParam(isCPF);
        if (value == null)
            return true;

        if(isCPFValue == true){
            if (req(value) && !cpfRegex.test(format.justNumbers(value))){
                return 'CPF inválido.';
            }
        }
        else{
            if (req(value) && !cnpjRegex.test(format.justNumbers(value))){
                return 'CNPJ inválido.';
            }
        }
        return true;
    }
}

export function validateCPF_CNPJ(value: string): boolean {
    if (value.length === 11) {
      // Validate CPF
      let sum = 0;
      let remainder;
  
      for (let i = 0; i < 9; i++) {
        sum += parseInt(value.charAt(i)) * (10 - i);
      }
  
      remainder = sum % 11;
  
      if (remainder < 2) {
        remainder = 0;
      } else {
        remainder = 11 - remainder;
      }
  
      if (remainder !== parseInt(value.charAt(9))) {
        return false;
      }
  
      sum = 0;
  
      for (let i = 0; i < 10; i++) {
        sum += parseInt(value.charAt(i)) * (11 - i);
      }
  
      remainder = sum % 11;
  
      if (remainder < 2) {
        remainder = 0;
      } else {
        remainder = 11 - remainder;
      }
  
      if (remainder !== parseInt(value.charAt(10))) {
        return false;
      }
  
      return true;
    } else if (value.length === 14) {
      // Validate CNPJ
      let sum = 0;
      let remainder;
  
      let weight = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  
      for (let i = 0; i < 12; i++) {
        sum += parseInt(value.charAt(i)) * weight[i];
      }
  
      remainder = sum % 11;
  
      if (remainder < 2) {
        remainder = 0;
      } else {
        remainder = 11 - remainder;
      }
  
      if (remainder !== parseInt(value.charAt(12))) {
        return false;
      }
  
      sum = 0;
      weight = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  
      for (let i = 0; i < 13; i++) {
        sum += parseInt(value.charAt(i)) * weight[i];
      }
  
      remainder = sum % 11;
  
      if (remainder < 2) {
        remainder = 0;
      } else {
        remainder = 11 - remainder;
      }
  
      if (remainder !== parseInt(value.charAt(13))) {
        return false;
      }
  
      return true;
    }
  
    return false;
  }


/**
 * Obriga que o valor esteja entre os parâmetros passados.
 * @param min Valor mínimo (>=). Pode ser passada uma função para que a configuração seja dinâmica.
 * @param max Valor máximo (<=). Pode ser passada uma função para que a configuração seja dinâmica.
 */
export function between(min: Param<number | Date>, max: Param<number | Date>) {
    return (value: string) => {
        if (!req(value))
            return true;

        const minValue = getParam(min);
        const maxValue = getParam(max);

        if (+value < +minValue || +value > +maxValue)
            return `Deve estar entre ${minValue} e ${maxValue}.`;

        return true;
    };
}
/*
* Obriga que o valor seja maior ou igual ao parâmetro.
* @param min Valor mínimo. Pode ser passada uma função para que a configuração seja dinâmica.
*/
export function minValue(min: Param<number | Date>) {

    return (value: string | number) => {
          
        if (!req(value))
            return true;

        const minValue = getParam(min);
 
        if(typeof value == "string"){

           value = convertTo.Decimal(value.replace(",","."));

        }
  
        if (+value < +minValue)
            return `Deve ser maior ou igual a ${minValue}.`;

       return true;
   };
}

/**
* Obriga que o valor seja menor ou igual ao parâmetro.
* @param max Valor máximo. Pode ser passada uma função para que a configuração seja dinâmica.
*/
export function maxValue(max: Param<number | Date>) {
   return (value: string) => {
       if (!req(value))
           return true;

       const maxValue = getParam(max);

       if (+value > +maxValue)
           return `Deve ser menor ou igual a ${maxValue}.`;

       return true;
   };
}

const decimalRegex = /^[-]?\d*(\.\d+)?$/;

/**
 * Obriga um decimal válido (formato en-US).
 * @param value
 */
export function decimal(value: number) {
    if (req(value) && !decimalRegex.test(value as any))
        return 'Número decimal inválido.';

    return true;
}

const decimalPtBrRegex = /^[-]?\d*(,\d+)?$/;

/**
 * Obriga um decimal válido (formato pt-BR).
 * @param value
 */
export function decimalPtBr(value: number) {
    if (req(value) && !decimalPtBrRegex.test(value as any))
        return 'Número decimal inválido.';

    return true;
}

const integerRegex = /(^[0-9]*$)|(^-[0-9]+$)/;

/**
 * Obriga um número inteiro (incluindo número negativo)
 * @param value
 */
export function integer(value: number) {
    if (req(value) && !integerRegex.test(value as any))
        return 'Número inteiro inválido.';

    return true;
}

const numericRegex = /^[0-9]*$/;

/**
 * Obriga um número inteiro positivo.
 * @param value
 */
export function numeric(value: number) {
    if (req(value) && !numericRegex.test(value as any))
        return 'Número inválido.';

    return true;
}

/**
 * Obriga que o valor esteja de acordo com a expressão regular
 * @param regex
 */
export function match(regex: Param<RegExp>) {
    return (value: string) => {
        const regexValue = getParam(regex);

        if (req(value) && !regexValue.test(value as any))
            return 'Formato inválido.';

        return true;
    };
}

export function unique(uniqueValues: Param<any[]>, message?: Param<string>) {
    return (value: string) => {
        const unique = getParam(uniqueValues);
        const msg = getParam(message ?? '') || 'Valor não é único.';

        if (unique.filter(f => f == value).length > 1)
            return msg;

        return true;
    };
}
