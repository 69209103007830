
/* eslint-disable @typescript-eslint/no-explicit-any */

import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

// Services
import agrupadoresService from "@/features/agrupadores/agrupadores-service";
import modelosNotasService from "@/features/modelos-notas/modelos-notas-service";
import pnService from "@/features/pn/pn-service";
import empresasService from "@/features/empresas/empresas-service";
import planoContasService from "@/features/plano-contas/plano-contas-service";
import tabelaPrecoService from "@/features/tabela-preco/tabela-preco-service";
import unidadeEstoqueService from "../features/unidade-estoque/unidade-estoque-service";
import planoPagamentoService from "@/features/plano-pagamento/plano-pagamento-service";

// Typings
import Api from "@/base/api.typings";
import Client from "@/base/client.typings";
import { FieldRules } from "./action-controller.interfaces";

// Extensions
import "@/utils/extensions/string";
import { BankAccountsRepository } from "../features/bank-accounts/bank-accounts-service";

enum StatusSelectedItems {
    All,
    Some,
    None,
}

@Component
export default class Search extends Vue {
    // #region Data ---------------------------------------------------------------------------------------

    // Component Properties -----------------------------
    @Prop() readonly value: any | any[];

    @Prop({ type: String, default: "" }) readonly label: string;
    @Prop({ type: String, default: "" }) readonly placeholder: string;
    @Prop({ type: String, default: "" }) readonly hint: string;
    @Prop({ type: String, default: "" }) readonly appendOuterIcon: string;
    
    @Prop({ type: Boolean, default: false }) readonly chips: boolean;
    @Prop({ type: Boolean, default: false }) readonly smallChips: boolean;
    @Prop({ type: Boolean, default: false }) readonly multiple: boolean;
    @Prop({ type: Boolean, default: false }) readonly returnObject: boolean;
    @Prop({ type: Boolean, default: false }) readonly deletableChips: boolean;
    @Prop({ type: Boolean, default: false }) readonly clearable: boolean;
    @Prop({ type: Boolean, default: false }) readonly cacheItems: boolean;
    @Prop({ type: Boolean, default: false }) readonly disabled: boolean;
    @Prop({ type: Boolean, default: false }) readonly useOnSale: boolean; //TODO ver depois como fazer, o certo ja era separar o plano de pagamento daqui
    @Prop({ type: Boolean, default: false }) readonly useOnPurchase: boolean; //TODO ver depois como fazer, o certo ja era separar o plano de pagamento daqui

    @Prop({ type: Boolean, default: false }) readonly IsClient: boolean;
    @Prop({ type: Boolean, default: false }) readonly IsProvider: boolean;
    @Prop({ type: Boolean, default: false }) readonly IsVender: boolean;
 

    @Prop() readonly data!: Client.ConjuntoValorTexto[];

    @Prop({
        default: Api.Domain.Enums.BuscarDadosEm.SemBusca,
        validator: (value) => Api.Domain.Enums.BuscarDadosEm[value as any] !== null,
    })
    readonly fetchDataFrom: Api.Domain.Enums.BuscarDadosEm;

    @Prop() readonly rules: FieldRules;

    // Consts Properties --------------------------------

    // Computed Properties ------------------------------

    get color(): string {
        return this.getStatusSelectedItemsFromComboBox() ==
            StatusSelectedItems.All
            ? "indigo darken-4"
            : "";
    }

    get icon(): string {
        if (!this.multiple) return "";

        switch (this.getStatusSelectedItemsFromComboBox()) {
            case StatusSelectedItems.All:
                return "mdi-close-box";
            case StatusSelectedItems.Some:
                return "mdi-minus-box";
            default:
                return "mdi-checkbox-blank-outline";
        }
    }

    get isAllSelected(): boolean {
        return (
            this.multiple &&
            this.selectedItems &&
            (this.selectedItems as any[]).filter(
                (f) => f.valor > Number.MIN_VALUE
            ).length <= 0
        );
    }

    get isDataAvailable(): boolean {
        return (this.data != null && this.data.length > 0) || false;
    }

    get isItemsEmpty(): boolean {
        const items = this.getItems;
        return !((items && items.length > 0) || false);
    }

    get isThereSelectedValue(): boolean {
        return (
            (this.selectedItems != null && this.selectedItems.length > 0) ||
            false
        );
    }

    get getItems(): Client.ConjuntoValorTexto[] {
        return this.isDataAvailable ? this.data : this.items;
    }

    // Local variables ----------------------------------

    isLoading = false;
    isSearching = false;

    items: Client.ConjuntoValorTexto[] = this.isDataAvailable ? this.data : [];
    search: string = null;
    selectedItems: any | any[] = null;
    timerToSearch = 0;

    // #endregion

    // #region Watchers -----------------------------------------------------------------------------------

    @Watch("search", { immediate: false, deep: false })
    async getSearch(value: string): Promise<void> {
        if (this.fetchDataFrom == Api.Domain.Enums.BuscarDadosEm.SemBusca)
            return;

        clearTimeout(this.timerToSearch);

        if (!String.isNullOrWhiteSpace(value)) {
            this.timerToSearch = setTimeout(this.makeSearch, 500, value.trim());
        } else {
            this.items = [];
        }
    }

    @Watch("selectedItems", { immediate: false, deep: true })
    synchronizeInputData(): void {
        const itemsToEmit = this.getStatusSelectedItemsFromComboBox() == StatusSelectedItems.All 
            ? this.getItems
            : this.selectedItems;

        this.$emit("input", itemsToEmit);
    }

    // #endregion

    // #region Methods ------------------------------------------------------------------------------------

    // Life Cycles --------------------------------------

    beforeCreated(): void {
        if (
            this.fetchDataFrom == Api.Domain.Enums.BuscarDadosEm.SemBusca &&
            !this.isDataAvailable
        )
            throw new Error(
                "Component is configured not to fetch data, but initial data has not been provided."
            );

        if (
            this.fetchDataFrom !== Api.Domain.Enums.BuscarDadosEm.SemBusca &&
            this.isDataAvailable
        )
            throw new Error(
                "Component is configured to fetch data, but initial data has been provided."
            );
    }

    mounted(): void {
        if (this.value != null) {
            this.selectedItems = this.value;
        }
    }

    // List Events --------------------------------

    // @Click
    selectAllItems(): void {
        this.$nextTick(
            (() => {
                const items = this.getItems;

                if (
                    this.getStatusSelectedItemsFromComboBox() !=
                    StatusSelectedItems.All
                ) {
                    // Add
                    this.selectedItems = items;
                } else {
                    // Remove
                    this.selectedItems = [];
                }
                this.$emit("input", this.getItems);
                this.$emit("all-items");
            }).bind(this)
        );
    }

    // Local methods ------------------------------------

    getGrouperType(
        value: Api.Domain.Enums.BuscarDadosEm
    ): Api.Agrupadores.AgrupadorDe {
        switch (value) {
            case Api.Domain.Enums.BuscarDadosEm.AgrupadoresPn:
                return Api.Agrupadores.AgrupadorDe.Pn;
            case Api.Domain.Enums.BuscarDadosEm.AgrupadoresProdutos:
                return Api.Agrupadores.AgrupadorDe.Produtos;
            default:
                throw new Error(
                    "Error on translating 'Api.Domain.Enums.BuscarDadosEm' to 'Api.Agrupadores.AgrupadorDe'."
                );
        }
    }

    getPnType(
        value: Api.Domain.Enums.BuscarDadosEm
    ): Api.Pn.PesquisarPn.PesquisarIndPn {
        switch (value) {
            case Api.Domain.Enums.BuscarDadosEm.Pn:
                return Api.Pn.PesquisarPn.PesquisarIndPn.Todos;
            case Api.Domain.Enums.BuscarDadosEm.Cliente:
                return Api.Pn.PesquisarPn.PesquisarIndPn.Cliente;
            case Api.Domain.Enums.BuscarDadosEm.Fornecedor:
                return Api.Pn.PesquisarPn.PesquisarIndPn.Fornecedor;
            case Api.Domain.Enums.BuscarDadosEm.Vendedor:
                return Api.Pn.PesquisarPn.PesquisarIndPn.Vendedor;
            default:
                throw new Error(
                    "Error on translating 'Api.Domain.Enums.BuscarDadosEm' to 'Api.Pn.PesquisarPn.PesquisarIndPn'."
                );
        }
    }

    getStatusSelectedItemsFromComboBox(): StatusSelectedItems {
        if (!this.multiple) return StatusSelectedItems.None;

        let length = this.isThereSelectedValue ? this.selectedItems.length : 0;
        let total = this.isItemsEmpty ? 0 : this.getItems.length;

        return total <= 0
            ? StatusSelectedItems.None
            : length == total
            ? StatusSelectedItems.All
            : length > 0
            ? StatusSelectedItems.Some
            : StatusSelectedItems.None;
    }

    async makeSearch(searchFor: string): Promise<void> {
        if (
            this.isSearching ||
            this.fetchDataFrom == Api.Domain.Enums.BuscarDadosEm.SemBusca
        )
            return;

        if (String.isNullOrWhiteSpace(searchFor))
            throw new Error("Parameter 'searchFor' is null or white space.");

        try {
            this.isSearching = true;

            switch (this.fetchDataFrom) {
                case Api.Domain.Enums.BuscarDadosEm.ModelosNotas:
                    this.items =
                        (await modelosNotasService.pesquisar({
                            search: searchFor,
                        })) as Api.Domain.Tables.ConjuntoValorTexto[];
                    break;

                case Api.Domain.Enums.BuscarDadosEm.Pn:
                case Api.Domain.Enums.BuscarDadosEm.Cliente:
                case Api.Domain.Enums.BuscarDadosEm.Fornecedor:
                case Api.Domain.Enums.BuscarDadosEm.Vendedor:
                   this.items = (await pnService.pesquisarPN({
                        search: searchFor,
                        pesquisarIndPn: this.getPnType(this.fetchDataFrom),
                    })) as Api.Domain.Tables.ConjuntoValorTexto[];
                    break;

                case Api.Domain.Enums.BuscarDadosEm.AgrupadoresPn:
                case Api.Domain.Enums.BuscarDadosEm.AgrupadoresProdutos:
                    this.items =
                        (await agrupadoresService.pesquisarPorAgrupadores({
                            pesquisa: searchFor,
                            agrupadorDe: this.getGrouperType(
                                this.fetchDataFrom
                            ),
                        })) as Api.Domain.Tables.ConjuntoValorTexto[];
                    break;

                case Api.Domain.Enums.BuscarDadosEm.Empresas:
                    this.items = (await empresasService.pesquisarEmpresas({
                        search: searchFor,
                        inactive:false,
                    })) as Api.Domain.Tables.ConjuntoValorTexto[];
                    break;

                case Api.Domain.Enums.BuscarDadosEm.PlanoContas:
                    this.items = (await planoContasService.pesquisarPlanoContas(
                        {
                            search: searchFor,
                        }
                    )) as Api.Domain.Tables.ConjuntoValorTexto[];
                    break;

                case Api.Domain.Enums.BuscarDadosEm.TabelaPreco:
                    this.items = (await tabelaPrecoService.pesquisarTabelaPreco(
                        {
                            search: searchFor,
                        }
                    )) as Api.Domain.Tables.ConjuntoValorTexto[];
                    break;

                case Api.Domain.Enums.BuscarDadosEm.UnidadeEstoque:
                    this.items =
                        (await unidadeEstoqueService.pesquisarUnidadeEstoque({
                            search: searchFor,
                        })) as Api.Domain.Tables.ConjuntoValorTexto[];
                    break;

                case Api.Domain.Enums.BuscarDadosEm.PlanoPagamento:
                    this.items =
                        (await planoPagamentoService.pesquisarPlanoPagamento({
                            inactive:false,
                            useOnSale:this.useOnSale,
                            useOnPurchase:this.useOnPurchase,
                            search: searchFor,
                        }))  as Api.Domain.Tables.ConjuntoValorTexto[];
                    break;

                case Api.Domain.Enums.BuscarDadosEm.PlanoPagamentoGrupo:
                    this.items =
                        (await planoPagamentoService.pesquisarPlanoPagamentoGrupo(
                            {
                                search: searchFor,
                            }
                        )) as Api.Domain.Tables.ConjuntoValorTexto[];
                    break;

                case Api.Domain.Enums.BuscarDadosEm.ContaCorrente:
                    this.items = await (
                        await new BankAccountsRepository().GetAll({
                            search: searchFor,
                        })
                    ).map((m) => {
                        return {
                            valor: m.id,
                            texto: m.name,
                        } as Client.ConjuntoValorTexto;
                    });

                    throw new Error(
                        "Filter field is configured to access an endpoint, but the endpoint is not specified."
                    );
            }
        } finally {
            this.isSearching = false;
        }
    }

    // #endregion
}
