
    /* eslint-disable @typescript-eslint/no-explicit-any */
    
    import Vue from 'vue';
    import { Component, Prop, Watch } from 'vue-property-decorator';

    import moment from 'moment';
    import ConvertTo from '@/utils/convert-to';
    import Format from '@/utils/format';
    import Enum from '@/utils/enum';

    import draggable from 'vuedraggable';
    import reportFilter from '@/components/report-filter.vue';
    import { FormRules } from '@/components/action-controller.interfaces';

    import * as validations from '@/base/validations';

    import Api from '@/base/api.typings';
    import Client from '@/base/client.typings';

    import { ReportGroupsRepository, ReportRepository } from '@/features/reports/reports-service';
    import router from '@/router';

    enum MathOperations {
        Sum,
        Average,
        Max,
        Min
    }

    interface IBaseTotalizer {
        [index: string]: any,
        id: number,
        name?: string,
        operation?: MathOperations,
    }

    interface IVerticalTotalizer extends IBaseTotalizer {
        columnId?: number
    }

    interface IHorizontalTotalizer extends IBaseTotalizer {
        columnsId?: number[],
        align?: Api.Domain.Enums.Align,
        format?: Api.Domain.Enums.Format
    }

    interface ITotalizer {
        horizontal: IHorizontalTotalizer[],
        vertical: IVerticalTotalizer[]
    }

    @Component({
        components: {
            draggable,
            reportFilter,
        }
    })
    export default class Relatorio extends Vue {

        inputItemsPerPage: string = null;
        itemsPerPage = -1;

        sanitazeInputItemsPerPage(input: string) {
            if (input == '' || input == null) {
                this.itemsPerPage = this.reportSelected.data.length;
            } else {
                let value = parseInt(input, 10);

                if (value == null || value < 1) {
                    this.itemsPerPage = 1;
                } else if (value > this.reportSelected.data.length) {
                    this.itemsPerPage = this.reportSelected.data.length;
                } else {
                    this.itemsPerPage = value;
                }

                this.inputItemsPerPage = this.itemsPerPage.toString();
            }
        }

        get hideFooter(): boolean {
            return this.isPrinting || this.itemsPerPage == this.reportSelected.data.length;
        }

        columnFiltersTab = 0;
        showReport = false;
        showReportSettings = false;
        showHorizontalTotalizerCrud = false;
        showVerticalTotalizerCrud = false;

        showTotalizerVerticalTotal = false;
        showTotalizerVerticalTotalByGroup = false;

        totalizerHorizontalIdCounter = 0;

        totalizer: ITotalizer = {
            horizontal: [],
            vertical: []
        };

        getTotalizerHorizontalId(): number {
            return --this.totalizerHorizontalIdCounter;
        }

        horizontalTotalizerModel: IHorizontalTotalizer = { id: 0, name: null, align: null, columnsId: [], format: null, operation: null };
        verticalTotalizerModel: IVerticalTotalizer = { id: 0, name: null, columnId: null, operation: null };

        isEditingHorizontalTotalizer = false;
        horizontalTotalizerModelIndex = 0;

        isEditingVerticalTotalizer = false;
        verticalTotalizerModelIndex = 0;

        customColumnAvaliableColumnsItems: Client.ConjuntoValorTexto[] = [];


        // #region Data ---------------------------------------------------------------------------------------

        // Component Properties -----------------------------
        @Prop({
            type: [Number, String],
            required: true,
            validator: (v) => {
                if (v == null || typeof v !== 'string') {
                    return false;
                }
                else if (parseInt(v) > 10000000000) {
                    return true;
                } else {
                    router.push({ name: 'error', params: { message: 'Grupo de relatório inválido' } });
                    return false;
                }
            }

        }) readonly reportGroupId: number;

        @Prop({
            required: false
        }) readonly reportGroupObject: Api.Reports.GetReportGroups.ReportGroupDto;


        // Consts Properties --------------------------------
        readonly AlignItems: Client.ConjuntoValorTexto[] = [
            {
                valor: Api.Domain.Enums.Align.Start,
                texto: 'Esquerda'
            },
            {
                valor: Api.Domain.Enums.Align.Center,
                texto: 'Centro'
            },
            {
                valor: Api.Domain.Enums.Align.End,
                texto: 'Direita'
            },
        ]

        readonly FormatItems: Client.ConjuntoValorTexto[] = [
            {
                valor: Api.Domain.Enums.Format.Number,
                texto: 'Número'
            },
            {
                valor: Api.Domain.Enums.Format.Decimal,
                texto: 'Decimal'
            },
            {
                valor: Api.Domain.Enums.Format.Currency,
                texto: 'Monetário'
            },
        ]

        readonly MathOperationItems: Client.ConjuntoValorTexto[] = [
            {
                texto: 'Somatório',
                valor: MathOperations.Sum,
            },
            {
                texto: 'Média',
                valor: MathOperations.Average,
            },
            {
                texto: 'Máximo',
                valor: MathOperations.Max,
            },
            {
                texto: 'Mínimo',
                valor: MathOperations.Min,
            },
        ]


        // Computed Properties ------------------------------
        get isReportSelected(): boolean {
            return this.reportSelected?.id > 0 || false;
        }

        get isReportViewAvailable(): boolean {
            return this.isReportSelected && this.hasGeneratedReport && !this.isGeneratingReport;
        }

        get validFilters(): Api.Reports.GenerateReport.Filter[] {
            if (!(this.filtersData?.length > 0))
                return [];

            return this.filtersData
                .filter(f => f != null && f.id > 0 && f.values?.length > 0)
                .map(m => {
                    return {
                        id: m.id,
                        values: m.values?.filter(f => f != null) ?? null,
                    } as Api.Reports.GenerateReport.Filter;
                })
                .filter(f => f.values?.length > 0);
        }

        get Title(): string {
            return this.reportGroup?.name ?? ''
        }

        get ReportTitle(): string {
            return ((this.reportGroup?.name ?? '') != '' && (this.reportSelected?.name ?? '') != '') ? `${this.reportGroup.name} - ${this.reportSelected.name}` : '';
        }

        get GenerateReportButtonText(): string {
            let text: string = this.hasGeneratedReport ? 'Visualizar' : 'Gerar';
            return `${text} Relatório`;
        }

        get isTotalizerVerticalAvailable(): boolean {
            return this.totalizer?.vertical?.length > 0;
        }
        
        get isGroupBySelected(): boolean {
            return !String.isNullOrWhiteSpace(this.groupBy);
        }

        get ColumnsForVerticalTotalizer(): Client.SelectModel[] {
            return [
                ...this.getValidColumnsForTotalizer(),
                ...this.getColumnsOfTotalizerForTotalizer(false)
            ];
        }

        get ColumnsForHorizontalTotalizer(): Client.SelectModel[] {
            return [
                ...this.getValidColumnsForTotalizer(),
                ...this.getColumnsOfTotalizerForTotalizer(true)
            ];
        }

        // Local variables ----------------------------------

        getValidColumnsForTotalizer(): Client.SelectModel[] {
            if (!(this.columnsSelected?.length > 0))
                return [];

            return this.columnsSelected
                .filter(f => Enum.In(f.format,
                    Api.Domain.Enums.Format.Decimal,
                    Api.Domain.Enums.Format.Currency,
                    Api.Domain.Enums.Format.Number)
                )
                .map(m => {
                    return {
                        text: m.text,
                        value: m.id
                    }
                });
        }

        getColumnsOfTotalizerForTotalizer(isForHorizontalTotalizer: boolean): Client.SelectModel[] {
            if (!(this.totalizer?.horizontal?.length > 0))
                return [];

            return this.totalizer.horizontal
                .filter(f => isForHorizontalTotalizer ? f.id != this.horizontalTotalizerModel?.id : true)
                .map(m => {
                    return {
                        text: m.name,
                        value: m.id,
                    }
                });
        }

        reportGroupsService: ReportGroupsRepository = new ReportGroupsRepository();
        reportService: ReportRepository = null;

        isGeneratingReport = false;
        isLoadingReports = false;
        isLoadingReportGroup = false;
        isLoadingColumnsAndFilters = false;
        isPrinting = false;

        hasGeneratedReport = false;

        groupBy: string = null;
        columnsSelected: Api.Reports.GetReport.ColumnDto[] = [];
        filtersData: Api.Reports.GenerateReport.Filter[] = [];
        reportTableHeaders: Client.Report.Header[] = null;

        reportGroup: Client.Report.ReportGroupDto = { id: 0, name: null, reports: [] };
        reportSelected: Client.Report.ReportDto = null;
        reportIdSelected = 0;




        rules: FormRules = {
            $summary: []
        };

        rulesAddTotalizer: FormRules = {
            $summary: [],
            name: [validations.required],
            operation: [validations.required],
            columns: [validations.required],
            align: [validations.required],
            format: [validations.required],
        };

        validationAddVerticalTotalizer: ((value: string) => string | true)[] = [
            validations.required,
            validations.unique(() => this.totalizer?.vertical?.filter(f => f.columnId != null).map(m => m.columnId) ?? [] )
        ];

        // #endregion




        // #region Watchers -----------------------------------------------------------------------------------

        @Watch('reportGroupId', { deep: false, immediate: true })
        async onChangingGrupoId(newReportGroupId: number, oldReportGroupId: number) {
            if (newReportGroupId != oldReportGroupId) {
                this.columnFiltersTab = 0;
                this.cleanReport();
                await this.loadReportGroup();
                await this.loadReports();
            }
        }

        @Watch('showHorizontalTotalizerCrud')
        onShowHorizontalTotalizerCrudChange(value: boolean) {
            if (value == false) {
                this.cleanHorizontalTotalizerModel();
                this.isEditingHorizontalTotalizer = false;
                (this.$refs.formHorizontalTotalizer as any)?.resetValidation();
            }
        }

        @Watch('columnsSelected', { deep: true, immediate: true })
        onColumnSelectedChange() {
            this.hasGeneratedReport = false;
        }

        @Watch('filtersData', { deep: true, immediate: true })
        onFilterDataChange() {
            this.hasGeneratedReport = false;
        }

        // #endregion




        // #region Methods ------------------------------------------------------------------------------------

        // Life Cycles --------------------------------------

        async created(): Promise<void> {
            this.cleanReport();
            await this.loadReportGroup();
            await this.loadReports();
        }


        // Combobox Report Selection ------------------------
        // @Change
        async onChangeReport(reportIdSelected: number): Promise<void> {

            if (this.isLoadingColumnsAndFilters)
                return;

            if (reportIdSelected == null)
                return;

            if (reportIdSelected <= 0)
                return;

            this.cleanReportData();

            try {
                this.isLoadingColumnsAndFilters = true;

                this.reportSelected = this.reportGroup.reports.find(f => f.id === reportIdSelected);

                if (this.reportSelected == null)
                    throw new Error(`No match for report id: ${reportIdSelected}.`);

                if (this.reportSelected.columns?.length > 0)
                    return;

                const response = await this.reportService.GetById(reportIdSelected);

                if (response == null || response.columns?.length <= 0)
                    throw new Error('No response from web service.');


                this.reportSelected.columns = this.cleanArray(this.reportSelected.columns);
                this.reportSelected.columns = response.columns.sort((a, b) => a.order - b.order);

                this.reportSelected.filters = this.cleanArray(this.reportSelected.filters);
                this.reportSelected.filters = response.filters.sort((a, b) => a.order - b.order);

                this.columnsSelected = this.reportSelected.columns;

            } catch (e) {
                console.log('Error getting columns and filters.')
                console.log(e);

                this.cleanReportSelected();

            } finally {
                this.isLoadingColumnsAndFilters = false;
            }
        }

        // @Focus
        async onReportFocus(): Promise<void> {
            if (this.reportGroup?.reports?.length <= 0) {
                this.loadReports();
            }
        }


        // Form Report Options ------------------------------
        // @Submit
        async generateReport(): Promise<void> {

            if (this.isGeneratingReport)
                return;

            if (this.hasGeneratedReport) {
                this.showReport = true;
            } else {
                if (this.columnsSelected.length <= 0)
                    this.columnsSelected.push(this.reportSelected.columns[0]);

                this.reportTableHeaders = this.columnsSelected.map(m => {
                    return {
                        id: m.id,
                        text: m.text,
                        value: m.propertyName,
                        align: this.parseAlign(m.align),
                        format: m.format,
                    } as Client.Report.Header;
                });

                this.$action.processSubmit({
                    action: async () => {
                        try {
                            this.isGeneratingReport = true;

                            this.reportSelected.data = this.cleanArray(this.reportSelected.data);
                            this.reportSelected.data.push(...(await this.reportService.Generate(this.reportSelected.id, {
                                columnsId: this.columnsSelected.map((m) => m.id),
                                filters: this.validFilters,
                            })));

                            this.itemsPerPage = this.reportSelected.data.length;

                            for (const [index, value] of this.reportSelected.data.entries()) {
                                value.id = index + 1;
                            }

                            this.hasGeneratedReport = true;
                            this.showReport = true;

                        } catch (e) {
                            console.log(e);
                            this.hasGeneratedReport = false;
                            this.showReport = false;

                        } finally {
                            this.isGeneratingReport = false;
                        }
                    },
                    form: this.$refs.form,
                    rules: this.rules,
                    ...this.$action.reportMessages
                });
            }
        }


        // Form Add Horizontal Totalizer --------------------
        // @Submit
        saveHorizontalTotalizer(): void {

            this.$action.processSubmit({
                action: () => {
                    try {
                        let horizontalTotalizer: IHorizontalTotalizer = this.insertOrUpdateHorizontalTotalizer();

                        this.insertHorizontalTotalizerOnTableHeaders(horizontalTotalizer);

                        this.calculateHorizontalTotalizer(horizontalTotalizer);
                    }
                    finally {
                        this.showHorizontalTotalizerCrud = false;
                    }
                },
                form: this.$refs.formHorizontalTotalizer,
                rules: this.rulesAddTotalizer,
                ...this.$action.saveMessages,
                showLoading: false,
                showLoadingOverlay: false,
                showSnackbar: false
            });

        }



        // Local methods ------------------------------------

        calculateHorizontalTotalizer(horizontalTotalizer: IHorizontalTotalizer) {

            let propertyName = this.getHorizontalTotalizerPropertyName(horizontalTotalizer);

            let selectedProps = this.reportTableHeaders
                .filter(f => horizontalTotalizer.columnsId.includes(f.id))
                .map(m => m.value);

            for (const data of this.reportSelected.data) {
                let values: number[] = [];
                for (const prop of selectedProps) {
                    values.push(data[prop]);
                }
                data[propertyName] = this.performCalculationOfMathematicalOperation(horizontalTotalizer.operation, values);
            }
        }

        //copyValidColumnsForTotalizers(): void {
        //    this.customColumnAvaliableColumnsItems = this.columnsSelected
        //        .filter(f => Enum.In(f.format,
        //            Api.Domain.Enums.Format.Decimal,
        //            Api.Domain.Enums.Format.Currency,
        //            Api.Domain.Enums.Format.Number)
        //        )
        //        .map(m => {
        //            return {
        //                texto: m.text,
        //                valor: m.id
        //            } as Client.ConjuntoValorTexto
        //        });
        //}

        insertOrUpdateHorizontalTotalizer(): IHorizontalTotalizer {
            let horizontalTotalizer: IHorizontalTotalizer = this.isEditingHorizontalTotalizer ? this.totalizer.horizontal[this.horizontalTotalizerModelIndex] : { id: null };

            for (const prop in this.horizontalTotalizerModel) {
                horizontalTotalizer[prop] = this.horizontalTotalizerModel[prop];
            }

            if (horizontalTotalizer.id == null) {
                horizontalTotalizer.id = this.getTotalizerHorizontalId();
            }

            if (!this.isEditingHorizontalTotalizer)
                this.totalizer.horizontal.push(horizontalTotalizer);

            return horizontalTotalizer;
        }

        insertHorizontalTotalizerOnTableHeaders(horizontalTotalizer: IHorizontalTotalizer): void {
            let index = this.reportTableHeaders.findIndex(f => f.id == horizontalTotalizer.id);

            let tableHeader: Client.Report.Header = index >= 0 ? this.reportTableHeaders[index] : { id: 0, text: null, value: null };

            tableHeader.text = horizontalTotalizer.name;
            tableHeader.align = this.parseAlign(horizontalTotalizer.align);
            tableHeader.format = horizontalTotalizer.format;

            if (!this.isEditingHorizontalTotalizer) {

                let propertyName = this.getHorizontalTotalizerPropertyName(horizontalTotalizer);

                tableHeader.id = horizontalTotalizer.id;
                tableHeader.value = propertyName;

                this.reportTableHeaders.push(tableHeader);
            }
        }

        calculateVerticalTotalizer(columnId: number, propertyName: string, items: Client.DynamicObject[]): number {

            let operation: MathOperations = this.totalizer.vertical.find(f => f.columnId == columnId)?.operation;

            if (operation == null)
                return null;

            return this.performCalculationOfMathematicalOperation(
                operation,
                items.map(m => m[propertyName])
            );
        }

        performCalculationOfMathematicalOperation(operation: MathOperations, values: number[]): number {

            if (values?.length > 0 == false)
                return null;

            switch (operation) {
                case MathOperations.Sum:
                    return values.reduce((x, y) => x + y);
                case MathOperations.Average:
                    return values.reduce((x, y) => x + y) / values.length;
                case MathOperations.Max:
                    return Math.max.apply(null, values);
                case MathOperations.Min:
                    return Math.min.apply(null, values);
                default:
                    throw Error('Invalid math operation.');
            }
        }

        cleanArray<T>(array: T[]): T[] {
            if (array) {
                array.length = 0;
            } else {
                array = [];
            }

            return array;
        }

        cleanContentForPrinting(): void {
            document.getElementById('print-area').innerHTML = '';
        }

        cleanHorizontalTotalizer(): void {
            this.cleanHorizontalTotalizerData();
            this.cleanHorizontalTotalizerModel();
        }

        cleanHorizontalTotalizerData(): void {
            this.totalizer.horizontal = [];
            this.horizontalTotalizerModelIndex = null;
            this.isEditingHorizontalTotalizer = false;
            this.showHorizontalTotalizerCrud = false;
        }

        cleanHorizontalTotalizerModel(): void {
            this.horizontalTotalizerModel = {
                id: 0,
                name: '',
                align: Api.Domain.Enums.Align.End,
                columnsId: null,
                format: Api.Domain.Enums.Format.Number,
                operation: MathOperations.Sum,
            }
        }

        cleanVerticalTotalizer(): void {
            this.cleanVerticalTotalizerData();
            this.cleanVerticalTotalizerModel();
        }

        insertVerticalTotalizer() { 
            if (this.totalizer.vertical.length <= 0) {
                this.showTotalizerVerticalTotal = true;

                if (!String.isNullOrWhiteSpace(this.groupBy))
                    this.showTotalizerVerticalTotalByGroup = true;
            }

            let id = this.totalizer?.vertical?.length > 0 ? Math.max(...this.totalizer.vertical.map(m => m.id)) : 1;

            this.totalizer.vertical.push({
                id,
                name: `Vertical ${id}`,
                columnId: null,
                operation: MathOperations.Sum,
            });
        }

        cleanVerticalTotalizerData(): void {
            this.totalizer.vertical = [];
            this.verticalTotalizerModelIndex = null;
            this.isEditingVerticalTotalizer = false;
            this.showVerticalTotalizerCrud = false;
            this.showTotalizerVerticalTotal = false;
        }

        cleanVerticalTotalizerModel(): void {
            this.verticalTotalizerModel = {
                id: 0,
                name: '',
                align: null,
                columnId: null
            }
        }

        cleanGroupBy(): void {
            this.groupBy = null;
            this.showTotalizerVerticalTotalByGroup = false;
        }

        onGroupByChange(): void {
            if (this.groupBy == null || this.groupBy == '')
                this.showTotalizerVerticalTotalByGroup = false
        }

        cleanReport(): void {
            this.cleanGroupBy();
            this.cleanHorizontalTotalizer();
            this.cleanVerticalTotalizer();
            this.cleanReportData();
            this.cleanReportSelected();
        }

        cleanReportData(): void {
            this.columnsSelected = [];
            this.filtersData = [];
            this.reportTableHeaders = [];

            if (this.reportSelected) {
                this.reportSelected.data = [];
                this.hasGeneratedReport = false;
            }   
        }

        cleanReportSelected(): void {
            if (this.reportSelected) {
                this.reportSelected.data = [];
                this.hasGeneratedReport;
            }

            this.reportSelected = {
                id: null,
                name: null,
                columns: [],
                filters: [],
                data: []
            };

            this.reportIdSelected = null;
        }

        deleteVerticalTotalizer(index: number) {
            this.totalizer.vertical.splice(index, 1);

            if (this.totalizer.vertical.length <= 0) {
                this.showTotalizerVerticalTotal = false;
                this.showTotalizerVerticalTotalByGroup = false;
            }
        }

        deleteHorizontalTotalizer(index: number) {
            let horizontalTotalizerRemoved = this.totalizer.horizontal.splice(index, 1)[0];

            let reportIndex = this.reportTableHeaders.findIndex(f => f.id == horizontalTotalizerRemoved.id);

            this.reportTableHeaders.splice(reportIndex, 1);

            let propertyName = this.getHorizontalTotalizerPropertyName(horizontalTotalizerRemoved);

            for (const data of this.reportSelected.data) {
                delete data[propertyName];
            }
        }

        editHorizontalTotalizer(index: number): void {

            this.horizontalTotalizerModelIndex = index;

            let column = this.totalizer.horizontal[index];

            this.horizontalTotalizerModel = Object.assign({}, column);

            this.isEditingHorizontalTotalizer = true;
            this.showHorizontalTotalizerCrud = true;
        }

        formatGroupHeader(groupBy: string | string[], value: any): string {
            let propertyName = Array.isArray(groupBy) ? groupBy[0] : groupBy;

            return this.formatItemContent(
                this.columnsSelected.find(f => f.propertyName == propertyName).format,
                value
            );
        }

        formatItemContent(formatting: Api.Domain.Enums.Format, item: any): string {

            if (item == null)
                return '';

            switch (formatting) {
                case Api.Domain.Enums.Format.Date:
                    return moment(item).format('L');

                case Api.Domain.Enums.Format.Time:
                    return moment(item).format('LTS');

                case Api.Domain.Enums.Format.DateTime: {
                    let datetime = moment(item);
                    return `${datetime.format('L')} ${datetime.format('LTS')}`;
                }

                case Api.Domain.Enums.Format.Decimal:
                    return Format.Decimal(ConvertTo.Decimal(item), 2);

                case Api.Domain.Enums.Format.Currency:
                    return Format.Currency(ConvertTo.Decimal(item), 2);

                case Api.Domain.Enums.Format.Text:
                case Api.Domain.Enums.Format.Number:
                    return item;

                default:
                    throw new Error(`Formatting type provided is invalid. Value: ${formatting}`);
            }
        }

        getHorizontalTotalizerPropertyName(horizontal: IHorizontalTotalizer): string {
            if (horizontal == null)
                throw new Error('Parameter "horizontal" is null.');

            return `horizontal${horizontal.id}`;
        }

        isValidColumn(headerId: number): boolean {
            return this.columnsSelected
                .filter(f => Enum.In(f.format,
                    Api.Domain.Enums.Format.Decimal,
                    Api.Domain.Enums.Format.Currency,
                    Api.Domain.Enums.Format.Number))
                .map(m => m.id)
                .includes(headerId);
        }

        async loadReports(): Promise<void> {

            if (this.isLoadingReports)
                return;

            try {
                this.isLoadingReports = true;

                this.reportGroup.reports = (await this.reportService.GetAll()).map(m => {
                    return {
                        id: m.id,
                        name: m.name,
                        data: []
                    } as Client.Report.ReportDto;
                });

            } catch (e) {
                this.reportGroup.reports = null;
            } finally {
                this.isLoadingReports = false;
            }
        }

        async loadReportGroup(): Promise<void> {

            if (this.isLoadingReportGroup)
                return;

            try {
                this.isLoadingReportGroup = true;

                let result = this.reportGroupObject != null ? this.reportGroupObject : await this.reportGroupsService.GetById(this.reportGroupId);

                this.reportGroup = {
                    id: result.id,
                    name: result.name
                }

                this.reportService = new ReportRepository(this.reportGroup.id);

            } catch (e) {
                this.reportGroup = null;

            } finally {
                this.isLoadingReportGroup = false;
            }
        }

        parseAlign(align: Api.Domain.Enums.Align): 'start' | 'center' | 'end' {
            switch (align) {
                case Api.Domain.Enums.Align.Start:
                    return 'start';
                case Api.Domain.Enums.Align.Center:
                    return 'center';
                case Api.Domain.Enums.Align.End:
                    return 'end';
                default:
                    throw new Error('Align is invalid.');
            }
        }

        putContentForPrinting(): void {
            let printArea = document.getElementById('print-area');
            let toPrint = document.getElementById('to-print');

            printArea.innerHTML = '';
            printArea.appendChild(toPrint.cloneNode(true));
        }

        print(): void {
            let itemsPerPage = this.itemsPerPage;
            this.itemsPerPage = this.reportSelected.data.length;
            this.isPrinting = true;
            Vue.nextTick()
                .then(() => {
                    try {
                        this.putContentForPrinting();
                        window.print();
                    } finally {
                        this.cleanContentForPrinting();
                        this.itemsPerPage = itemsPerPage;
                        this.isPrinting = false;
                    }
                });

            //this.isPrinting = !this.isPrinting;
            //Vue.nextTick()
            //    .then(() => {
            //        if (this.isPrinting) {
            //            this.putContentForPrinting();
            //        } else {
            //            this.cleanContentForPrinting();
            //        }
            //    });
        }

        // #endregion

    }
