import { WebStorageStateStore, UserManagerSettings } from 'oidc-client';

export const QueryParameterNames = {
    Message:    'message',
    ReturnUrl:  'returnUrl',
}

export enum LogoutActions {
    Logout          = 'logout',
    LoggedOut       = 'logged-out',
    LogoutCallback  = 'logout-callback',
}

export enum LoginActions {
    Login           = 'login',
    LoginCallback   = 'login-callback',
    LoginFailed     = 'login-failed',
    Profile         = 'profile',
    Register        = 'register',
}

// Sempre preecher com '/' no começo
const prefix = '/authentication';

/** Caminho das rotas de autenticação */
export const AuthrorizationPaths = {
    DefaultLoginRedirectPath:   '/',
    ApiAuthorizationPrefix:     prefix,
    Login:                      `${prefix}/${LoginActions.Login}`,
    LoginFailed:                `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback:              `${prefix}/${LoginActions.LoginCallback}`,
    Register:                   `${prefix}/${LoginActions.Register}`,
    Profile:                    `${prefix}/${LoginActions.Profile}`,
    LogOut:                     `${prefix}/${LogoutActions.Logout}`,
    LoggedOut:                  `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback:             `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath:       '/Identity/Account/Register',
    IdentityManagePath:         '/Identity/Account/Manage'
};

/** Configurações do OAuth. Essas configurações devem ser as mesmas no appsettings do servidor. */
export const OpenIdClientSettings : UserManagerSettings = {
    authority:                      process.env.VUE_APP_URL_AUTHORITY,
    client_id:                      'sisgem-web-client',
    redirect_uri:                   window.location.origin + AuthrorizationPaths.LoginCallback,
    response_type:                  "code",
    scope:                          "openid sisgem-web-api.all",
    post_logout_redirect_uri:       window.location.origin + AuthrorizationPaths.LogOutCallback,
    automaticSilentRenew:           true,

    userStore: new WebStorageStateStore({
        prefix: 'sisgem-web-client',
        //store: new InMemoryWebStorage(),
    })
};

/** Mensagens de autenticação */
export const AuthMessages = {
    ProcessingLogin:            'Entrando...',
    ProcessingLoginCallback:    'Processando autenticação, por favor aguarde.',
    LoginFailed:                'Falha de login.',
    ProcessingLogout:           'Saindo...',
    LogoutNotInitiated:         'O processo de logout não foi iniciado na página.',
    ProcessingLogoutCallback:   'Saindo...',
    LoggedOut:                  'Você saiu com sucesso!'
};