
    import Vue from 'vue';
    import axios from 'axios';
    import { Location } from 'vue-router';
    import Component from 'vue-class-component';

    import ActionController from '@/components/action-controller.vue';
    import MenuList from '@/components/menu-list.vue';

    import Api from '@/base/api.typings';
    import Client from './base/client.typings';
    import authService from '@/features/api-authorization/authorize-service';
    import { ReportGroupsRepository } from '@/features/reports/reports-service';

    import { AuthrorizationPaths, QueryParameterNames } from '@/features/api-authorization/api-authorization-constants';
    import { Watch } from 'vue-property-decorator';
    import { ReportRouteName, ReportRouteParamReportGroupObject, ReportRouteParamReportGroupId } from '@/features/reports/reports-route';    
    import { Dictionary } from 'vue-router/types/router';
    import { PedidoLancar } from '@/features/vendas/vendas-route';
    import { PNListar, PNInserir } from "@/features/pn/pn-route";

    
    @Component({
        components: {
            ActionController,
            MenuList,
        }
    })
    export default class App extends Vue {

        // #region Data ---------------------------------------------------------------------------------------

        // Local variables ----------------------------------

        reportGroupsService: ReportGroupsRepository = new ReportGroupsRepository();

        isAuthenticated = false;

        drawer = false;
        menuItems: Client.Home.Menu[] = [];
        transitionName = '';


        // #endregion




        // #region Methods ------------------------------------------------------------------------------------

        // Life Cycles --------------------------------------

        async created(): Promise<void> {
            this.configureAxiosInterceptors();
            this.checkAuthentication();
        }

        async mounted(): Promise<void> {
            this.addActionControlerToRefs();
            this.configureRouterTransation();
            await this.loadMenuItems();
        }


        // Local methods ------------------------------------

        addActionControlerToRefs(): void {
            let vue = Vue.prototype;
            vue.$action = this.$refs.action;
        }

        checkAuthentication(): void {
            let checkAuthentication = (isUserSet?: boolean) => this.isAuthenticated = isUserSet ? authService.isAuthenticated() : false;

            authService.subscribe(checkAuthentication);

            checkAuthentication(true);
        }

        configureAxiosInterceptors(): void {

            axios.interceptors.request.use(request => {
                const isAuth = authService.isAuthenticated();
                if (isAuth) {
                    request.headers['Authorization'] = `Bearer ${authService.getAccessToken()}`;
                }
                return request;
            });

            axios.interceptors.response.use(null, error => {
                // Authentication errors automatically take the user to the login screen
                if (error.response.status == 401) {
                    const query: Dictionary<string> = {};

                    query[QueryParameterNames.ReturnUrl] = window.location.pathname;

                    this.$router.push({
                        path: AuthrorizationPaths.Login,
                        query
                    });
                    return null;
                } else {
                    return Promise.reject(error);
                }
            })
        }

        configureRouterTransation(): void {
            this.$router.beforeEach((to, from, next) => {

                // Implements screen transition animation.
                // The direction depends on whether the new screen is a child or parent of the old screen.

                this.transitionName = to && from && to.fullPath.startsWith(from.fullPath)
                    ? 'slide-right'
                    : 'slide-left';

                next();

            });
        }

        @Watch('drawer')
        onOpenMenu(value: boolean): void {
            if (value) {
                this.loadMenuItems();
            }
        }

        async loadMenuItems(): Promise<void> {

            let isAuthenticated = authService.isAuthenticated();
            
            if (isAuthenticated && this.menuItems && this.menuItems.length <= 0) {

                let menuReport = await this.createMenuItemReport();

                menuReport.children.push({
                    id: 'despesas',
                    icon: '',
                    text: "Despesas",
                    model: false,
                    active: true,
                    to: '/reports/expenses-by-chart-of-accounts',
                })

                menuReport.children.find(f => f.text == "Caixa").to = {
                    name: 'Sisgem-Report-Cash',
                    params: { reportGroupId: "10000000002" } 
                }

                this.menuItems = [
                    {
                        icon: 'mdi-pencil-circle-outline',
                        text: 'Cadastros',
                        active: true,
                        children: [
                            {
                                icon: 'mdi-account',
                                text: 'Parceiro de Negócio',
                                active: true,
                                children: [
                                    {
                                        icon: 'mdi-list-box',
                                        text: 'Listar',
                                        active: true,
                                        to: PNListar
                                    },
                                    {
                                        icon: 'mdi-account-plus',
                                        text: 'Cadastrar',
                                        active: true,
                                        to: PNInserir
                                    },
                                ]
                            }
                        ]
                    },
                    menuReport,
                    {
                        icon: 'mdi-contacts',
                        text: 'Cadastrar PN',
                        to: '/pn',
                        active: true,
                    },
                    {
                        icon: 'mdi-contacts',
                        text: 'Lançar Pedido',
                        to: PedidoLancar,
                        active: true,
                    },
                    {
                        icon: 'mdi-file-cog',
                        text: 'Ordem de Serviço',
                        active: true,
                        children: [
                            {
                                icon: 'mdi-file-cog',
                                text: 'Consultar Ordem de Serviço',
                                to: '/ordemservico',
                                active: false,
                            },
                            {
                                icon: 'mdi-file-cog',
                                text: 'Lançar Ordem de Serviço',
                                to: '/ordemservicolancar',
                                active: false,
                            },
                        ]
                    },
                    {
                        text: 'Notas Fiscais',
                        icon: 'mdi-clipboard-edit',
                        active: await authService.validateUserAccess(Api.Domain.Enums.UserAccess.Cadastro_ModelosDeNota),
                        children: [
                            {
                                text: 'Cadastro',
                                icon: 'mdi-clipboard-edit',
                                to: '/modelo-de-notas-fiscais/cadastrar',
                                active: await authService.validateUserAccess(Api.Domain.Enums.UserAccess.Cadastro_ModelosDeNota_Incluir),
                            },
                            {
                                text: 'Listagem',
                                icon: 'mdi-clipboard-edit',
                                to: '/modelo-de-notas-fiscais/listar',
                                active: await authService.validateUserAccess(Api.Domain.Enums.UserAccess.Cadastro_ModelosDeNota_Consultar),
                            }
                        ]
                    }
                ];
            }
        }

        async createMenuItemReport(): Promise<Client.Home.Menu> {

            let reports = await this.reportGroupsService.GetAll();

            return {
                icon: 'mdi-clipboard-text-outline',
                text: 'Relatórios',
                model: false,
                active: true,
                children: reports.map(this.createMenuItemReportGroup),
            };
        }

        createMenuItemReportGroup(group: Api.Reports.GetReportGroups.ReportGroupDto): Client.Home.Menu {

            let to: Location = {
                name: ReportRouteName,
                params: {},
            }

            to.params[ReportRouteParamReportGroupId] = group.id.toString();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            to.params[ReportRouteParamReportGroupObject] = group as any;

            return {
                id: group.id.toString(),
                icon: '',
                text: group.name,
                model: false,
                active: true,
                to: to,
            };
        }
    }
