import { UserManager, User, Profile } from 'oidc-client';
import Api from '@/base/api.typings';
import usersService from '@/features/users/users-service';
import { OpenIdClientSettings } from '@/features/api-authorization/api-authorization-constants';
import Enum from '@/utils/enum';

export interface State {
    returnUrl?: string
}

export enum AuthenticationResultStatus {
    Redirect = 'redirect',
    Success = 'success',
    Fail = 'fail',
}

export interface AuthResult {
    status: AuthenticationResultStatus,
    state?: State,
    message?: string,
}

type Action<T> = (t?: T) => void;

interface Callback {
    callback: Action<boolean>
    subscription: number
}

export class AuthorizeService {

    // Static Methods -------------------------------------------------------------------------------------

    static get instance() : AuthorizeService {
        return authService;
    }




    // Local variables ------------------------------------------------------------------------------------

    private _callbacks: Callback[] = [];
    private _nextSubscriptionId = 0;    
    private _user: User;
    private _userAccess: Api.Users.GetUserAccesses.UserAccessInfoDto = null;




    // By default pop ups are disabled, because they don't work properly on Edge.
    // If you want to enable pop up authentication simply set this flag to false.
    private _popUpDisabled = true;

    private userManager: UserManager;

    private hashCode(value: string): number {
        let hash = 0, chr = 0;
        for (let i = 0; i < value.length; i++) {
            chr = value.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0;
        }
        return hash;
    }

    // Public Methods -------------------------------------------------------------------------------------

    /**
     * Complete the login process when the server returns the token.
     * @param url
     */
    async completeSignIn(url: string): Promise<AuthResult> {
        try {
            await this.ensureUserManagerInitialized();    
            
            const user = await this.userManager.signinCallback(url);            

            if (user == null) {
                return this.error("User is undefined.");
            } else {
                return this.success(user && user.state);
            }
        } catch (error) {
            console.log('There was an error signing in: ', error);
            return this.error('There was an error signing in.');
        }
    }

    /**
     * Complete the logout process when the server redirects back to the app after logging out.
     * @param url
     */
    async completeSignOut(url: string): Promise<AuthResult> {
        await this.ensureUserManagerInitialized();
        try {
            const response = await this.userManager.signoutRedirectCallback(url);            
            return this.success((response.state) as State);
        } catch (error) {
            console.log('There was an error trying to logout: ', error);
            return this.error(error);
        }
    }

    /** Returns true if the user is authenticated */
    isAuthenticated(): boolean {
        return !!this.getUserProfile();
    }

    /** 
     * Returns the access token of the logged in user.
     * Send this token to the server to authenticate. 
     */
    getAccessToken(): string {
        return this._user && this._user.access_token;
    }

    getUserProfile(): Profile {
        return this._user && this._user.profile;
    }

    // We try to authenticate the user in three different ways:
    // 1) We try to see if we can authenticate the user silently. This happens
    //    when the user is already logged in on the IdP and is done using a hidden iframe
    //    on the client.
    // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
    //    redirect flow.
    async signIn(state: State): Promise<AuthResult> {

        await this.ensureUserManagerInitialized();

        try {
            return await this.signinSilent();
        } catch (silentError) {

            if (silentError.error !== 'login_required') {
                console.log("Silent authentication error: ", silentError);
            }

            try {
                return await this.signinRedirect(state);
            } catch (redirectError) {
                console.log("Redirect authentication error: ", redirectError);
                return this.error(redirectError);
            }
        }
    }

    // We try to sign out the user in two different ways:
    // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
    //    post logout redirect flow.
    async signOut(state: State): Promise<AuthResult> {
        await this.ensureUserManagerInitialized();
        try {
            await this.userManager.signoutRedirect(this.createArguments(state));
            return this.redirect();
        } catch (redirectSignOutError) {
            console.log("Redirect signout error: ", redirectSignOutError);
            return this.error(redirectSignOutError);
        }
    }

    /**
     * Notifies changes to the user's authentication state.
     * @param callback
     */
    subscribe(callback: Action<boolean>): number {
        this._callbacks.push({
            callback,
            subscription: this._nextSubscriptionId++
        });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId: number): void {
        const subscriptionIndex = this._callbacks.findIndex(f => f.subscription === subscriptionId);

        if (subscriptionIndex < 0) {
            throw new Error(`Subscription not found. Subscription id: ${subscriptionId}`)
        }

        this._callbacks = this._callbacks.splice(subscriptionIndex, 1);
    }

    /**
     * Returns true if the user has all accesses informed by the parameter. 
     * Use to validate that the user has access to the resource. 
     * It caches the user's accesses, so if the permissions are changed 
     * they will take effect when the user updates or reopens the page, 
     * however on the server they take effect immediately when changed.
     * @param userAccess
     */
    async validateUserAccess(...userAccess: Api.Domain.Enums.UserAccess[]): Promise<boolean> {
        await this.loadUserAccess();
        
        if (Enum.In(this._userAccess.level, Api.Domain.Enums.UserLevel.Support, Api.Domain.Enums.UserLevel.Owner))
            return true;

        return !userAccess.some(s => !this._userAccess.accesses.includes(s));
    }

    /**
     * Returns true if the logged in user has all permissions within @param userLevels
     * @param userLevels
     */
    async validateUserLevelAccess(...userLevels: Api.Domain.Enums.UserLevel[]) : Promise<boolean> {
        await this.loadUserAccess();

        return Enum.In(this._userAccess.level, userLevels);
    }

    // Private Methods ------------------------------------------------------------------------------------

    private createArguments(state?: State) {
        return {
            state,
        };
    }

    private async ensureUserManagerInitialized(): Promise<void> {
        if (this.userManager != null)
            return;        

        this.userManager = new UserManager(OpenIdClientSettings);

        this.userManager.events.addUserLoaded((async (user: User) => {
            this._user = user;
            this.notifySubscribers(true);
        }).bind(this));

        this.userManager.events.addUserUnloaded((() => {
            this._user = null;
            this.notifySubscribers(false);
        }).bind(this))

        this.userManager.events.addUserSignedOut((async () => {
            await this.userManager.removeUser();
        }).bind(this));
    }

    private error(message: string): AuthResult {
        return { status: AuthenticationResultStatus.Fail, message };
    }

    private async loadUserAccess() : Promise<void> {
        if (this._userAccess != null)
            return;

        this._userAccess = await usersService.getUserAccesses();
        
    }

    private notifySubscribers(isUserSet?: boolean) {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback(isUserSet);
        }
    }

    private redirect(): AuthResult {
        return { status: AuthenticationResultStatus.Redirect };
    }

    private success(state: State): AuthResult {
        return { status: AuthenticationResultStatus.Success, state };
    }

    private async signinRedirect(state?: State) {
        await this.userManager.signinRedirect(this.createArguments(state));
        return this.redirect();
    }

    private async signinSilent(state?: State): Promise<AuthResult> {
        const user = await this.userManager.signinSilent(this.createArguments(state));

        if (user == null) {
            return this.error("user is undefined");
        } else {
            return this.success(state);
        }
    }

}

const authService = new AuthorizeService();

export default authService;